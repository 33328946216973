import React from "react";
import {
	Card,
	CardBody,
	Row,
	Col,
} from '@mycentyr/centyr-ui';

import { connect } from 'react-redux';

class Dashboard extends React.Component {
	render() {
		return (
			<div>
				<div className="page-header">
					<Row>
						<Col className="page-title">
							<h1>{this.props.title}</h1>
						</Col>
					</Row>
				</div>

				<div className="content-wrapper">
					<Card>
						<CardBody>
							{this.props.title} <br />
							Hello {this.props.authUserData && this.props.authUserData.role}
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authUserData: state.authUser.userData,
	};
};

export default connect(mapStateToProps, null)(Dashboard);