import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { history } from "helpers/history";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  packages: [],
  noOfRecords: 0,
  packageData: {},
  isFetchPackagesPending: false,
  isFetchPackagePending: false,
  isAddUpdatePackagePending: false,
};

const slice = createSlice({
  name: "packageData",
  initialState,
  reducers: {
    fetchPackages(state, action) {
      state.packages = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchPackage(state, action) {
      state.packageData = action.payload;
    },
    setFetchPackagesPending(state, action) {
      state.isFetchPackagesPending = action.payload;
    },
    setFetchPackagePending(state, action) {
      state.isFetchPackagePending = action.payload;
    },
    setAddUpdatePackagesPending(state, action) {
      state.isAddUpdatePackagePending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchPackages = (
  pageNo = 1,
  perPage = 10,
  keyword = "",
  status = "all",
  customerId = "",
  startDate = "",
  endDate = "",
  order = "",
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchPackagesPending(true));
  dispatch(slice.actions.fetchPackages({ items: [], count: -1 }));

  const response = await instance
    .post(
      `packages/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? " desc" : ""
      }&status=${status}&start_date=${startDate}&end_date=${endDate}&customers=${customerId}`,
      {}
    )
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchPackagesPending(false));
  if (response) {
    dispatch(slice.actions.fetchPackages(response.data.data));
  }
};

export const fetchConsolidatePackages = (
  pageNo = 1,
  perPage = 10,
  keyword = "",
  order = "",
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchPackagesPending(true));
  dispatch(slice.actions.fetchPackages({ items: [], count: -1 }));

  const response = await instance
    .post(
      `packages/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? " desc" : ""
      }&status=&type=consolidate`,
      {}
    )
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchPackagesPending(false));
  if (response) {
    dispatch(slice.actions.fetchPackages(response.data.data));
  }
};

export const fetchPackage = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchPackagePending(true));

  const response = await instance
    .post(`packages/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchPackagePending(false));
  if (response) {
    dispatch(slice.actions.fetchPackage(response.data.data));
  }
};

export const addPackage = (postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post("packages/create", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package added successfully");
    history.replace("/packages/list/all");
  }
};

export const updatePackage = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`packages/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    dispatch(slice.actions.fetchPackage(response.data.data));
    CustomSnackbar("success", "Package updated successfully");
  }
};
export const uploadImage = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`packages/upload_images/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    dispatch(slice.actions.fetchPackage(response.data.data));
    CustomSnackbar("success", "Package image uploaded successfully");
  }
};

export const deletePackages = (
  postData,
  pageNo,
  perPage,
  keyword = ""
) => async (dispatch) => {
  const response = await instance
    .post("packages/delete", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  if (response) {
    CustomSnackbar("success", "Package deleted Successfully");
    dispatch(fetchPackages(pageNo, perPage, keyword));
  }
};

export const deleteMedias = (id, postData) => async (dispatch) => {
  const response = await instance
    .post(`packages/delete_media/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  if (response) {
    CustomSnackbar("success", "Media deleted Successfully");
    dispatch(fetchPackage(id));
  }
};

export const changeStatus = (id, status, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));
  let postData = {
    id: id,
    status: status,
  };
  const response = await instance
    .post("packages/change_status", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    CustomSnackbar("success", "Package Status changed successfully");
    toggle();
    dispatch(fetchPackage(id));
  }
};

export const getLabel = (id) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`/packages/get_label/${id}`)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    let labelData = response.data.data.labels[0].labelData;
    var a = document.createElement("a");
    a.href = "data:application/pdf;base64," + labelData;
    a.download = id + "-label.pdf";
    a.click();
  }
};

export const getMenifest = (id) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdatePackagesPending(true));

  const response = await instance
    .post(`/packages/get_manifest/${id}`)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdatePackagesPending(false));
  if (response) {
    let manifestData = response.data.data.manifests[0].manifestData;
    var a = document.createElement("a");
    a.href = "data:application/octet-stream;base64," + manifestData;
    a.download = id + "-manifest.pdf";
    a.click();
  }
};

export default slice;
