import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import { fetchAddresses, deleteAddresses } from "../slices/addressSlice";
import AddressActionButtons from "./AddressActionButtons";
import AddressSkeleeton from "./AddressSkeleeton";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
} from "@mycentyr/centyr-ui";
import OneAddress from "./OneAddress";

const AddressList = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { addresses, noOfRecords, isFetchAddressesPending } = useSelector(
    (state) => state.address
  );

  const headCells = [
    {
      id: "customer_address_name",
      numeric: false,
      disablePadding: false,
      label: "Address Name",
    },
    {
      id: "customer_address_country_name",
      numeric: false,
      disablePadding: false,
      label: "Country",
    },
    {
      id: "customer_address_state",
      numeric: false,
      disablePadding: false,
      label: "State",
    },
    {
      id: "customer_address_city",
      numeric: false,
      disablePadding: false,
      label: "City",
    },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  const createData = (
    id,
    customer_address_name,
    customer_address_country_name,
    customer_address_state,
    customer_address_city
  ) => {
    return {
      id,
      customer_address_name,
      customer_address_country_name,
      customer_address_state,
      customer_address_city,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchAddresses(id, 1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchAddresses(id, pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deleteAddresses(id, postData, pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchAddresses(id, 1, perPage, keyword));
  };

  useEffect(() => {
    let rowsLcl = [];
    addresses.map((address) =>
      rowsLcl.push(
        createData(
          address.id,
          address.customer_address_name,
          address.customer_address_country_name,
          address.customer_address_state,
          address.customer_address_city
        )
      )
    );
    setRows(rowsLcl);
  }, [addresses]);

  useEffect(() => {
    dispatch(fetchAddresses(id));
  }, []);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Address</ModalHeader>
        <ModalBody>
          <OneAddress id="add" customer_id={id} toggle={toggle} />
        </ModalBody>
      </Modal>

      {props.authUserData.permissions &&
        props.authUserData.permissions.includes(
          "add.crm.customer.addresses"
        ) && (
          <Button color="primary" className="mb-3 pull-right" onClick={toggle}>
            Add Address
          </Button>
        )}
      <div className="clearfix"></div>
      <MuiTable
        headCells={headCells}
        rows={rows}
        onReload={onReload} // comment this to disable refresh option
        isFetchPending={isFetchAddressesPending}
        // chooseColumn={false}   //=>to false display column option
        noOfRecords={noOfRecords}
        handlePaginationChange={handlePaginationChange}
        multipleDelete={
          props.authUserData.permissions &&
          props.authUserData.permissions.includes(
            "delete.crm.customer.addresses"
          )
            ? true
            : false
        }
        onMultiDelete={onMultiDelete}
        onSearch={onSearch}
        CustomSkeleton={AddressSkeleeton}
        Actions={AddressActionButtons}
        otherProps={{ customer_id: id }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(AddressList);
