import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Col,
  CustomSpinner,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Row,
  Select,
  TextField,
} from '@mycentyr/centyr-ui';
import { fetchAllEmailProviders } from 'app/manage/email/emailprovider/actions/emailProviderActions';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'redux/store';
import { isObjectEmpty } from 'utils/fun';
import * as yup from 'yup';
import {
  addDepartment,
  fetchDepartment,
  updateDepartment,
} from '../slices/departmentSlice';

const schema = yup.object().shape({
  departmentName: yup.string().required('Department name is required'),
});

const initialValue = {
  departmentName: '',
  departmentDescription: '',
  emailProvider: '',
  hideFromClient: false,
};

const OneDepartment = ({ id, toggle, emailproviders }) => {
  const dispatch = useDispatch();
  const {
    department,
    isFetchDepartmentPending,
    isAddUpdateDepartmentPending,
  } = useSelector((state) => state.department);

  useEffect(() => {
    dispatch(fetchAllEmailProviders());
  }, []);

  useEffect(() => {
    if (id !== 'add') {
      dispatch(fetchDepartment(id));
    }
  }, [id]);

  useEffect(() => {
    if (!isObjectEmpty(department))
      reset(
        id === 'add'
          ? initialValue
          : {
              departmentName: department.ticket_department_name || '',
              departmentDescription:
                department.ticket_department_description || '',
              emailProvider: department.emailprovider_id || false,
              hideFromClient: Boolean(parseInt(department.is_hidden)) || false,
            }
      );
  }, [department, id]);

  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
    defaultValues: initialValue,
  });

  const onSubmit = async (data) => {
    const postData = {
      ticket_department_name: data.departmentName,
      ticket_department_description: data.departmentDescription,
      emailprovider_id: data.emailProvider,
      is_hidden: data.hideFromClient ? 1 : 0,
    };
    id === 'add'
      ? dispatch(addDepartment(postData, toggle))
      : dispatch(updateDepartment(id, postData, toggle));
  };
  if (!isAddUpdateDepartmentPending && isFetchDepartmentPending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        {isFetchDepartmentPending && <CustomSpinner />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="12" xs="12">
              <TextField
                className="mb-3"
                label="Department Name"
                name="departmentName"
                id="departmentName"
                variant="outlined"
                size="small"
                fullWidth
                required
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.departmentName}
                helperText={errors.departmentName?.message}
                inputRef={register}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12" xs="12">
              <TextField
                label="Department Description"
                className="mb-3"
                name="departmentDescription"
                id="departmentDescription"
                variant="outlined"
                size="small"
                multiline
                rows="2"
                fullWidth
                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                error={errors.departmentDescription}
                helperText={errors.departmentDescription?.message}
                inputRef={register}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12" xs="12">
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors.emailProvider}
                className="mb-3"
              >
                <InputLabel>Email Provider</InputLabel>
                <Controller
                  as={
                    <Select native label="Email Provider">
                      <option aria-label="None" value="" />
                      {emailproviders?.map((emailprovider, index) => (
                        <option key={index} value={emailprovider.id}>
                          {emailprovider.emailprovider_name}
                        </option>
                      ))}
                    </Select>
                  }
                  name="emailProvider"
                  control={control}
                />
                <FormHelperText>{errors.emailProvider?.message}</FormHelperText>
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col lg="12" xs="12">
              <FormControlLabel
                control={
                  <Controller
                    name="hideFromClient"
                    control={control}
                    render={(props) => (
                      <Checkbox
                        onChange={(e) => props.onChange(e.target.checked)}
                        checked={props.value}
                      />
                    )}
                  />
                }
                label="Hide from client?"
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12" xs="12" className="text-right">
              <Button
                type="submit"
                color="primary"
                disabled={isAddUpdateDepartmentPending}
              >
                {id === 'add' ? 'Create' : 'Update'}
              </Button>
              <Button
                className="ml-2"
                type="button"
                color="secondary"
                onClick={toggle}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    isFetchEmailProviderPending:
      state.emailprovider.isFetchEmailProviderPending,
    emailproviders: state.emailprovider.emailproviders,
  };
};

export default connect(mapStateToProps, null)(OneDepartment);
