//permission
export const FETCH_PERMISSION_SUCCESS = 'FETCH_PERMISSION_SUCCESS';
export const FETCH_PERMISSION_PENDING = 'FETCH_PERMISSION_PENDING';
export const FETCH_PERMISSION_ERROR = 'FETCH_PERMISSION_ERROR';
export const FETCH_PERMISSION = 'FETCH_PERMISSION';
export const FETCH_PERMISSION_COUNT = 'FETCH_PERMISSION_COUNT';
export const ADD_UPDATE_PERMISSION_ERROR = 'ADD_UPDATE_PERMISSION_ERROR';
export const FETCH_ONE_PERMISSION = 'FETCH_ONE_PERMISSION';
export const FETCH_ONE_PERMISSION_PENDING = 'FETCH_ONE_PERMISSION_PENDING';
export const ADD_UPDATE_PERMISSION_PENDING = 'ADD_UPDATE_PERMISSION_PENDING';
export const ADD_UPDATE_PERMISSION_SUCCESS = 'ADD_UPDATE_PERMISSION_SUCCESS';
