import React from 'react';
import { connect } from 'react-redux';
import { fetchLogs } from '../actions/logActions';

import { Row, Col, MuiTable } from '@mycentyr/centyr-ui';
import LogSkeleeton from './LogSkeleeton';

class Logs extends React.Component {

    componentDidMount() {
        this.props.fetchLogs();
    }

    render() {

        const headCells = [
            { id: 'log_action', numeric: false, disablePadding: false, label: 'Action' },
            { id: 'log_description', numeric: false, disablePadding: false, label: 'Description' },
            { id: 'model_name', numeric: false, disablePadding: false, label: 'Model' },
            { id: 'model_id', numeric: false, disablePadding: false, label: 'Model Id' },
            { id: 'created_by', numeric: false, disablePadding: false, label: 'User Name' },
            { id: 'created_at', numeric: false, disablePadding: false, label: 'Timestamp' },
        ];

        const createData = (id, log_action, log_description, model_name, model_id, created_by, created_at) => {
            return { id, log_action, log_description, model_name, model_id, created_by, created_at };
        }

        var rows = [];
        if (this.props.logs) {
            this.props.logs.map(log =>
                rows.push(createData(log.id, log.log_action, log.log_description, log.model_name, log.model_id, log.created_by, log.created_at))
            )
        }

        const onReload = (rowsPerPage, keyword) => {
            this.props.fetchLogs(1, rowsPerPage, keyword);
        }

        const handlePaginationChange = (pageNo, perPage, keyword) => {
            this.props.fetchLogs(pageNo, perPage, keyword);
        }

        const onSearch = (keyword, perPage) => {
            if (keyword.length > 2 || keyword.length === 0)
                this.props.fetchLogs(1, perPage, keyword);
        }

        return (
            <div>
                <div className="page-header">
                    <Row>
                        <Col className="page-title">
                            <h1>{this.props.title}</h1>
                        </Col>
                    </Row>
                </div>
                <div className="content-wrapper">
                    <MuiTable
                        headCells={headCells}
                        rows={rows}
                        onReload={onReload}  // comment this to disable refresh option
                        isFetchPending={this.props.isFetchLogPending}
                        noOfRecords={this.props.noOfRecords}
                        handlePaginationChange={handlePaginationChange}
                        onSearch={onSearch}
                        CustomSkeleton={LogSkeleeton}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isFetchLogsuccess: state.log.isFetchLogsuccess,
        isFetchLogPending: state.log.isFetchLogPending,
        fetchLogError: state.log.fetchLogError,
        logs: state.log.logs,
        noOfRecords: state.log.noOfRecords,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchLogs: (pageNo, perPage, keyword) => { dispatch(fetchLogs(pageNo, perPage, keyword)) },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Logs);