import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './style.scss';

const App = require('app').default;

Sentry.init({
    dsn: "https://0c2be6482d0f480e9937a6a22389e082@o525799.ingest.sentry.io/5640506",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));