import React from 'react';

class Footer extends React.Component {
  render() {

    return (
      <footer className="footer text-center ">
        All Rights Reserved by Centyr. Designed and Developed by{' '}
        <a href="https://ethericsolution.com">Etheric Solution</a>.

      </footer>
    );
  }
}
export default Footer;
