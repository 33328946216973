import { instance } from 'utils/axios'
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_SETTING_SUCCESS,
    FETCH_SETTING_PENDING,
    FETCH_SETTING_ERROR,
    FETCH_SETTING,
    FETCH_SETTING_COUNT,
    SAVE_SETTING_ERROR,
    SAVE_SETTING_PENDING,
    SAVE_SETTING_SUCCESS,
} from './types';

const setFetchSettingSuccess = isFetchSettingSuccess => dispatch => {
    dispatch({
        type: FETCH_SETTING_SUCCESS,
        payload: isFetchSettingSuccess
    });
}

const setFetchSettingPending = isFetchSettingPending => dispatch => {
    dispatch({
        type: FETCH_SETTING_PENDING,
        payload: isFetchSettingPending
    });
}

const setSaveSettingPending = isSaveSettingPending => dispatch => {
    dispatch({
        type: SAVE_SETTING_PENDING,
        payload: isSaveSettingPending
    });
}

export const setSaveSettingError = saveSettingError => dispatch => {
    dispatch({
        type: SAVE_SETTING_ERROR,
        payload: saveSettingError
    });
}

export const setSaveSettingSuccess = isSaveSettingSuccess => dispatch => {
    dispatch({
        type: SAVE_SETTING_SUCCESS,
        payload: isSaveSettingSuccess
    });
}

export const setFetchSettingError = fetchSettingError => dispatch => {
    dispatch({
        type: FETCH_SETTING_ERROR,
        payload: fetchSettingError
    });
}

export const fetchSettings = (group) => async dispatch => {

    dispatch(setFetchSettingPending(true));
    dispatch({
        type: FETCH_SETTING_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_SETTING,
        payload: null
    });

    instance.post(`settings/get/${group}`, {})
        .then(response => {
            dispatch(setFetchSettingSuccess(true));
            dispatch({
                type: FETCH_SETTING_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_SETTING,
                payload: response.data.data
            });
            dispatch(setFetchSettingPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchSettingError(err.response.data.data));
            dispatch(setFetchSettingPending(false));
        });

}

export const saveSetting = (group, postData) => async dispatch => {
    dispatch(setSaveSettingSuccess(false));
    dispatch(setSaveSettingPending(true));
    instance.post(`settings/update/${group}`, postData)
        .then(response => {
            CustomSnackbar('success', 'Setting updated successfully');
            dispatch(setSaveSettingSuccess(true));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setSaveSettingError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setSaveSettingPending(false));
        });

}
