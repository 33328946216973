import React, { Fragment } from 'react';
import { TableCell, Skeleton } from '@mycentyr/centyr-ui';

export default function DepartmentSkeleeton() {
  return (
    <Fragment>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" />
      </TableCell>
    </Fragment>
  );
}
