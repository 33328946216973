import React from 'react';
import Helmet from 'react-helmet';
import { appName } from 'utils/axios'

const TitleProvider = props => {
    const { component: Component, title, ...rest } = props;

    var defaultTitle = title;

    return (
        <React.Fragment>
            <Helmet>
                <title>{title ? title + appName : defaultTitle}</title>
            </Helmet>
            <Component {...rest} title={title} />
        </React.Fragment>
    );
};

export default TitleProvider;