import React from 'react';
import { connect } from 'react-redux';
import { fetchPermissions } from 'app/setup/permission/actions/permissionActions';
import { fetchAllGroups } from 'app/setup/group/actions/groupActions'
import { fetchAllApplications } from 'app/setup/application/actions/applicationActions'
import { allowRolePermissions, denyRolePermissions } from 'app/manage/role/actions/roleActions';
import { allowUserPermissions, denyUserPermissions } from 'app/manage/user/actions/userActions';

import {
    MuiTable,
    Button,
    IconButton,
    Drawer,
    FormControl,
    Select,
    Row,
    Col,
    InputLabel
} from '@mycentyr/centyr-ui';

import PermissionSwitch from './PermissionSwitch'
import PermissionAllowDenyAction from './PermissionAllowDenyAction'
import PermissionSkeleeton from './PermissionSkeleeton'

import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'

class PermissionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            group_id: 0,
            application_id: 0,
            allowedPermissions: this.props.allowedPermissions
        }
    }
    componentDidMount() {
        this.props.fetchAllGroups();
        this.props.fetchAllApplications();
        this.props.fetchPermissions();
    }

    changePermission = (permission, action) => {
        let newPermissions = [...this.state.allowedPermissions];
        if (!Array.isArray(permission)) {
            const index = newPermissions.indexOf(permission);
            if (index > -1) {
                newPermissions.splice(index, 1);
            }
        } else {
            newPermissions = newPermissions.filter(function (el) {
                return permission.join().indexOf(el) < 0;
            })
        }

        if (action === 'allow') {
            let newArray = []
            newPermissions.push(permission);
            newArray = newPermissions.concat(permission);
            this.setState({ allowedPermissions: newArray })
            if (this.props.type === 'role')
                this.props.allowRolePermissions(this.props.id, permission, this.state.group_id, this.state.application_id)
            else
                this.props.allowUserPermissions(this.props.id, permission, this.state.group_id, this.state.application_id)
        } else {
            this.setState({ allowedPermissions: newPermissions })
            if (this.props.type === 'role')
                this.props.denyRolePermissions(this.props.id, permission, this.state.group_id, this.state.application_id)
            else
                this.props.denyUserPermissions(this.props.id, permission, this.state.group_id, this.state.application_id)
        }
    }

    render() {
        const headCells = [
            { id: 'permission_name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'permission_slug', numeric: false, disablePadding: false, label: 'Permission' },
            { id: 'application_name', numeric: false, disablePadding: false, label: 'App Name' },
            { id: 'group_name', numeric: false, disablePadding: false, label: 'Group Name' },
            { id: 'action', numeric: true, disablePadding: false, label: 'Status' },
        ];

        const createData = (id, permission_name, permission_slug, group_name, application_name) => {
            return { id, permission_name, permission_slug, group_name, application_name };
        }

        const handlePaginationChange = (pageNo, perPage, keyword) => {
            this.props.fetchPermissions(pageNo, perPage, keyword, this.state.group_id, this.state.application_id);
        }

        const onSearch = (keyword, perPage) => {
            if (keyword.length > 2 || keyword.length === 0)
                this.props.fetchPermissions(1, perPage, keyword, this.state.group_id, this.state.application_id);
        }

        const onClose = () => {
            this.setState({ ...this.state, open: !this.state.open })
        }

        const FilterActionButton = () => {
            return (
                <IconButton className='no-border-onfocus' aria-label="Filter" onClick={() => this.setState({ ...this.state, open: true })}>
                    <FilterListIcon />
                </IconButton>
            )
        }

        var rows = [];
        if (this.props.permissions) {
            this.props.permissions.map(permission =>
                rows.push(createData(permission.id, permission.permission_name, permission.permission_slug, permission.group_name, permission.application_name))
            )
        }

        return (
            <React.Fragment>
                <Drawer
                    anchor="right"
                    classes={{ paper: 'filter-paper' }}
                    onClose={onClose}
                    open={this.state.open}
                    variant="temporary"
                >
                    <div className='filter-action-buttons-container'>
                        <Button
                            onClick={onClose}
                            color="danger"
                            outline
                            size="sm"
                        >
                            <CloseIcon />{' '}Close
                        </Button>
                        <Row className="mt-3">
                            <Col lg={12}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className="mb-3"
                                    onChange={(event) => this.setState({ group_id: event.target.value })}
                                >
                                    <InputLabel htmlFor="group_id">Group</InputLabel>
                                    <Select
                                        native
                                        value={this.state.group_id}
                                        inputProps={{
                                            name: 'group_id',
                                            id: 'group_id',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {this.props.groups && this.props.groups.map((group) =>
                                            <option value={group.id} key={group.id}>{group.application_group_name}</option>
                                        )
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className="mb-3"
                                    onChange={(event) => this.setState({ application_id: event.target.value })}
                                >
                                    <InputLabel htmlFor="application_id">App</InputLabel>
                                    <Select
                                        native
                                        value={this.state.application_id}
                                        inputProps={{
                                            name: 'application_id',
                                            id: 'application_id',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {this.props.applications && this.props.applications.map((application) =>
                                            <option value={application.id} key={application.id}>{application.application_name}</option>
                                        )
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Button
                            color="primary"
                            block
                            outline
                            className='filter-action-button'
                            onClick={() => { this.setState({ application_id: 0, group_id: 0 }) }}
                        >
                            <DeleteIcon />{' '}Clear
                        </Button>
                        <Button
                            color="primary"
                            block
                            className='filter-action-button'
                            onClick={() => { this.props.fetchPermissions(1, this.props.perPage, this.props.keyword, this.state.group_id, this.state.application_id); onClose(); }}
                        >
                            Apply filters
                        </Button>
                    </div>
                </Drawer>
                <MuiTable
                    headCells={headCells}
                    rows={rows}
                    isFetchPending={this.props.isFetchPermissionPending}
                    title="Permissions"
                    noOfRecords={this.props.noOfRecords}
                    handlePaginationChange={handlePaginationChange}
                    onSearch={onSearch}
                    Actions={PermissionSwitch}
                    MassActions={PermissionAllowDenyAction}
                    FilterComponent={FilterActionButton}
                    CustomSkeleton={PermissionSkeleeton}
                    chooseColumn={false}
                    otherProps={{ allowedPermissions: this.state.allowedPermissions, changePermission: this.changePermission }}
                />
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isFetchPermissionPending: state.permission.isFetchPermissionPending,
        permissions: state.permission.permissions,
        noOfRecords: state.permission.noOfRecords,
        groups: state.applicationgroup.groups,
        applications: state.application.applications,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchPermissions: (pageNo, perPage, keyword, group_id, application_id) => { dispatch(fetchPermissions(pageNo, perPage, keyword, group_id, application_id)) },
        fetchAllApplications: () => { dispatch(fetchAllApplications()) },
        fetchAllGroups: () => { dispatch(fetchAllGroups()) },
        allowRolePermissions: (id, permissions, group_id, application_id) => { dispatch(allowRolePermissions(id, permissions, group_id, application_id)) },
        denyRolePermissions: (id, permissions, group_id, application_id) => { dispatch(denyRolePermissions(id, permissions, group_id, application_id)) },
        allowUserPermissions: (id, permissions, group_id, application_id) => { dispatch(allowUserPermissions(id, permissions, group_id, application_id)) },
        denyUserPermissions: (id, permissions, group_id, application_id) => { dispatch(denyUserPermissions(id, permissions, group_id, application_id)) },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PermissionTable);
