import React, { useEffect } from 'react'
import { Prompt } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import { addCustomer, updateCustomer } from '../actions/customerActions';
import { loginAs } from 'app/auth/actions/authActions';
import useEscape from "layouts/useEscape";
import {
    Row,
    Col,
    CustomSpinner,
    TextField,
    Button,
    Card,
    CardBody,
    Box,
    Typography,
    Avatar
} from '@mycentyr/centyr-ui';

//eslint-disable-next-line
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const schema = yup.object().shape({
    user_firstname: yup.string().required('First Name is required'),
    user_lastname: yup.string().required('Last Name is required'),
    user_display_name: yup.string().required('Display Name is required'),
    user_username: yup.string().required('Username is required'),
    user_email: yup
        .string()
        .email('Email is invalid')
        .required('Email is required'),
    user_phone_number: yup
        .string()
        .matches(phoneRegExp, 'Phone number is invalid')
        .required('Phone number is required'),
    user_password: yup
        .string()
        .oneOf(
            [yup.ref('confirm_password'), null],
            'Password and Confirm Password does not match'
        )
        .min(6, 'Password must be Atleast 6 Charecters'),
});

const initialValue = {
    user_firstname: '',
    user_lastname: '',
    user_display_name: '',
    user_username: '',
    user_email: '',
    user_phone_number: '',
    user_password: '',
}

const CustomerForm = (props) => {
    const id = props.id;
    useEscape(() => history.push("list"));
    useEffect(() => {
        reset(id === "add" ?
            initialValue : {
                user_firstname: props.customerData.user_firstname || '',
                user_lastname: props.customerData.user_lastname || '',
                user_display_name: props.customerData.user_display_name || '',
                user_username: props.customerData.user_username || '',
                user_email: props.customerData.user_email || '',
                user_phone_number: props.customerData.user_phone_number || '',
                user_password: '',
            })
    }, [props.customerData, id]);

    const { register, handleSubmit, errors, reset, formState } = useForm({
        mode: "onblur",
        resolver: yupResolver(schema),
    });

    const { dirtyFields } = formState;
    const isDirtyAlt = !!Object.keys(dirtyFields).length;

    const onSubmit = data => {
        const postData = {
            user_firstname: data.user_firstname,
            user_lastname: data.user_lastname,
            user_display_name: data.user_display_name,
            user_username: data.user_username,
            user_email: data.user_email,
            user_phone_number: data.user_phone_number,
            user_password: data.user_password,
        };
        id === "add" ? props.addCustomer(postData) : props.updateCustomer(id, postData);
    };

    if (!props.isAddUpdateCustomerPending && props.isCustomerDataPending)
        return <CustomSpinner />;
    else
        return (
            <React.Fragment>
                <Prompt
                    when={isDirtyAlt}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <Row>
                    {id !== 'add' && (
                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        flexDirection="column"
                                        textAlign="center"
                                    >
                                        <Avatar
                                            style={{ height: 100, width: 100 }}
                                            src={props.customerData.user_profile}
                                        />
                                        <Typography gutterBottom variant="h5">
                                            {props.customerData.user_display_name}
                                        </Typography>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.loginAs({ uid: props.customerData.user_uid });
                                            }}
                                        >
                                            Login as Customer
                                        </Button>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    <Col lg={id !== 'add' ? 8 : 12}>
                        <Card>
                            <CardBody className="">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {props.isAddUpdateCustomerPending && <CustomSpinner />}

                                    <Row>
                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="First Name *"
                                                className="mb-3"
                                                name="user_firstname"
                                                id="user_firstname"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                                                error={errors.user_firstname?.message ? true : false}
                                                helperText={errors.user_firstname?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Last Name *"
                                                className="mb-3"
                                                name="user_lastname"
                                                id="user_lastname"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                                                error={errors.user_lastname?.message ? true : false}
                                                helperText={errors.user_lastname?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Display Name *"
                                                className="mb-3"
                                                name="user_display_name"
                                                id="user_display_name"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                                                error={errors.user_display_name?.message ? true : false}
                                                helperText={errors.user_display_name?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Userame *"
                                                className="mb-3"
                                                name="user_username"
                                                id="user_username"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                                                error={errors.user_username?.message ? true : false}
                                                helperText={errors.user_username?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Email Address *"
                                                className="mb-3"
                                                name="user_email"
                                                id="user_email"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                                                error={errors.user_email?.message ? true : false}
                                                helperText={errors.user_email?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Phone Number *"
                                                className="mb-3"
                                                name="user_phone_number"
                                                id="user_phone_number"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                                                error={errors.user_phone_number?.message ? true : false}
                                                helperText={errors.user_phone_number?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>
                                    {id === 'add' && (
                                        <Row>
                                            <Col lg="6" xs="12">
                                                <TextField
                                                    label="Password"
                                                    inputRef={register}
                                                    className="mb-3"
                                                    name="user_password"
                                                    type="password"
                                                    id="user_password"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    autoComplete="off"
                                                    error={errors.user_password?.message ? true : false}
                                                    helperText={errors.user_password?.message}
                                                />
                                            </Col>

                                            <Col lg="6" xs="12">
                                                <TextField
                                                    label="Confirm Password"
                                                    inputRef={register}
                                                    className="mb-3"
                                                    name="confirm_password"
                                                    type="password"
                                                    id="confirm_password"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    <Row>
                                        <Col lg="12" xs="12" className="text-right">
                                            <Button type="submit" color="primary" disabled={props.isAddUpdateCustomerPending}>{id === "add" ? "Create" : "Update"}</Button>
                                            <Button
                                                className="ml-2"
                                                type="button"
                                                color="secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    history.push("list");
                                                }}
                                            >
                                                Cancel
                                    </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        )
}

const mapStateToProps = (state) => {
    return {
        customerData: state.customer.customerData,
        isAddUpdateCustomerPending: state.customer.isAddUpdateCustomerPending,
        addUpdateCustomerError: state.customer.addUpdateCustomerError,
        isCustomerDataPending: state.customer.isCustomerDataPending,
        isAddUpdateCustomerSuccess: state.customer.isAddUpdateCustomerSuccess,
        isFetchRolePending: state.customer.isFetchRolePending,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCustomer: (postData) => { dispatch(addCustomer(postData)) },
        updateCustomer: (id, postData) => { dispatch(updateCustomer(id, postData)) },
        loginAs: (postData) => { dispatch(loginAs(postData)); },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);