//role
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_PENDING = 'FETCH_ROLE_PENDING';
export const FETCH_ROLE_ERROR = 'FETCH_ROLE_ERROR';
export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_COUNT = 'FETCH_ROLE_COUNT';
export const ADD_UPDATE_ROLE_ERROR = 'ADD_UPDATE_ROLE_ERROR';
export const FETCH_ONE_ROLE = 'FETCH_ONE_ROLE';
export const FETCH_ONE_ROLE_PENDING = 'FETCH_ONE_ROLE_PENDING';
export const ADD_UPDATE_ROLE_PENDING = 'ADD_UPDATE_ROLE_PENDING';
export const ADD_UPDATE_ROLE_SUCCESS = 'ADD_UPDATE_ROLE_SUCCESS';