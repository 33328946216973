import React, { useEffect, useLayoutEffect } from 'react';
import { Prompt } from 'react-router';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { connect } from 'react-redux';
import {
  fetchSettings,
  saveSetting,
} from 'app/manage/settings/actions/settingsActions';
import { fetchAllRoles } from 'app/manage/role/actions/roleActions';
import { fetchCountries } from 'app/manage/settings/actions/countryActions';

import {
  Row,
  Col,
  Card,
  CardBody,
  TextField,
  Button,
  CardTitle,
  FormControlLabel,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  CustomSpinner,
} from '@mycentyr/centyr-ui';

const languages = [{ value: 'en', label: 'English' }];
const charsets = [{ value: 'utf-8', label: 'UTF-8' }];
const timezone_strings = [{ value: 'london', label: 'London' }];
const start_of_weeks = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
];

const schema = yup.object().shape({
  app_name: yup.string().required('Application Name/ Company Name is required'),
});

function GeneralSettings(props) {
  const [group, SetGroup] = React.useState('general');
  const roleLabel = React.useRef(null);
  const [roleLabelWidth, setRoleLabelWidth] = React.useState(0);

  const languageLabel = React.useRef(null);
  const [languageLabelWidth, setLanguageLabelWidth] = React.useState(0);

  const charsetLabel = React.useRef(null);
  const [charsetLabelWidth, setCharsetLabelWidth] = React.useState(0);

  const timezoneLabel = React.useRef(null);
  const [timezoneLabelWidth, setTimezoneLabelWidth] = React.useState(0);

  const weekLabel = React.useRef(null);
  const [weekLabelWidth, setWeekLabelWidth] = React.useState(0);

  const countryLabel = React.useRef(null);
  const [countryLabelWidth, setCountryLabelWidth] = React.useState(0);
  useEffect(() => {
    props.fetchAllRoles();
    props.fetchCountries();
    props.fetchSettings(group);
  }, []);

  useLayoutEffect(() => {
    if (!props.isFetchSettingPending) {
      setRoleLabelWidth(roleLabel.current?.offsetWidth);
      setLanguageLabelWidth(languageLabel.current?.offsetWidth);
      setCharsetLabelWidth(charsetLabel.current?.offsetWidth);
      setTimezoneLabelWidth(timezoneLabel.current?.offsetWidth);
      setWeekLabelWidth(weekLabel.current?.offsetWidth);
      setCountryLabelWidth(countryLabel.current?.offsetWidth);
    }
  }, [props.isFetchSettingPending]);

  useEffect(() => {
    props.settings &&
      reset({
        app_name: props.settings.app_name || '',
        users_can_register: props.settings.users_can_register || 0,
        default_role: props.settings.default_role || '',
        language: props.settings.language || '',
        charset: props.settings.charset || '',
        timezone_string: props.settings.timezone_string || '',
        start_of_week: props.settings.start_of_week || '',
        country: props.settings.country || '',
      });
  }, [props.roles, props.countries, props.settings]);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });
  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const onSubmit = (data) => {
    const postData = {
      app_name: data.app_name,
      users_can_register: data.users_can_register,
      default_role: data.default_role,
      language: data.language,
      charset: data.charset,
      timezone_string: data.timezone_string,
      start_of_week: data.start_of_week,
      country: data.country,
    };
    props.saveSetting(group, postData);
  };

  if (props.isFetchSettingPending) return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Prompt
          when={isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {props.isSaveSettingPending && <CustomSpinner />}
          <div className="page-header">
            <Row>
              <Col className="page-title">
                <h1 className="page-title">{props.title}</h1>
              </Col>
              <Col className="text-right page-header-button">
                <Button
                  type="submit"
                  color="primary"
                  className="mb-3"
                  disabled={props.isSaveSettingPending || !isDirtyAlt}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </div>
          <div className="content-wrapper">
            <Row>
              <Col lg="4" xs="12">
                <Card>
                  <CardTitle>App Settings</CardTitle>
                  <CardBody className="">
                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          label="Application Name/ Company Name"
                          className="mb-3"
                          name="app_name"
                          id="app_name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={
                            props.settings && props.settings.app_name
                              ? { shrink: true }
                              : {}
                          }
                          error={errors.app_name?.message ? true : false}
                          helperText={errors.app_name?.message}
                          inputRef={register}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xs="12">
                <Card>
                  <CardTitle>User Settings</CardTitle>
                  <CardBody className="">
                    <Row>
                      <Col lg="12" xs="12">
                        <FormControlLabel
                          className="mb-3"
                          control={
                            <Checkbox
                              name="users_can_register"
                              defaultChecked={
                                props.settings &&
                                props.settings.users_can_register == 1
                              }
                              value={1}
                              id="users_can_register"
                              color="primary"
                              inputRef={register}
                            />
                          }
                          label="Anyone can Register"
                        />
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.default_role?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={roleLabel}
                            htmlFor="default_role"
                          >
                            Role
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={roleLabelWidth}
                                name="default_role"
                                id="default_role"
                              />
                            }
                          >
                            {props.roles &&
                              props.roles.map((role) => (
                                <option value={role.id} key={role.id}>
                                  {role.role_name}
                                </option>
                              ))}
                          </Select>
                          <FormHelperText>
                            {errors.default_role?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xs="12">
                <Card>
                  <CardTitle>Localization Settings</CardTitle>
                  <CardBody className="">
                    <Row>
                      <Col lg="12" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.country?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={countryLabel}
                            htmlFor="country"
                          >
                            Country
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={countryLabelWidth}
                                name="country"
                                id="country"
                              />
                            }
                          >
                            {props.countries &&
                              props.countries.map((country) => (
                                <option value={country.id} key={country.id}>
                                  {country.country_nicename}
                                </option>
                              ))}
                          </Select>
                          <FormHelperText>
                            {errors.country?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.language?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={languageLabel}
                            htmlFor="language"
                          >
                            Language
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={languageLabelWidth}
                                name="language"
                                id="language"
                              />
                            }
                          >
                            {languages &&
                              languages.map((language) => (
                                <option
                                  value={language.value}
                                  key={language.value}
                                >
                                  {language.label}
                                </option>
                              ))}
                          </Select>
                          <FormHelperText>
                            {errors.language?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.charset?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={charsetLabel}
                            htmlFor="charset"
                          >
                            Default Charset
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={charsetLabelWidth}
                                name="charset"
                                id="charset"
                              />
                            }
                          >
                            {charsets &&
                              charsets.map((charset) => (
                                <option
                                  value={charset.value}
                                  key={charset.value}
                                >
                                  {charset.label}
                                </option>
                              ))}
                          </Select>
                          <FormHelperText>
                            {errors.charset?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.timezone_string?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={timezoneLabel}
                            htmlFor="timezone_string"
                          >
                            Timezone
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={timezoneLabelWidth}
                                name="timezone_string"
                                id="timezone_string"
                              />
                            }
                          >
                            {timezone_strings &&
                              timezone_strings.map((timezone_string) => (
                                <option
                                  value={timezone_string.value}
                                  key={timezone_string.value}
                                >
                                  {timezone_string.label}
                                </option>
                              ))}
                          </Select>
                          <FormHelperText>
                            {errors.timezone_string?.message}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.start_of_week?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={weekLabel}
                            htmlFor="start_of_week"
                          >
                            Week Starts On
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={weekLabelWidth}
                                name="start_of_week"
                                id="start_of_week"
                              />
                            }
                          >
                            {start_of_weeks &&
                              start_of_weeks.map((start_of_week) => (
                                <option
                                  value={start_of_week.value}
                                  key={start_of_week.value}
                                >
                                  {start_of_week.label}
                                </option>
                              ))}
                          </Select>
                          <FormHelperText>
                            {errors.start_of_week?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </form>
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  return {
    settings: state.setting.settings,
    isFetchSettingPending: state.setting.isFetchSettingPending,
    isSaveSettingPending: state.setting.isSaveSettingPending,
    roles: state.role.roles,
    countries: state.country.countries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: (group) => {
      dispatch(fetchSettings(group));
    },
    saveSetting: (group, postData) => {
      dispatch(saveSetting(group, postData));
    },
    fetchAllRoles: () => {
      dispatch(fetchAllRoles());
    },
    fetchCountries: () => {
      dispatch(fetchCountries());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
