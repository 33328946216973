import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "redux/store";
import {
  fetchTemplateTag,
  updateTemplateTag,
  addTemplateTag,
} from "../slices/templateTagSlice";

import { fetchAllApplications } from "app/setup/application/actions/applicationActions";
import { fetchAllGroups } from "app/setup/group/actions/groupActions";

import {
  Row,
  Col,
  CustomSpinner,
  TextField,
  Button,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mycentyr/centyr-ui";

const schema = yup.object().shape({
  application_id: yup.string().required("Application is required"),
  application_group_id: yup.string().required("Application Group is required"),
  template_tag_name: yup.string().required("Name is required"),
  template_tag_slug: yup.string().required("Slug is required"),
});

const initialValue = {
  application_id: "",
  application_group_id: "",
  template_tag_name: "",
  template_tag_slug: "",
  template_tag_description: "",
};

const OneTemplateTag = (props) => {
  const { id, toggle } = props;

  if (id !== "add") document.title = "Manage Template Tag";
  else document.title = "Create New Template Tag";

  const dispatch = useDispatch();
  const {
    templateData,
    isFetchTemplateTagPending,
    isAddUpdateTemplateTagPending,
  } = useSelector((state) => state.templateTag);

  const { applications } = useSelector((state) => state.application);
  const { groups } = useSelector((state) => state.applicationgroup);

  useEffect(() => {
    if (id !== "add") {
      dispatch(fetchTemplateTag(id));
    }
    dispatch(fetchAllApplications());
    dispatch(fetchAllGroups());
  }, [id]);

  useEffect(() => {
    reset(
      id === "add"
        ? initialValue
        : {
            application_id: templateData.application_id || "",
            application_group_id: templateData.application_group_id || "",
            template_tag_name: templateData.template_tag_name || "",
            template_tag_slug: templateData.template_tag_slug || "",
            template_tag_description:
              templateData.template_tag_description || "",
          }
    );
  }, [applications, groups, templateData, id]);

  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
    defaultValues: initialValue,
  });

  const onSubmit = (data) => {
    const postData = {
      application_id: data.application_id,
      application_group_id: data.application_group_id,
      template_tag_name: data.template_tag_name,
      template_tag_slug: data.template_tag_slug,
      template_tag_description: data.template_tag_description,
    };

    id !== "add"
      ? dispatch(updateTemplateTag(id, postData, toggle))
      : dispatch(addTemplateTag(postData, toggle));
  };

  if (!isAddUpdateTemplateTagPending && isFetchTemplateTagPending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        {isFetchTemplateTagPending && <CustomSpinner />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="12" md="12" xs="12">
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors.application_id}
                className="mb-3"
              >
                <InputLabel>Application</InputLabel>
                <Controller
                  as={
                    <Select native label="Application">
                      <option aria-label="None" value="" />
                      {applications.map((application) => (
                        <option value={application.id} key={application.id}>
                          {application.application_name}
                        </option>
                      ))}
                    </Select>
                  }
                  name="application_id"
                  control={control}
                />
                <FormHelperText>
                  {errors.application_id?.message}
                </FormHelperText>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12">
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors.application_group_id}
                className="mb-3"
              >
                <InputLabel>Application Group</InputLabel>
                <Controller
                  as={
                    <Select native label="Application Group">
                      <option aria-label="None" value="" />
                      {groups.map((group) => (
                        <option value={group.id} key={group.id}>
                          {group.application_group_name}
                        </option>
                      ))}
                    </Select>
                  }
                  name="application_group_id"
                  control={control}
                />
                <FormHelperText>
                  {errors.application_group_id?.message}
                </FormHelperText>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12">
              <TextField
                className="mb-3"
                label="Template Tag Name"
                name="template_tag_name"
                id="template_tag_name"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                error={errors.template_tag_name?.message ? true : false}
                helperText={errors.template_tag_name?.message}
                inputRef={register}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12">
              <TextField
                className="mb-3"
                label="Template Tag"
                name="template_tag_slug"
                id="template_tag_slug"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                error={errors.template_tag_slug?.message ? true : false}
                helperText={errors.template_tag_slug?.message}
                inputRef={register}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12">
              <TextField
                label="Template Tag Description"
                className="mb-3"
                name="template_tag_description"
                id="template_tag_description"
                variant="outlined"
                size="small"
                multiline
                rows="2"
                fullWidth
                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                error={errors.template_tag_description}
                helperText={errors.template_tag_description?.message}
                inputRef={register}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12" xs="12" className="text-right">
              <Button
                type="submit"
                color="primary"
                disabled={isAddUpdateTemplateTagPending}
              >
                {id === "add" ? "Create" : "Update"}
              </Button>
              <Button
                className="ml-2"
                type="button"
                color="secondary"
                onClick={toggle}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
};

export default OneTemplateTag;
