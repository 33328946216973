import React from 'react';
import { connect } from 'react-redux';
import {
  fetchCustomers,
  setFetchCustomerError,
  deleteCustomers,
} from '../actions/customerActions';
import CustomerActionButtons from './CustomerActionButtons';
import { history } from 'helpers/history';
import CustomerSkeleeton from './CustomerSkeleeton';

import { Row, Col, MuiTable, Button, Badge } from '@mycentyr/centyr-ui';

function CustomBadge(props) {
  const { text, ...rest } = props;

  return (
    <div>
      <Badge {...rest}>{text}</Badge>
    </div>
  );
}
class CustomerList extends React.Component {
  componentDidMount() {
    this.props.fetchCustomers();
  }

  render() {
    const headCells = [
      {
        id: 'user_username',
        numeric: false,
        disablePadding: false,
        label: 'Account No',
      },
      {
        id: 'display_name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email Address',
      },
      {
        id: 'phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone Number',
      },
      {
        id: 'created',
        numeric: true,
        disablePadding: false,
        label: 'Created At',
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        component: CustomBadge,
      },
      { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
    ];

    const createData = (
      id,
      user_username,
      display_name,
      email,
      phone,
      created,
      status,
      uid,
      customProps
    ) => {
      return {
        id,
        user_username,
        display_name,
        email,
        phone,
        created,
        status,
        uid,
        customProps,
      };
    };
    var rows = [];
    if (this.props.customers) {
      this.props.customers.map((customer) =>
        rows.push(
          createData(
            customer.id,
            customer.user_username,
            customer.user_display_name,
            customer.user_email,
            customer.user_phone_number,
            customer.created_at,
            customer.is_active === '1'
              ? 'Active'
              : customer.is_active === '0'
              ? 'Deactive'
              : 'Pending',
            customer.user_uid,
            {
              color:
                customer.is_active === '1'
                  ? 'success'
                  : customer.is_active === '1'
                  ? 'danger'
                  : 'secondary',
            }
          )
        )
      );
    }

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchCustomers(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchCustomers(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteCustomers(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchCustomers(1, perPage, keyword);
    };

    return (
      <div>
        <div className='page-header'>
          <Row>
            <Col className='page-title'>
              <h1>{this.props.title}</h1>
            </Col>
            <Col className='text-right page-header-button'>
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes(
                  'add.crm.customers'
                ) && (
                  <Button
                    color='primary'
                    className='mb-3'
                    onClick={() => {
                      history.push('add');
                    }}
                  >
                    Add Customer
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className='content-wrapper'>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchCustomerPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes(
                'delete.crm.customers'
              )
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={CustomerSkeleeton}
            Actions={CustomerActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchCustomerSuccess: state.customer.isFetchCustomerSuccess,
    isFetchCustomerPending: state.customer.isFetchCustomerPending,
    fetchCustomerError: state.customer.fetchCustomerError,
    customers: state.customer.customers,
    noOfRecords: state.customer.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomers: (pageNo, perPage, keyword) => {
      dispatch(fetchCustomers(pageNo, perPage, keyword));
    },
    setFetchCustomerError: (postData) => {
      dispatch(setFetchCustomerError(postData));
    },
    deleteCustomers: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteCustomers(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
