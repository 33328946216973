
//shipping
export const FETCH_SHIPPING_SUCCESS = 'FETCH_SHIPPING_SUCCESS';
export const FETCH_SHIPPING_PENDING = 'FETCH_SHIPPING_PENDING';
export const FETCH_SHIPPING_ERROR = 'FETCH_SHIPPING_ERROR';
export const FETCH_SHIPPING = 'FETCH_SHIPPING';
export const FETCH_SHIPPING_COUNT = 'FETCH_SHIPPING_COUNT';
export const ADD_UPDATE_SHIPPING_ERROR = 'ADD_UPDATE_SHIPPING_ERROR';
export const FETCH_ONE_SHIPPING = 'FETCH_ONE_SHIPPING';
export const FETCH_ONE_SHIPPING_PENDING = 'FETCH_ONE_SHIPPING_PENDING';
export const ADD_UPDATE_SHIPPING_PENDING = 'ADD_UPDATE_SHIPPING_PENDING';
export const ADD_UPDATE_SHIPPING_SUCCESS = 'ADD_UPDATE_SHIPPING_SUCCESS';
export const FETCH_PARENT_SHIPPING = 'FETCH_PARENT_SHIPPING';
export const FETCH_PARENT_SHIPPING_PENDING = 'FETCH_PARENT_SHIPPING_PENDING';
