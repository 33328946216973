import React from "react";
import { connect } from "react-redux";
import {
  fetchEmailProviders,
  setFetchEmailProviderError,
  deleteEmailProviders,
} from "../actions/emailProviderActions";
import EmailProviderActionButtons from "./EmailProviderActionButtons";
import EmailProviderForm from "./EmailProviderForm";
import EmailProviderSkeleeton from "./EmailProviderSkeleeton";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  Button,
  Badge,
} from "@mycentyr/centyr-ui";

function CustomBadge(props) {
  const { text, ...rest } = props;
  let color = "success";
  if (text === "active") color = "success";
  else if (text === "hidden") color = "secondary";
  else color = "danger";

  return (
    <div>
      <Badge color={color}>{text}</Badge>
    </div>
  );
}
class EmailProviderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    this.props.fetchEmailProviders();
  }

  render() {
    const headCells = [
      {
        id: "emailprovider_name",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "emailprovider_smtp_host",
        numeric: false,
        disablePadding: false,
        label: "SMTP Host",
      },
      {
        id: "emailprovider_sender_name",
        numeric: false,
        disablePadding: false,
        label: "Sender Name",
      },
      {
        id: "emailprovider_sender_email",
        numeric: false,
        disablePadding: false,
        label: "Sender Email",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        component: CustomBadge,
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (
      id,
      emailprovider_name,
      emailprovider_smtp_host,
      emailprovider_sender_name,
      emailprovider_sender_email,
      status
    ) => {
      return {
        id,
        emailprovider_name,
        emailprovider_smtp_host,
        emailprovider_sender_name,
        emailprovider_sender_email,
        status,
      };
    };
    var rows = [];
    if (this.props.emailproviders) {
      this.props.emailproviders.map((emailprovider) =>
        rows.push(
          createData(
            emailprovider.id,
            emailprovider.emailprovider_name,
            emailprovider.emailprovider_smtp_host,
            emailprovider.emailprovider_sender_name,
            emailprovider.emailprovider_sender_email,
            emailprovider.status
          )
        )
      );
    }

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchEmailProviders(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchEmailProviders(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteEmailProviders(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchEmailProviders(1, perPage, keyword);
    };

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Email Provider</ModalHeader>
          <ModalBody>
            <EmailProviderForm id="add" toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes(
                  "add.emailproviders"
                ) && (
                  <Button className="mb-3" color="primary" onClick={toggle}>
                    Add Email Provider
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchEmailProviderPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes(
                "delete.emailproviders"
              )
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={EmailProviderSkeleeton}
            Actions={EmailProviderActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchEmailProviderSuccess:
      state.emailprovider.isFetchEmailProviderSuccess,
    isFetchEmailProviderPending:
      state.emailprovider.isFetchEmailProviderPending,
    fetchEmailProviderError: state.emailprovider.fetchEmailProviderError,
    emailproviders: state.emailprovider.emailproviders,
    noOfRecords: state.emailprovider.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailProviders: (pageNo, perPage, keyword) => {
      dispatch(fetchEmailProviders(pageNo, perPage, keyword));
    },
    setFetchEmailProviderError: (postData) => {
      dispatch(setFetchEmailProviderError(postData));
    },
    deleteEmailProviders: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteEmailProviders(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailProviderList);
