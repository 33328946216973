import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import { fetchTickets, deleteTickets } from "../slices/ticketSlice";
import TicketActionButtons from "./TicketActionButtons";
import { history } from "helpers/history";
import TicketSkeleeton from "./TicketSkeleeton";

import { Row, Col, MuiTable, Button } from "@mycentyr/centyr-ui";

const TicketList = (props) => {
  const dispatch = useDispatch();
  const { tickets, noOfRecords, isFetchTicketsPending } = useSelector(
    (state) => state.ticket
  );

  const headCells = [
    {
      id: "subject",
      numeric: false,
      disablePadding: false,
      label: "Subject",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  const createData = (id, subject, status) => {
    return { id, subject, status };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchTickets(1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchTickets(pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deleteTickets(postData, pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchTickets(1, perPage, keyword));
  };

  var rows = [];
  tickets.map((ticket) =>
    rows.push(
      createData(ticket.id, ticket.ticket_subject, ticket.ticket_status)
    )
  );

  useEffect(() => {
    dispatch(fetchTickets());
  }, []);

  return (
    <div>
      <div className="page-header">
        <Row>
          <Col className="page-title">
            <h1>{props.title}</h1>
          </Col>
          <Col className="text-right page-header-button">
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes(
                "add.helpdesk.tickets"
              ) && (
                <Button
                  color="primary"
                  className="mb-3"
                  onClick={() => {
                    history.push("add");
                  }}
                >
                  Add Ticket
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchTicketsPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes("delete.helpdesk.tickets")
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={TicketSkeleeton}
          Actions={TicketActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(TicketList);
