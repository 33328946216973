import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';
import { changePassword, resetPassword } from '../../../auth/actions/authActions';

import {
    Row,
    Col,
    CardTitle,
    CustomSpinner,
    TextField,
    Button,
    Card,
    CardBody,
} from '@mycentyr/centyr-ui';

const schema = yup.object().shape({
    new_password: yup.string().required('New Password is required')
        .oneOf([yup.ref('confirm_password'), null], 'Confirm Password does not match')
        .min(6, 'Password must be Atleast 6 Charecters'),
});

const schema2 = yup.object().shape({
    email: yup.string().required('Email is required')
        .email('Email is invalid'),
});

const UserPassword = (props) => {
    const id = props.id;

    let userData = props.userData;
    if (props.role === 'customer')
        userData = props.customerData;

    const { register, handleSubmit, errors } = useForm({
        mode: "onblur",
        resolver: yupResolver(schema),
    });
    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema2),
    });

    const onSubmit = data => {
        const postData = {
            current_password: data.current_password,
            new_password: data.new_password,
        };
        props.changePassword(postData, userData.id);
    };

    const onSubmit2 = data => {
        const postData = {
            email: data.email,
        };
        props.resetPassword(postData);
    };

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="4">
                        <Card>
                            <CardTitle>
                                Change Password
                            </CardTitle>
                            <CardBody className="mt-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col lg="12" xs="12">
                                            <TextField
                                                label="New Password"
                                                className="mb-3"
                                                name="new_password"
                                                id="new_password"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                type="password"
                                                autoComplete="off"
                                                error={errors.new_password?.message ? true : false}
                                                helperText={errors.new_password?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="12" xs="12">
                                            <TextField
                                                label="Confirm Password"
                                                className="mb-3"
                                                name="confirm_password"
                                                id="confirm_password"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                type="password"
                                                autoComplete="off"
                                                error={errors.confirm_password?.message ? true : false}
                                                helperText={errors.confirm_password?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12" xs="12">
                                            <Button className="pull-right" type="submit" color="primary" >Change Password</Button>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card>
                            <CardTitle>
                                Reset Password
                            </CardTitle>
                            <CardBody className="mt-3">
                                <form onSubmit={handleSubmit2(onSubmit2)}>
                                    <Row>
                                        <Col lg="12" xs="12">
                                            <TextField
                                                label="Email Address"
                                                className="mb-3"
                                                name="email"
                                                id="email"
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                defaultValue={userData.user_email}
                                                fullWidth
                                                autoComplete="off"
                                                error={errors2.email?.message ? true : false}
                                                helperText={errors2.email?.message}
                                                inputRef={register2}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12" xs="12">
                                            <Button className="pull-right" type="submit" color="primary" >Send Reset Password Link</Button>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        authUserData: state.authUser.userData,
        userData: state.user.userData,
        customerData: state.customer.customerData,
        isResetPasswordPending: state.auth.isResetPasswordPending,
        resetPasswordError: state.auth.resetPasswordError,
        resetPasswordSuccess: state.auth.resetPasswordSuccess,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (postData, id) => { dispatch(changePassword(postData, id)) },
        resetPassword: (postData) => { dispatch(resetPassword(postData)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPassword);