import {
    FETCH_GROUP_SUCCESS,
    FETCH_GROUP_PENDING,
    FETCH_GROUP_ERROR,
    FETCH_GROUP,
    FETCH_GROUP_COUNT,
    ADD_UPDATE_GROUP_ERROR,
    FETCH_ONE_GROUP,
    FETCH_ONE_GROUP_PENDING,
    ADD_UPDATE_GROUP_PENDING,
    ADD_UPDATE_GROUP_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchGroupSuccess: false,
    isFetchGroupPending: false,
    fetchGroupError: null,
    addUpdateGroupError: null,
    groupData: [],
    isGroupDataPending: false,
    groups: [],
    noOfRecords: 0,
    isAddUpdateGroupPending: false,
    isAddUpdateGroupSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_GROUP:
            return {
                ...state,
                groups: action.payload
            };

        case FETCH_GROUP_SUCCESS:
            return {
                ...state,
                isFetchGroupSuccess: action.payload
            };

        case FETCH_GROUP_PENDING:
            return {
                ...state,
                isFetchGroupPending: action.payload
            };

        case FETCH_GROUP_ERROR:
            return {
                ...state,
                fetchGroupError: action.payload
            };

        case FETCH_GROUP_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_GROUP:
            return {
                ...state,
                groupData: action.payload
            };

        case ADD_UPDATE_GROUP_ERROR:
            return {
                ...state,
                addUpdateGroupError: action.payload
            };

        case FETCH_ONE_GROUP_PENDING:
            return {
                ...state,
                isGroupDataPending: action.payload
            };

        case ADD_UPDATE_GROUP_PENDING:
            return {
                ...state,
                isAddUpdateGroupPending: action.payload
            };

        case ADD_UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                isAddUpdateGroupSuccess: action.payload
            };

        default:
            return state;
    }
}