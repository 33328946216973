import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Switch from '@material-ui/core/Switch'
import InputBase from '@material-ui/core/InputBase'
import Grid from '@material-ui/core/Grid'
import withWidth from '@material-ui/core/withWidth'
import DeleteIcon from '@material-ui/icons/Delete'
import GetAppIcon from '@material-ui/icons/GetApp'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import CachedIcon from '@material-ui/icons/Cached'
import CustomModal from './custommodal'
import NoProagation from './NoProagation'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import DOMPurify from 'dompurify'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    state,
    headCells
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            className='checkbox-white'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontFamily: 'inherit',
              width: headCell.width ? headCell.width : ''
            }}
            className={
              state[headCell.id] || state['all'] ? '' : classes.noneDisplay
            }
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  },
  fullWidth: {
    width: '100%',
    marginRight: '0px',
    paddingRight: '16px'
  },
  reset: {
    paddingRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '5px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  selctCount: {
    flex: '0 0 100%',
    marginLeft: '8px'
  },
  paddingAround: {
    margin: '16px'
  },
  greyBG: {
    background: '#4f5467',
    color: 'white'
  },
  whiteBG: {
    background: 'white',
    color: 'black'
  },
  center: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex'
  }
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const {
    title,
    numSelected,
    state,
    setState,
    width,
    headCells,
    onReload,
    chooseColumn,
    page,
    setPage,
    rowsPerPage,
    selected,
    setSelected,
    onSearch,
    setKeyword,
    keyword,
    onMultiDelete,
    exportOption,
    FilterComponent,
    MassActions,
    multipleDelete,
    otherProps
  } = props

  const [modal, setModal] = React.useState(false)
  const toggle = () => setModal(!modal)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openedPopoverId, setOpenedPopoverId] = React.useState(null)

  const handlePopoverOpen = (event, popoverId) => {
    setOpenedPopoverId(popoverId)
    setAnchorEl(event.target)
  }

  const handlePopoverClose = () => {
    setOpenedPopoverId(null)
    setAnchorEl(null)
  }

  const onMassDelete = () => {
    toggle()
    onMultiDelete({ id: selected }, page + 1, rowsPerPage)
    setSelected([])
  }

  const handleChange = (event, id) => {
    if (id !== 'all')
      setState({
        ...state,
        [id]: event.target.checked,
        all: false
      })
    else {
      const tp = {}
      for (const headCell of headCells) {
        tp[headCell.id] = event.target.checked
      }
      setState({
        ...state,
        ...tp,
        [id]: event.target.checked
      })
    }
  }

  return (
    <Fragment>
      {title && (
        <Toolbar>
          <Typography
            className={classes.title}
            variant='h6'
            id='tableTitle'
            component='div'
          >
            {title}
          </Typography>
        </Toolbar>
      )}
      <Toolbar>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='flex-start'
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.center}
            style={{
              justifyContent: width === 'xs' ? 'space-between' : 'flex-start'
            }}
          >
            <Typography color='inherit' variant='subtitle1'>
              {numSelected} selected
            </Typography>
            {(multipleDelete || MassActions) && (
              <Typography className={classes.paddingAround}>|</Typography>
            )}
            {multipleDelete && (
              <Tooltip title='Delete'>
                <div>
                  <IconButton
                    className='no-border-onfocus'
                    disabled={selected.length < 1}
                    aria-label='delete'
                    onClick={() => {
                      if (selected.length > 0) toggle()
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {MassActions && (
              <MassActions
                selected={selected}
                setSelected={setSelected}
                page={page}
                rowsPerPage={rowsPerPage}
                keyword={keyword}
                otherProps={otherProps}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            className={classes.center}
            style={{
              justifyContent: width === 'xs' ? 'space-between' : 'flex-end'
            }}
          >
            <InputBase
              className={classes.search}
              placeholder='Search…'
              onChange={(e) => {
                setKeyword(e.target.value)
                onSearch(e.target.value, rowsPerPage)
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />

            {onReload ? (
              <Tooltip title='Refresh'>
                <IconButton
                  className='no-border-onfocus'
                  aria-label='refresh'
                  onClick={() => {
                    setPage(0)
                    onReload(rowsPerPage, keyword)
                  }}
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            {chooseColumn === undefined || chooseColumn ? (
              <Tooltip title='Columns'>
                <IconButton
                  className='no-border-onfocus'
                  aria-label='columns'
                  onClick={(e) => {
                    handlePopoverOpen(e, 'columns')
                  }}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
            ) : null}

            {FilterComponent ? (
              <FilterComponent
                selected={selected}
                setSelected={setSelected}
                page={page}
                rowsPerPage={rowsPerPage}
                keyword={keyword}
                otherProps={otherProps}
              />
            ) : null}

            <Popover
              open={openedPopoverId === 'columns'}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <List component='nav' aria-label='columns'>
                <ListItem button className={classes.reset}>
                  <FormControlLabel
                    className={classes.fullWidth}
                    control={
                      <Checkbox
                        checked={state['all']}
                        onChange={(e) => handleChange(e, 'all')}
                        value='all'
                        color='primary'
                      />
                    }
                    label='Toggle all'
                  />
                </ListItem>

                <Divider />

                {headCells.map((headCell) => (
                  <ListItem button className={classes.reset} key={headCell.id}>
                    <FormControlLabel
                      className={classes.fullWidth}
                      key={headCell.id}
                      control={
                        <Checkbox
                          checked={state[headCell.id]}
                          onChange={(e) => handleChange(e, headCell.id)}
                          value={headCell.id}
                          color='primary'
                        />
                      }
                      label={headCell.label}
                    />
                  </ListItem>
                ))}
              </List>
            </Popover>

            {exportOption && (
              <Fragment>
                <Tooltip title='Export Data'>
                  <IconButton
                    className='no-border-onfocus'
                    aria-label='export'
                    onClick={(event) => {
                      handlePopoverOpen(event, 'export')
                    }}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>

                <Popover
                  open={openedPopoverId === 'export'}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <List component='nav' aria-label='export options'>
                    <ListItem button>
                      <ListItemText primary='Excel' />
                    </ListItem>
                  </List>
                </Popover>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      {/* {isFetchPending && <LinearProgress />} */}
      <CustomModal
        onModalAccept={onMassDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle='Delete'
        modalBody='Are you sure you want to delete?'
      />
    </Fragment>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  noneDisplay: {
    display: 'none'
  }
}))

export default withWidth()(function MuiTable(props) {
  const {
    headCells,
    rows,
    title,
    onReload,
    isFetchPending,
    exportOption,
    chooseColumn,
    noOfRecords,
    handlePaginationChange,
    onSearch,
    Actions,
    onMultiDelete,
    MassActions,
    multipleDelete,
    otherProps,
    FilterComponent,
    CustomSkeleton
  } = props

  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [keyword, setKeyword] = React.useState('')

  const tp = {}
  let isAnyHidden = false
  for (const headCell of headCells) {
    tp[headCell.id] = true
    if (headCell.hidden) {
      isAnyHidden = true
      tp[headCell.id] = false
    }
  }
  const [state, setState] = React.useState({
    all: !isAnyHidden,
    ...tp
  })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, page) => {
    setPage(page)
    setSelected([])
    handlePaginationChange(page + 1, rowsPerPage, keyword)
  }

  const handleChangeRowsPerPage = (event) => {
    const eVal = event.target.value
    setRowsPerPage(eVal)
    setPage(0)
    setSelected([])
    handlePaginationChange(1, eVal, keyword)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const emptyRows = 0 // rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const skeletonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          state={state}
          setState={setState}
          width={props.width}
          headCells={headCells}
          onReload={onReload}
          title={title}
          chooseColumn={chooseColumn}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          selected={selected}
          setSelected={setSelected}
          onSearch={onSearch}
          setKeyword={setKeyword}
          keyword={keyword}
          multipleDelete={multipleDelete}
          onMultiDelete={onMultiDelete}
          exportOption={exportOption}
          MassActions={MassActions}
          FilterComponent={FilterComponent}
          isFetchPending={isFetchPending}
          otherProps={otherProps}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              state={state}
              headCells={headCells}
            />
            <TableBody>
              {isFetchPending &&
                CustomSkeleton &&
                skeletonArray.map((x, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <CustomSkeleton />
                      {headCells.map(
                        (headCell) =>
                          headCell.id === 'action' && (
                            <TableCell
                              key={headCell.id}
                              style={{
                                width: headCell.width ? headCell.width : ''
                              }}
                              align={headCell.numeric ? 'right' : 'left'}
                            >
                              <IconButton>
                                <MoreHorizIcon />
                              </IconButton>
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  )
                })}
              {noOfRecords === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={headCells.length + 1}
                    align='center'
                    style={{ fontFamily: 'inherit', fontSize: '0.975rem' }}
                    padding='default'
                  >
                    No matching record found
                  </TableCell>
                </TableRow>
              )}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(0, rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>

                      {headCells.map((headCell) =>
                        headCell.component ? (
                          <TableCell
                            style={{
                              fontFamily: 'inherit',
                              fontSize: '0.975rem'
                            }}
                            className={
                              state[headCell.id] || state['all']
                                ? ''
                                : classes.noneDisplay
                            }
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={
                              headCell.disablePadding ? 'none' : 'default'
                            }
                          >
                            <headCell.component
                              {...row.customProps}
                              text={row[headCell.id]}
                            />
                          </TableCell>
                        ) : headCell.id === 'action' ? (
                          <TableCell
                            className={
                              state[headCell.id] || state['all']
                                ? ''
                                : classes.noneDisplay
                            }
                            style={{
                              width: headCell.width ? headCell.width : ''
                            }}
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={
                              headCell.disablePadding ? 'none' : 'default'
                            }
                          >
                            <NoProagation>
                              <Actions
                                setPage={setPage}
                                setSelected={setSelected}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                keyword={keyword}
                                row={row}
                                rowLength={rows.length}
                                otherProps={otherProps}
                              />
                            </NoProagation>
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{
                              fontFamily: 'inherit',
                              fontSize: '0.975rem',
                              width: headCell.width ? headCell.width : ''
                            }}
                            className={
                              (state[headCell.id] || state['all']
                                ? ' '
                                : classes.noneDisplay) +
                              (headCell.primary ? ' primary-title-text' : ' ')
                            }
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={
                              headCell.disablePadding ? 'none' : 'default'
                            }
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                row[headCell.id] === '' ? '-' : row[headCell.id]
                              )
                            }}
                          />
                        )
                      )}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className='no-border'
          rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
          component='div'
          count={noOfRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
      />
    </div>
  )
})
