
//application
export const FETCH_APPLICATION_SUCCESS = 'FETCH_APPLICATION_SUCCESS';
export const FETCH_APPLICATION_PENDING = 'FETCH_APPLICATION_PENDING';
export const FETCH_APPLICATION_ERROR = 'FETCH_APPLICATION_ERROR';
export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const FETCH_APPLICATION_COUNT = 'FETCH_APPLICATION_COUNT';
export const ADD_UPDATE_APPLICATION_ERROR = 'ADD_UPDATE_APPLICATION_ERROR';
export const FETCH_ONE_APPLICATION = 'FETCH_ONE_APPLICATION';
export const FETCH_ONE_APPLICATION_PENDING = 'FETCH_ONE_APPLICATION_PENDING';
export const ADD_UPDATE_APPLICATION_PENDING = 'ADD_UPDATE_APPLICATION_PENDING';
export const ADD_UPDATE_APPLICATION_SUCCESS = 'ADD_UPDATE_APPLICATION_SUCCESS';
export const FETCH_PARENT_APPLICATION = 'FETCH_PARENT_APPLICATION';
export const FETCH_PARENT_APPLICATION_PENDING = 'FETCH_PARENT_APPLICATION_PENDING';
