import {
  EMAIL_VERIFIED,
  AUTH_STATE_CHANGED,
  REGISTER_PENDING,
  REGISTER_ERROR,
  LOGIN_PENDING,
  LOGIN_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from "../actions/types";

const initialState = {
  isEmailNotVerified: false,
  isRegisterPending: false,
  registerError: null,
  isLoginPending: false,
  loginError: null,
  isResetPasswordPending: false,
  resetPasswordError: null,
  resetPasswordSuccess: null,
  isAuthenticated: false,
  isInitialised: false,
  user: null,

  isChangePasswordPending: false,
  changePasswordError: null,
  changePasswordSuccess: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EMAIL_VERIFIED:
      return {
        ...state,
        isEmailNotVerified: action.payload,
      };

    case REGISTER_PENDING:
      return {
        ...state,
        isRegisterPending: action.payload,
      };

    case REGISTER_ERROR:
      return {
        ...state,
        registerError: action.payload,
      };
    case LOGIN_PENDING:
      return {
        ...state,
        isLoginPending: action.payload,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
      };
    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        isResetPasswordPending: action.payload,
      };

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      };

    case AUTH_STATE_CHANGED:
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };

    case CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        isChnagePasswordPending: action.payload,
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.payload,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccess: action.payload,
      };

    default:
      return state;
  }
}
