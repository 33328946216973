import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "redux/store";
import {
  Row,
  Col,
  Card,
  CardBody,
  FileDropzone,
  FileGrid,
  CustomSpinner,
  CustomModal,
} from "@mycentyr/centyr-ui";
import Delete from "@material-ui/icons/Delete";
import addFileImg from "assets/images/undraw_add_file2_gvbb.svg";
import { uploadImage, deleteMedias } from "../slices/packageSlice";

const PackageImages = (props) => {
  const id = props.id;

  const dispatch = useDispatch();
  const {
    packageData,
    isFetchPackagePending,
    isAddUpdatePackagePending,
  } = useSelector((state) => state.package);

  const [files, setFiles] = useState([]);
  const [filesWithUrl, setFilesWithUrl] = useState([]);
  const [modal, setModal] = useState(false);
  const [nameToDelete, setNameToDelete] = useState("");

  const toggle = () => setModal(!modal);

  const handleDrop = (newFiles, rejectedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  useEffect(async () => {
    const postData = new FormData();
    if (files.length > 0) {
      files.map((file, index) =>
        postData.append(`package_image${index}`, file)
      );

      let response = dispatch(uploadImage(id, postData));
      if (response) setFiles([]);
    }
  }, [files]);

  const handleRemove = (file) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_file) => _file.path !== file.path)
    );
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  useEffect(async () => {
    const packageImage = [];
    if (typeof packageData.package_image !== "boolean") {
      packageData.package_image?.map((imageData) => {
        packageImage.push({
          name: imageData.image_name,
          type: imageData.image_type,
          size: imageData.image_size,
          url: process.env.REACT_APP_API_URL + imageData.image_url,
        });
      });
      setFilesWithUrl(packageImage);
    }
  }, [packageData]);

  return (
    <Row>
      <Col lg="12" xs="12">
        <Card>
          <CardBody>
            {isAddUpdatePackagePending && <CustomSpinner />}
            <FileGrid
              className="mb-3"
              files={filesWithUrl}
              donwloadAction={async (file) => {
                urlToObject(file.url).then(async (imageResult) => {
                  const blob = new Blob([imageResult]);
                  const href = await URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = href;
                  link.download = file.name;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
              }} //if u wont pass this there is no download button
              moreButtons={[
                {
                  icon: Delete,
                  text: "Delete",
                  action: (name) => {
                    setNameToDelete(name);
                    toggle();
                  },
                },
              ]}
            />
            <FileDropzone
              className="mb-3"
              files={files}
              onDrop={handleDrop}
              onRemove={handleRemove}
              onRemoveAll={handleRemoveAll}
              fileImg={addFileImg}
              accept="image/*"
            />
            <CustomModal
              onModalAccept={() => {
                toggle();
                dispatch(
                  deleteMedias(id, {
                    name: nameToDelete,
                  })
                );
              }}
              onModalCancel={toggle}
              modal={modal}
              toggle={toggle}
              modalTitle="Delete"
              modalBody="Are you sure you want to delete?"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PackageImages;
