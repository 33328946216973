import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import {CustomSnackbar} from '@mycentyr/centyr-ui'

import {
    FETCH_GROUP_SUCCESS,
    FETCH_GROUP_PENDING,
    FETCH_GROUP_ERROR,
    FETCH_GROUP,
    FETCH_GROUP_COUNT,
    ADD_UPDATE_GROUP_ERROR,
    ADD_UPDATE_GROUP_PENDING,
    ADD_UPDATE_GROUP_SUCCESS,
    FETCH_ONE_GROUP,
    FETCH_ONE_GROUP_PENDING,
} from './types';

const setFetchGroupSuccess = isFetchGroupSuccess => dispatch => {
    dispatch({
        type: FETCH_GROUP_SUCCESS,
        payload: isFetchGroupSuccess
    });
}

const setFetchGroupPending = isFetchGroupPending => dispatch => {
    dispatch({
        type: FETCH_GROUP_PENDING,
        payload: isFetchGroupPending
    });
}

const setAddUpdateGroupPending = isAddUpdateGroupPending => dispatch => {
    dispatch({
        type: ADD_UPDATE_GROUP_PENDING,
        payload: isAddUpdateGroupPending
    });
}

const setFetchOneGroupPending = isGroupDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_GROUP_PENDING,
        payload: isGroupDataPending
    });
}

export const setAddUpdateGroupError = addUpdateGroupError => dispatch => {
    dispatch({
        type: ADD_UPDATE_GROUP_ERROR,
        payload: addUpdateGroupError
    });
}

export const setAddUpdateGroupSuccess = isAddUpdateGroupSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_GROUP_SUCCESS,
        payload: isAddUpdateGroupSuccess
    });
}

export const setFetchGroupError = fetchGroupError => dispatch => {
    dispatch({
        type: FETCH_GROUP_ERROR,
        payload: fetchGroupError
    });
}

export const fetchGroups = (pageNo = 1, perPage = 10, keyword = "", order = "", postType = "post", desc = false) => async dispatch => {

    dispatch(setFetchGroupPending(true));
    dispatch({
        type: FETCH_GROUP_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_GROUP,
        payload: null
    });

    instance.post(`setup/applications/groups/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchGroupSuccess(true));
            dispatch({
                type: FETCH_GROUP_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_GROUP,
                payload: response.data.data.items
            });
            dispatch(setFetchGroupPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchGroupError(err.response.data.data));
            dispatch(setFetchGroupPending(false));
        });

}

export const fetchAllGroups = () => async dispatch => {

    dispatch(setFetchGroupPending(true));
    dispatch({
        type: FETCH_GROUP_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_GROUP,
        payload: []
    });

    instance.post(`setup/applications/groups/get`, {})
        .then(response => {
            dispatch(setFetchGroupSuccess(true));
            dispatch({
                type: FETCH_GROUP_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_GROUP,
                payload: response.data.data
            });
            dispatch(setFetchGroupPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchGroupError(err.response.data.data));
            dispatch(setFetchGroupPending(false));
        });

}

export const addGroup = (postData, toggle) => async dispatch => {
    dispatch(setAddUpdateGroupSuccess(false));
    dispatch(setAddUpdateGroupPending(true));
    instance.post('setup/applications/groups/create', postData)
        .then(response => {
            dispatch(setAddUpdateGroupSuccess(true));
            toggle();
            CustomSnackbar('success', 'Group added successfully');
            dispatch(fetchGroups());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateGroupError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateGroupPending(false));
        });

}

export const updateGroup = (id, postData, toggle) => async dispatch => {

    dispatch(setAddUpdateGroupSuccess(false));
    dispatch(setAddUpdateGroupPending(true));
    dispatch(setFetchOneGroupPending(true));

    instance.post(`setup/applications/groups/update/${id}`, postData)
        .then(response => {
            dispatch(setAddUpdateGroupSuccess(true));
            CustomSnackbar('success', 'Group updated successfully');
            toggle();
            dispatch(fetchGroups());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateGroupError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateGroupPending(false));
            dispatch(setFetchOneGroupPending(false));
        });

}

export const fetchGroup = (id) => async dispatch => {

    dispatch(setFetchOneGroupPending(true));

    instance.post(`setup/applications/groups/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_GROUP,
                payload: response.data.data
            });
            dispatch(setFetchOneGroupPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneGroupPending(false));
        });
}

export const deleteGroups = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('setup/applications/groups/delete', postData)
        .then(response => {
            dispatch(fetchGroups(pageNo, perPage, keyword));
            CustomSnackbar('success', response.data.message);
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchGroupError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}
