//Import Components for Manage Routes
import CustomerList from "app/crm/customer/views/CustomerList";
import OneCustomer from "app/crm/customer/views/OneCustomer";

//Import icons for Manage Nav Section
import Person from "@material-ui/icons/Person";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const crmroutes = [
  {
    path: "/crm",
    name: "CRM",
    title: "CRM",
    id: "customer",
    icon: Person,
    permission: ["list.crm.customers", "add.crm.customers"],
    child: [
      {
        collapse: true,
        name: "Customers",
        icon: PeopleAltIcon,
        state: "Customers",
        permission: ["list.crm.customers", "add.crm.customers"],
        subchild: [
          {
            path: "/crm/customers/list",
            title: "Manage Customers",
            name: "All Customers",
            component: CustomerList,
            permission: "list.crm.customers",
          },
          {
            path: "/crm/customers/add",
            title: "Create New Customer",
            name: "Add New",
            permission: "add.crm.customers",
          },
        ],
      },
    ],
  },
  {
    path: "/crm/customers/:id",
    name: "Create New Customer",
    component: OneCustomer,
    routeonly: true,
  },
];

export default crmroutes;
