import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import {
  fetchTemplateTags,
  deleteTemplateTags,
} from "../slices/templateTagSlice";
import TemplateTagActionButtons from "./TemplateTagActionButtons";
import OneTemplateTag from "./OneTemplateTag";
import { history } from "helpers/history";
import TemplateTagSkeleeton from "./TemplateTagSkeleeton";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  Button,
} from "@mycentyr/centyr-ui";

const TemplateTagList = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { templateTags, noOfRecords, isFetchTemplateTagsPending } = useSelector(
    (state) => state.templateTag
  );

  const headCells = [
    {
      id: "template_tag_name",
      numeric: false,
      disablePadding: false,
      label: "Tag Name",
    },
    {
      id: "template_tag_slug",
      numeric: false,
      disablePadding: false,
      label: "Tag Slug",
    },
    {
      id: "application_name",
      numeric: false,
      disablePadding: false,
      label: "Application Name",
    },
    {
      id: "application_group_name",
      numeric: false,
      disablePadding: false,
      label: "Group name",
    },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  const createData = (
    id,
    template_tag_name,
    template_tag_slug,
    application_name,
    application_group_name
  ) => {
    return {
      id,
      template_tag_name,
      template_tag_slug,
      application_name,
      application_group_name,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchTemplateTags(1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchTemplateTags(pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deleteTemplateTags(postData, pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchTemplateTags(1, perPage, keyword));
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {
    let rowsLcl = [];
    templateTags.map((tag) =>
      rowsLcl.push(
        createData(
          tag.id,
          tag.template_tag_name,
          tag.template_tag_slug,
          tag.application_name,
          tag.application_group_name
        )
      )
    );
    setRows(rowsLcl);
  }, [templateTags]);

  useEffect(() => {
    dispatch(fetchTemplateTags());
  }, []);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Template Tag</ModalHeader>
        <ModalBody>
          <OneTemplateTag id="add" toggle={toggle} />
        </ModalBody>
      </Modal>
      <div className="page-header">
        <Row>
          <Col className="page-title">
            <h1>{props.title}</h1>
          </Col>
          <Col className="text-right page-header-button">
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes("add.template.tags") && (
                <Button color="primary" className="mb-3" onClick={toggle}>
                  Add Template Tag
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchTemplateTagsPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes("delete.template.tags")
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={TemplateTagSkeleeton}
          Actions={TemplateTagActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(TemplateTagList);
