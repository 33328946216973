import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { history } from "helpers/history";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  emailTemplates: [],
  noOfRecords: 0,
  emailTemplate: {},
  isFetchEmailTemplatesPending: false,
  isFetchEmailTemplatePending: false,
  isAddUpdateEmailTemplatePending: false,

  testEmailResponse: null,
  testEmailPending: false,
  testEmailSuccess: false,
};

const slice = createSlice({
  name: "emailTemplate",
  initialState,
  reducers: {
    fetchEmailTemplates(state, action) {
      state.emailTemplates = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchEmailTemplate(state, action) {
      state.emailTemplate = action.payload;
    },
    setFetchEmailTemplatesPending(state, action) {
      state.isFetchEmailTemplatesPending = action.payload;
    },
    setFetchEmailTemplatePending(state, action) {
      state.isFetchEmailTemplatePending = action.payload;
    },
    setAddUpdateEmailTemplatesPending(state, action) {
      state.isAddUpdateEmailTemplatePending = action.payload;
    },
    setTestEmailPending(state, action) {
      state.testEmailPending = action.payload;
    },
    setTestEmailSuccess(state, action) {
      state.testEmailSuccess = action.payload;
    },
    setTestEmailResponse(state, action) {
      state.testEmailResponse = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchEmailTemplates = (
  pageNo = 1,
  perPage = 10,
  keyword = "",
  order = "",
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchEmailTemplatesPending(true));
  dispatch(slice.actions.fetchEmailTemplates({ items: [], count: -1 }));

  const response = await instance
    .post(
      `emailtemplates/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? " desc" : ""
      }`,
      {}
    )
    .catch((error) => {
      CustomSnackbar("error", error.errorToDisplay);
    });
  dispatch(slice.actions.setFetchEmailTemplatesPending(false));
  if (response) {
    dispatch(slice.actions.fetchEmailTemplates(response.data.data));
  }
};

export const fetchEmailTemplate = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchEmailTemplatePending(true));

  const response = await instance
    .post(`emailtemplates/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchEmailTemplatePending(false));
  if (response) {
    dispatch(slice.actions.fetchEmailTemplate(response.data.data));
  }
};

export const addEmailTemplate = (postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateEmailTemplatesPending(true));

  const response = await instance
    .post("emailtemplates/create", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateEmailTemplatesPending(false));
  if (response) {
    history.push("/manage/email/emailtemplates/" + response.data.data.id);
    CustomSnackbar("success", "Template created successfully");
  }
};

export const updateEmailTemplate = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setTestEmailResponse(null));
  dispatch(slice.actions.setAddUpdateEmailTemplatesPending(true));
  dispatch(slice.actions.setFetchEmailTemplatePending(true));

  const response = await instance
    .post(`emailtemplates/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateEmailTemplatesPending(false));
  dispatch(slice.actions.setFetchEmailTemplatePending(false));
  if (response) {
    dispatch(slice.actions.fetchEmailTemplate(response.data.data));
    CustomSnackbar("success", "Template updated successfully");
  }
};

export const deleteEmailTemplates = (
  postData,
  pageNo,
  perPage,
  keyword = ""
) => async (dispatch) => {
  const response = await instance
    .post("emailtemplates/delete", postData)
    .catch((error) => {
      CustomSnackbar("error", error.errorToDisplay);
    });
  if (response) {
    dispatch(fetchEmailTemplates(pageNo, perPage, keyword));
  }
};

export const testEmail = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setTestEmailResponse(null));
  dispatch(slice.actions.setTestEmailSuccess(false));
  dispatch(slice.actions.setTestEmailPending(true));

  instance
    .post(`emailtemplates/testemail/${id}`, postData)
    .then((response) => {
      dispatch(slice.actions.setTestEmailResponse(response.data.data));
      dispatch(slice.actions.setTestEmailSuccess(true));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(setTestEmailError(err.response.data.data));
        CustomSnackbar("error", err.response.data.data);
      }
    })
    .finally(() => {
      dispatch(slice.actions.setTestEmailPending(false));
    });
};

export default slice;
