
//group
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_PENDING = 'FETCH_GROUP_PENDING';
export const FETCH_GROUP_ERROR = 'FETCH_GROUP_ERROR';
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_GROUP_COUNT = 'FETCH_GROUP_COUNT';
export const ADD_UPDATE_GROUP_ERROR = 'ADD_UPDATE_GROUP_ERROR';
export const FETCH_ONE_GROUP = 'FETCH_ONE_GROUP';
export const FETCH_ONE_GROUP_PENDING = 'FETCH_ONE_GROUP_PENDING';
export const ADD_UPDATE_GROUP_PENDING = 'ADD_UPDATE_GROUP_PENDING';
export const ADD_UPDATE_GROUP_SUCCESS = 'ADD_UPDATE_GROUP_SUCCESS';
export const FETCH_PARENT_GROUP = 'FETCH_PARENT_GROUP';
export const FETCH_PARENT_GROUP_PENDING = 'FETCH_PARENT_GROUP_PENDING';
