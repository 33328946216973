//emailprovider
export const FETCH_EMAILPROVIDER_SUCCESS = 'FETCH_EMAILPROVIDER_SUCCESS';
export const FETCH_EMAILPROVIDER_PENDING = 'FETCH_EMAILPROVIDER_PENDING';
export const FETCH_EMAILPROVIDER_ERROR = 'FETCH_EMAILPROVIDER_ERROR';
export const FETCH_EMAILPROVIDER = 'FETCH_EMAILPROVIDER';
export const FETCH_EMAILPROVIDER_COUNT = 'FETCH_EMAILPROVIDER_COUNT';
export const ADD_UPDATE_EMAILPROVIDER_ERROR = 'ADD_UPDATE_EMAILPROVIDER_ERROR';
export const FETCH_ONE_EMAILPROVIDER = 'FETCH_ONE_EMAILPROVIDER';
export const FETCH_ONE_EMAILPROVIDER_PENDING = 'FETCH_ONE_EMAILPROVIDER_PENDING';
export const ADD_UPDATE_EMAILPROVIDER_PENDING = 'ADD_UPDATE_EMAILPROVIDER_PENDING';
export const ADD_UPDATE_EMAILPROVIDER_SUCCESS = 'ADD_UPDATE_EMAILPROVIDER_SUCCESS';

export const TEST_EMAIL_PENDING = 'TEST_EMAIL_PENDING';
export const TEST_EMAIL_SUCCESS = 'TEST_EMAIL_SUCCESS';
export const TEST_EMAIL_ERROR = 'TEST_EMAIL_ERROR';
export const TEST_EMAIL_RESPONSE = 'TEST_EMAIL_RESPONSE';