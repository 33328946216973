import {
    FETCH_LOG_SUCCESS,
    FETCH_LOG_PENDING,
    FETCH_LOG_ERROR,
    FETCH_LOG,
    FETCH_LOG_COUNT,
} from '../actions/types';

const initialState = {
    isFetchLogSuccess: false,
    isFetchLogPending: false,
    fetchLogError: null,
    isLogDataPending: false,
    logs: null,
    noOfRecords: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        
        case FETCH_LOG:
            return {
                ...state,
                logs: action.payload
            };

        case FETCH_LOG_SUCCESS:
            return {
                ...state,
                isFetchLogSuccess: action.payload
            };

        case FETCH_LOG_PENDING:
            return {
                ...state,
                isFetchLogPending: action.payload
            };

        case FETCH_LOG_ERROR:
            return {
                ...state,
                fetchLogError: action.payload
            };

        case FETCH_LOG_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        default:
            return state;
    }
}