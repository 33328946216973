//Import Components for Manage Routes
import TicketList from "app/helpdesk/ticket/views/TicketList";
import OneTicket from "app/helpdesk/ticket/views/OneTicket";
import TicketDetail from "app/helpdesk/ticket/views/TicketDetail";

import DepartmentList from "app/helpdesk/department/views/DepartmentList";
// import OneDepartment from 'app/helpdesk/department/views/OneDepartment';
// import DepartmentDetail from 'app/helpdesk/department/views/DepartmentDetail';

//Import icons for Manage Nav Section
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const helpdeskroutes = [
  {
    path: "/helpdesk",
    name: "HelpDesk",
    title: "HelpDesk",
    id: "helpdesk",
    icon: HelpOutlineIcon,
    permission: ["list.helpdesk.tickets", "add.helpdesk.tickets"],
    child: [
      {
        collapse: true,
        name: "Tickets",
        icon: LiveHelpIcon,
        state: "Tickets",
        permission: ["list.helpdesk.tickets", "add.helpdesk.tickets"],
        subchild: [
          {
            path: "/helpdesk/tickets/list",
            title: "Manage Tickets",
            name: "All Tickets",
            component: TicketList,
            permission: "list.helpdesk.tickets",
          },
          {
            path: "/helpdesk/tickets/add",
            title: "Open New Ticket",
            name: "Open New Ticket",
            permission: "add.helpdesk.tickets",
          },
        ],
      },
      {
        collapse: true,
        name: "Departments",
        icon: PeopleAltIcon,
        state: "Departments",
        permission: ["list.helpdesk.departments", "add.helpdesk.departments"],
        subchild: [
          {
            path: "/helpdesk/departments/list",
            title: "Manage Departments",
            name: "All Departments",
            component: DepartmentList,
            permission: "list.helpdesk.departments",
          },
        ],
      },
    ],
  },
  {
    path: "/helpdesk/tickets/:id",
    name: "Create New Ticket",
    component: OneTicket,
    routeonly: true,
  },
  {
    path: "/helpdesk/tickets/:id/view",
    name: "Ticket Detail",
    component: TicketDetail,
    routeonly: true,
  },
];

export default helpdeskroutes;
