import { instance } from 'utils/axios'
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_LOG_SUCCESS,
    FETCH_LOG_PENDING,
    FETCH_LOG,
    FETCH_LOG_COUNT,
} from './types';

const setFetchLogSuccess = isFetchLogSuccess => dispatch => {
    dispatch({
        type: FETCH_LOG_SUCCESS,
        payload: isFetchLogSuccess
    });
}

const setFetchLogPending = isFetchLogPending => dispatch => {
    dispatch({
        type: FETCH_LOG_PENDING,
        payload: isFetchLogPending
    });
}

export const fetchLogs = (pageNo = 1, perPage = 10, keyword = "", order = "", desc = false) => async dispatch => {

    dispatch(setFetchLogPending(true));

    dispatch({
        type: FETCH_LOG_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_LOG,
        payload: null
    });

    instance.post(`manage/logs/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchLogSuccess(true));
            dispatch({
                type: FETCH_LOG_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_LOG,
                payload: response.data.data.items
            });
            dispatch(setFetchLogPending(false));
        })
        .catch(err => {
            if (err.response) {
                CustomSnackbar('error', err.response.data.data);
            }
            dispatch(setFetchLogPending(false));
        });

}
