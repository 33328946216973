import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import { history } from "helpers/history";
import {
  fetchEmailTemplates,
  deleteEmailTemplates,
} from "../slices/emailTemplateSlice";
import EmailTemplateActionButtons from "./EmailTemplateActionButtons";
import EmailTemplateSkeleeton from "./EmailTemplateSkeleeton";
import OneEmailTemplate from "./OneEmailTemplate";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  Button,
  Badge,
} from "@mycentyr/centyr-ui";

function CustomBadge(props) {
  const { text, ...rest } = props;
  let color = "success";

  return (
    <div>
      <Badge color={color}>{text}</Badge>
    </div>
  );
}

const EmailTemplateList = (props) => {
  const dispatch = useDispatch();
  const {
    emailTemplates,
    noOfRecords,
    isFetchEmailTemplatePending,
  } = useSelector((state) => state.emailTemplate);

  const headCells = [
    {
      id: "email_template_name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "email_template_subject",
      numeric: false,
      disablePadding: false,
      label: "Subject",
    },
    {
      id: "emailprovider_name",
      numeric: false,
      disablePadding: false,
      label: "Provider Name",
    },
    {
      id: "is_active",
      numeric: false,
      disablePadding: false,
      label: "Status",
      component: CustomBadge,
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },
  ];

  const createData = (
    id,
    email_template_name,
    email_template_subject,
    emailprovider_name,
    is_active
  ) => {
    return {
      id,
      email_template_name,
      email_template_subject,
      emailprovider_name,
      is_active,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchEmailTemplates(1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchEmailTemplates(pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deleteEmailTemplates(postData, pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchEmailTemplates(1, perPage, keyword));
  };

  var rows = [];
  emailTemplates.map((emailtemplate) =>
    rows.push(
      createData(
        emailtemplate.id,
        emailtemplate.email_template_name,
        emailtemplate.email_template_subject,
        emailtemplate.emailprovider_name,
        emailtemplate.is_active === "1" ? "Active" : "Deactive",
        { color: emailtemplate.is_active === "1" ? "success" : "danger" }
      )
    )
  );

  useEffect(() => {
    dispatch(fetchEmailTemplates());
  }, []);

  const toggle = () =>
    this.setState({ ...this.state, modal: !this.state.modal });

  return (
    <div>
      {/* <Modal isOpen={state.modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>Add Email Provider</ModalHeader>
        <ModalBody>
          <EmailTemplateForm
            id="add"
            toggle={toggle}
          />
        </ModalBody>
      </Modal> */}
      <div className="page-header">
        <Row>
          <Col className="page-title">
            <h1>{props.title}</h1>
          </Col>
          <Col className="text-right page-header-button">
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes(
                "add.email.templates"
              ) && (
                <Button
                  className="mb-3"
                  color="primary"
                  onClick={() => {
                    history.push("add");
                  }}
                >
                  Add Email Template
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchEmailTemplatePending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes("delete.email.templates")
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={EmailTemplateSkeleeton}
          Actions={EmailTemplateActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(EmailTemplateList);
