import React from 'react'

import { Switch } from '@mycentyr/centyr-ui';

const PermissionSwitch = (props) => {

    const handleChange = (event) => {
        if (event.target.checked) {
            props.otherProps.changePermission(event.target.value, 'allow')
        } else {
            props.otherProps.changePermission(event.target.value, 'deny')
        }
    }

    return (
        <React.Fragment>
            <Switch
                defaultChecked={props.otherProps.allowedPermissions.includes(props.row.id)}
                color="primary"
                name="checkedB"
                value={props.row.id}
                onChange={handleChange}
            />
        </React.Fragment>
    )
}


export default PermissionSwitch;