import React, { useEffect } from "react";
import { Prompt } from "react-router";
import { appName } from "utils/axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";
import { history } from "helpers/history";
import useEscape from "layouts/useEscape";
import {
  fetchEmailProvider,
  testEmail,
  updateEmailProvider,
} from "../actions/emailProviderActions";

import {
  Row,
  Col,
  InputLabel,
  CustomSpinner,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  Button,
  OutlinedInput,
  CardBody,
  CardTitle,
  Card,
  Alert,
} from "@mycentyr/centyr-ui";

const schema = yup.object().shape({
  emailprovider_name: yup.string().required("Name is required"),
  emailprovider_smtp_host: yup.string().required("SMTP Host is required"),
  emailprovider_smtp_user: yup.string().required("SMTP User is required"),
  emailprovider_smtp_password: yup
    .string()
    .required("SMTP Password is required"),
  emailprovider_smtp_port: yup.string().required("SMTP Port is required"),
  emailprovider_sender_email: yup.string().required("Sender Email is required"),
});
const schema2 = yup.object().shape({
  email: yup.string().required("Email is required").email("Email is invalid"),
});

const statuses = ["active", "inactive", "hidden"];

const encryptions = [
  { value: "", label: "None" },
  { value: "tls", label: "TLS" },
  { value: "ssl", label: "SSL" },
];

const OneEmailProvider = (props) => {
  const { id } = props.match.params;

  const [emailResponse, setEmailResponse] = React.useState(null);

  const statusLabel = React.useRef(null);
  const [statusLabelWidth, setStatusLabelWidth] = React.useState(0);

  const encryptionLabel = React.useRef(null);
  const [encryptionLabelWidth, setEncryptionLabelWidth] = React.useState(0);

  document.title = "Update Email Provider" + appName;
  useEscape(() => history.push("list"));
  useEffect(() => {
    props.fetchEmailProvider(id);
  }, [id]);

  useEffect(() => {
    setEmailResponse(props.testEmailResponse);
  }, [props.testEmailResponse]);

  useEffect(() => {
    setStatusLabelWidth(statusLabel.current?.offsetWidth);
    setEncryptionLabelWidth(encryptionLabel.current?.offsetWidth);
  }, [!props.isEmailProviderDataPending]);

  useEffect(() => {
    reset({
      emailprovider_name: props.emailproviderData.emailprovider_name || "",
      emailprovider_description:
        props.emailproviderData.emailprovider_description || "",
      emailprovider_smtp_host:
        props.emailproviderData.emailprovider_smtp_host || "",
      emailprovider_smtp_user:
        props.emailproviderData.emailprovider_smtp_user || "",
      emailprovider_smtp_password:
        props.emailproviderData.emailprovider_smtp_password || "",
      emailprovider_smtp_port:
        props.emailproviderData.emailprovider_smtp_port || "",
      emailprovider_smtp_encryption:
        props.emailproviderData.emailprovider_smtp_encryption || "",
      emailprovider_sender_name:
        props.emailproviderData.emailprovider_sender_name || "",
      emailprovider_sender_email:
        props.emailproviderData.emailprovider_sender_email || "",
      status: props.emailproviderData.status || "active",
      emailprovider_reply_to:
        props.emailproviderData.emailprovider_reply_to || "",
      emailprovider_bcc: props.emailproviderData.emailprovider_bcc || "",
    });
  }, [props.emailproviderData, id]);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
    formState,
  } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });
  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema2),
  });

  const onSubmit = (data) => {
    const postData = {
      emailprovider_name: data.emailprovider_name,
      emailprovider_description: data.emailprovider_description,
      emailprovider_smtp_host: data.emailprovider_smtp_host,
      emailprovider_smtp_user: data.emailprovider_smtp_user,
      emailprovider_smtp_password: data.emailprovider_smtp_password,
      emailprovider_smtp_port: data.emailprovider_smtp_port,
      emailprovider_smtp_encryption: data.emailprovider_smtp_encryption,
      emailprovider_sender_name: data.emailprovider_sender_name,
      emailprovider_sender_email: data.emailprovider_sender_email,
      status: data.status,
      emailprovider_reply_to: data.emailprovider_reply_to,
      emailprovider_bcc: data.emailprovider_bcc,
    };
    props.updateEmailProvider(id, postData);
  };

  const onSubmit2 = (data) => {
    const postData = {
      email: data.email,
    };
    props.testEmail(id, postData);
  };

  if (
    !props.isAddUpdateEmailProviderPending &&
    props.isEmailProviderDataPending
  )
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Prompt
          when={isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">Update Email Provider</h1>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <Row>
            <Col lg="8" xs="12">
              <Card>
                <CardTitle>SMTP Detail</CardTitle>
                <CardBody>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {props.isEmailProviderDataPending && <CustomSpinner />}
                    <Row>
                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email Provider Name"
                          name="emailprovider_name"
                          id="emailprovider_name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_name?.message ? true : false
                          }
                          helperText={errors.emailprovider_name?.message}
                          inputRef={register}
                        />
                      </Col>
                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email Provider Description"
                          name="emailprovider_description"
                          id="emailprovider_description"
                          variant="outlined"
                          size="small"
                          fullWidth
                          multiline
                          rows={2}
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_description?.message
                              ? true
                              : false
                          }
                          helperText={errors.emailprovider_description?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="SMTP Host"
                          name="emailprovider_smtp_host"
                          id="emailprovider_smtp_host"
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={
                            errors.emailprovider_smtp_host?.message
                              ? true
                              : false
                          }
                          helperText={errors.emailprovider_smtp_host?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="SMTP Port"
                          name="emailprovider_smtp_port"
                          id="emailprovider_smtp_port"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_smtp_port?.message
                              ? true
                              : false
                          }
                          helperText={errors.emailprovider_smtp_port?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="SMTP User"
                          name="emailprovider_smtp_user"
                          id="emailprovider_smtp_user"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_smtp_user?.message
                              ? true
                              : false
                          }
                          helperText={errors.emailprovider_smtp_user?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="SMTP Password"
                          name="emailprovider_smtp_password"
                          id="emailprovider_smtp_password"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_smtp_password?.message
                              ? true
                              : false
                          }
                          helperText={
                            errors.emailprovider_smtp_password?.message
                          }
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={
                            errors.emailprovider_smtp_encryption?.message
                              ? true
                              : false
                          }
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel
                            shrink
                            ref={encryptionLabel}
                            htmlFor="emailprovider_smtp_encryption"
                          >
                            SMTP Secure
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={encryptionLabelWidth}
                                name="emailprovider_smtp_encryption"
                                id="emailprovider_smtp_encryption"
                              />
                            }
                          >
                            {encryptions.map((encryption) => (
                              <option
                                value={encryption.value}
                                key={encryption.value}
                              >
                                {encryption.label}
                              </option>
                            ))}
                          </Select>
                          <FormHelperText>
                            {errors.emailprovider_smtp_encryption?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>

                      <Col lg="6" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          error={errors.status?.message ? true : false}
                          fullWidth
                          className="mb-3"
                        >
                          <InputLabel shrink ref={statusLabel} htmlFor="status">
                            Status
                          </InputLabel>
                          <Select
                            native
                            inputRef={register}
                            input={
                              <OutlinedInput
                                notched
                                labelWidth={statusLabelWidth}
                                name="status"
                                id="status"
                              />
                            }
                          >
                            {statuses.map((status) => (
                              <option value={status} key={status}>
                                {status}
                              </option>
                            ))}
                          </Select>
                          <FormHelperText>
                            {errors.status?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="Sender Name"
                          name="emailprovider_sender_name"
                          id="emailprovider_sender_name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_sender_name?.message
                              ? true
                              : false
                          }
                          helperText={errors.emailprovider_sender_name?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="Sender Email"
                          name="emailprovider_sender_email"
                          id="emailprovider_sender_email"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_sender_email?.message
                              ? true
                              : false
                          }
                          helperText={
                            errors.emailprovider_sender_email?.message
                          }
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email Reply To"
                          name="emailprovider_reply_to"
                          id="emailprovider_reply_to"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_reply_to?.message
                              ? true
                              : false
                          }
                          helperText={errors.emailprovider_reply_to?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email BCC"
                          name="emailprovider_bcc"
                          id="emailprovider_bcc"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.emailprovider_bcc?.message ? true : false
                          }
                          helperText={errors.emailprovider_bcc?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="12" xs="12" className="text-right">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={props.isAddUpdateEmailProviderPending}
                        >
                          {id === "add" ? "Create" : "Update"}
                        </Button>
                        <Button
                          className="ml-2"
                          type="button"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("list");
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xs="12">
              <Card>
                <CardTitle>Send Test Email</CardTitle>
                <CardBody>
                  <form onSubmit={handleSubmit2(onSubmit2)}>
                    {props.testEmailPending && <CustomSpinner />}
                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email"
                          name="email"
                          id="email"
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={errors2.email?.message ? true : false}
                          helperText={errors2.email?.message}
                          inputRef={register2}
                        />
                      </Col>

                      <Col lg="12" xs="12" className="text-right">
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Col>

                      <Col lg="12" xs="12" className="mt-3">
                        {emailResponse && (
                          <Alert
                            color={
                              emailResponse.success &&
                              emailResponse.success === true
                                ? "success"
                                : "danger"
                            }
                          >
                            {emailResponse.message}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    emailproviderData: state.emailprovider.emailproviderData,
    isEmailProviderDataPending: state.emailprovider.isEmailProviderDataPending,
    isAddUpdateEmailProviderPending:
      state.emailprovider.isAddUpdateEmailProviderPending,
    testEmailResponse: state.emailprovider.testEmailResponse,
    testEmailPending: state.emailprovider.testEmailPending,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailProvider: (id) => {
      dispatch(fetchEmailProvider(id));
    },
    updateEmailProvider: (id, postData) => {
      dispatch(updateEmailProvider(id, postData));
    },
    testEmail: (id, postData) => {
      dispatch(testEmail(id, postData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneEmailProvider);
