import React from 'react'

import {
    Card,
    CardBody,
    Row,
    Col,
} from '@mycentyr/centyr-ui';

const PackageInvoice = (props) => {

    const { id } = props.match.params;
    const url = process.env.REACT_APP_API_URL + "packages/invoice/" + id;

    return (
        <div>
            <div className="page-header">
                <Row>
                    <Col className="page-title">
                        <h1 className='page-title'>Package Invoice</h1>
                    </Col>
                    <Col className="text-right page-header-button">

                    </Col>
                </Row>
            </div>
            <div className="content-wrapper">
                <Row>
                    <Col lg="12" xs="12">
                        <Card>
                            <CardBody>
                                <iframe title="View Pdf" src={url} style={{ height: "300mm" }} width="100%" frameBorder={0} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default PackageInvoice;