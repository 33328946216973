import {
    FETCH_SHIPPING_SUCCESS,
    FETCH_SHIPPING_PENDING,
    FETCH_SHIPPING_ERROR,
    FETCH_SHIPPING,
    FETCH_SHIPPING_COUNT,
    ADD_UPDATE_SHIPPING_ERROR,
    FETCH_ONE_SHIPPING,
    FETCH_ONE_SHIPPING_PENDING,
    ADD_UPDATE_SHIPPING_PENDING,
    ADD_UPDATE_SHIPPING_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchShippingSuccess: false,
    isFetchShippingPending: false,
    fetchShippingError: null,
    addUpdateShippingError: null,
    shippingData: [],
    isShippingDataPending: false,
    shippings: [],
    noOfRecords: 0,
    isAddUpdateShippingPending: false,
    isAddUpdateShippingSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SHIPPING:
            return {
                ...state,
                shippings: action.payload
            };

        case FETCH_SHIPPING_SUCCESS:
            return {
                ...state,
                isFetchShippingSuccess: action.payload
            };

        case FETCH_SHIPPING_PENDING:
            return {
                ...state,
                isFetchShippingPending: action.payload
            };

        case FETCH_SHIPPING_ERROR:
            return {
                ...state,
                fetchShippingError: action.payload
            };

        case FETCH_SHIPPING_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_SHIPPING:
            return {
                ...state,
                shippingData: action.payload
            };

        case ADD_UPDATE_SHIPPING_ERROR:
            return {
                ...state,
                addUpdateShippingError: action.payload
            };

        case FETCH_ONE_SHIPPING_PENDING:
            return {
                ...state,
                isShippingDataPending: action.payload
            };

        case ADD_UPDATE_SHIPPING_PENDING:
            return {
                ...state,
                isAddUpdateShippingPending: action.payload
            };

        case ADD_UPDATE_SHIPPING_SUCCESS:
            return {
                ...state,
                isAddUpdateShippingSuccess: action.payload
            };

        default:
            return state;
    }
}