import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import {CustomSnackbar} from '@mycentyr/centyr-ui'

import {
    FETCH_SHIPPING_SUCCESS,
    FETCH_SHIPPING_PENDING,
    FETCH_SHIPPING_ERROR,
    FETCH_SHIPPING,
    FETCH_SHIPPING_COUNT,
    ADD_UPDATE_SHIPPING_ERROR,
    ADD_UPDATE_SHIPPING_PENDING,
    ADD_UPDATE_SHIPPING_SUCCESS,
    FETCH_ONE_SHIPPING,
    FETCH_ONE_SHIPPING_PENDING,
} from './types';

const setFetchShippingSuccess = isFetchShippingSuccess => dispatch => {
    dispatch({
        type: FETCH_SHIPPING_SUCCESS,
        payload: isFetchShippingSuccess
    });
}

const setFetchShippingPending = isFetchShippingPending => dispatch => {
    dispatch({
        type: FETCH_SHIPPING_PENDING,
        payload: isFetchShippingPending
    });
}

const setAddUpdateShippingPending = isAddUpdateShippingPending => dispatch => {
    dispatch({
        type: ADD_UPDATE_SHIPPING_PENDING,
        payload: isAddUpdateShippingPending
    });
}

const setFetchOneShippingPending = isShippingDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_SHIPPING_PENDING,
        payload: isShippingDataPending
    });
}

export const setAddUpdateShippingError = addUpdateShippingError => dispatch => {
    dispatch({
        type: ADD_UPDATE_SHIPPING_ERROR,
        payload: addUpdateShippingError
    });
}

export const setAddUpdateShippingSuccess = isAddUpdateShippingSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_SHIPPING_SUCCESS,
        payload: isAddUpdateShippingSuccess
    });
}

export const setFetchShippingError = fetchShippingError => dispatch => {
    dispatch({
        type: FETCH_SHIPPING_ERROR,
        payload: fetchShippingError
    });
}

export const fetchShippings = (pageNo = 1, perPage = 10, keyword = "", order = "", postType = "post", desc = false) => async dispatch => {

    dispatch(setFetchShippingPending(true));
    dispatch({
        type: FETCH_SHIPPING_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_SHIPPING,
        payload: null
    });

    instance.post(`shipping/carriers/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchShippingSuccess(true));
            dispatch({
                type: FETCH_SHIPPING_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_SHIPPING,
                payload: response.data.data.items
            });
            dispatch(setFetchShippingPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchShippingError(err.response.data.data));
            dispatch(setFetchShippingPending(false));
        });

}

export const fetchAllShippings = () => async dispatch => {

    dispatch(setFetchShippingPending(true));
    dispatch({
        type: FETCH_SHIPPING_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_SHIPPING,
        payload: []
    });

    instance.post(`shipping/carriers/get`, {})
        .then(response => {
            dispatch(setFetchShippingSuccess(true));
            dispatch({
                type: FETCH_SHIPPING_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_SHIPPING,
                payload: response.data.data
            });
            dispatch(setFetchShippingPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchShippingError(err.response.data.data));
            dispatch(setFetchShippingPending(false));
        });

}

export const addShipping = (postData, toggle) => async dispatch => {
    dispatch(setAddUpdateShippingSuccess(false));
    dispatch(setAddUpdateShippingPending(true));
    instance.post('shipping/carriers/create', postData)
        .then(response => {
            dispatch(setAddUpdateShippingSuccess(true));
            toggle();
            CustomSnackbar('success', 'Shipping added successfully');
            dispatch(fetchShippings());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateShippingError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateShippingPending(false));
        });

}

export const updateShipping = (id, postData, toggle) => async dispatch => {

    dispatch(setAddUpdateShippingSuccess(false));
    dispatch(setAddUpdateShippingPending(true));
    dispatch(setFetchOneShippingPending(true));

    instance.post(`shipping/carriers/update/${id}`, postData)
        .then(response => {
            dispatch(setAddUpdateShippingSuccess(true));
            CustomSnackbar('success', 'Shipping updated successfully');
            toggle();
            dispatch(fetchShippings());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateShippingError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateShippingPending(false));
            dispatch(setFetchOneShippingPending(false));
        });

}

export const fetchShipping = (id) => async dispatch => {

    dispatch(setFetchOneShippingPending(true));

    instance.post(`shipping/carriers/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_SHIPPING,
                payload: response.data.data
            });
            dispatch(setFetchOneShippingPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneShippingPending(false));
        });
}

export const deleteShippings = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('shipping/carriers/delete', postData)
        .then(response => {
            dispatch(fetchShippings(pageNo, perPage, keyword));
            CustomSnackbar('success', response.data.message);
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchShippingError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}
