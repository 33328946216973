import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'redux/store';
import { urlToObject } from 'utils/fun';
import {
  fetchPackage,
  updatePackage,
  addPackage,
  deleteMedias,
} from '../slices/packageSlice';

import { fetchAllCustomers } from 'app/crm/customer/actions/customerActions';
import { fetchAllShippings } from 'app/wsa/shipping/actions/shippingActions';

import { history } from 'helpers/history';
import useEscape from 'layouts/useEscape';
import {
  Row,
  Col,
  CustomSpinner,
  TextField,
  Button,
  Card,
  CardTitle,
  CardBody,
  Select,
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  FileDropzone,
  Box,
  CustomModal,
  FileGrid,
} from '@mycentyr/centyr-ui';
import ReactQuill from 'react-quill';
import { appName } from 'utils/axios';
import addFileImg from 'assets/images/undraw_add_file2_gvbb.svg';

import moment from 'moment';

const schema = yup.object().shape({
  customer_id: yup.string().required('Customer is required'),
  package_height: yup.string().required('Height is required'),
  package_width: yup.string().required('Width is required'),
  package_length: yup.string().required('Length is required'),
  package_weight: yup.string().required('Weight is required'),
});

const warehouses = [{ id: '1', label: 'New Jersey (USA)' }];
const types = ['package', 'document', 'consolidate'];
const conditions = ['good', 'damaged'];

const initialValue = {
  customer_id: '',
  package_warehouse: 1,
  package_type: 'package',
  package_condition: 'good',
  package_commodity_type: '',
  package_inward_shipping_carrier: '',
  package_inward_tracking_number: '',
  package_sender_name: '',
  package_height: '',
  package_width: '',
  package_length: '',
  package_weight: '',
  package_note: '',
  package_receive_time: moment().format('YYYY-MM-DDTHH:mm'),
  package_weight_unit: 'lb',
  package_length_unit: 'in',

  package_shipping_carrier: '',
  package_outward_tracking_number: '',
  package_shipping_time: '',
};

const PackageForm = (props) => {
  const { id } = props;

  if (id !== 'add') document.title = 'Manage Package' + appName;
  else document.title = 'Create New Package' + appName;

  const [files, setFiles] = useState([]);
  const [note, setNote] = useState('');

  const warehouseLabel = React.useRef(null);
  const [warehouseLabelWidth, setWarehouseLabelWidth] = React.useState(0);
  const typeLabel = React.useRef(null);
  const [typeLabelWidth, setTypeLabelWidth] = React.useState(0);
  const conditionLabel = React.useRef(null);
  const [conditionLabelWidth, setConditionLabelWidth] = React.useState(0);
  const customerLabel = React.useRef(null);
  const [customerLabelWidth, setCustomerLabelWidth] = React.useState(0);
  const shippingLabel = React.useRef(null);
  const [shippingLabelWidth, setShippingLabelWidth] = React.useState(0);

  const handleDrop = (newFiles, rejectedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_file) => _file.path !== file.path)
    );
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const dispatch = useDispatch();
  const { packageData, isFetchPackagePending, isAddUpdatePackagePending } =
    useSelector((state) => state.package);

  const { shippings } = useSelector((state) => state.shipping);
  const { customers } = useSelector((state) => state.customer);
  useEscape(() => history.push('list/all'));
  useEffect(() => {
    if (id !== 'add') {
      dispatch(fetchPackage(id));
    }
    dispatch(fetchAllCustomers());
    dispatch(fetchAllShippings());

    setWarehouseLabelWidth(warehouseLabel.current?.offsetWidth);
    setTypeLabelWidth(typeLabel.current?.offsetWidth);
    setConditionLabelWidth(conditionLabel.current?.offsetWidth);
    setCustomerLabelWidth(customerLabel.current?.offsetWidth);
    setShippingLabelWidth(shippingLabel.current?.offsetWidth);
  }, [id]);

  useEffect(async () => {
    let receiveTime =
      packageData.package_receive_time &&
      moment(packageData.package_receive_time).format('YYYY-MM-DDTHH:mm');

    let shippingTime = null;
    if (
      packageData.package_shipping_time &&
      packageData.package_shipping_time !== null
    )
      shippingTime = moment(packageData.package_shipping_time).format(
        'YYYY-MM-DDTHH:mm'
      );

    if (id !== 'add') {
      setNote(packageData.package_note || '');
    } else {
      setNote('');
    }

    reset(
      id === 'add'
        ? initialValue
        : {
            customer_id: packageData.customer_id || '',
            package_warehouse: packageData.package_warehouse || 1,
            package_type: packageData.package_type || 'package',
            package_condition: packageData.package_condition || 'good',
            package_commodity_type: packageData.package_commodity_type || '',
            package_inward_shipping_carrier:
              packageData.package_inward_shipping_carrier || '',
            package_inward_tracking_number:
              packageData.package_inward_tracking_number || '',
            package_sender_name: packageData.package_sender_name || '',
            package_height: packageData.package_height || '',
            package_width: packageData.package_width || '',
            package_length: packageData.package_length || '',
            package_weight: packageData.package_weight || '',
            package_weight_unit: packageData.package_weight_unit || 'lb',
            package_length_unit: packageData.package_length_unit || 'in',
            package_receive_time:
              receiveTime || moment().format('YYYY-MM-DDTHH:mm'),
            package_shipping_carrier:
              packageData.package_shipping_carrier || '',
            package_outward_tracking_number:
              packageData.package_outward_tracking_number || '',
            package_shipping_time: shippingTime || '',
          }
    );
  }, [customers, packageData, id]);

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const postData = new FormData();

    postData.append('customer_id', data.customer_id);
    postData.append('package_warehouse', data.package_warehouse);
    postData.append('package_type', data.package_type);
    postData.append('package_condition', data.package_condition);
    postData.append('package_commodity_type', data.package_commodity_type);
    postData.append(
      'package_inward_shipping_carrier',
      data.package_inward_shipping_carrier || ''
    );
    postData.append(
      'package_inward_tracking_number',
      data.package_inward_tracking_number || ''
    );
    postData.append('package_sender_name', data.package_sender_name);
    postData.append('package_height', data.package_height);
    postData.append('package_width', data.package_width);
    postData.append('package_length', data.package_length);
    postData.append('package_weight', data.package_weight);
    postData.append('package_weight_unit', data.package_weight_unit);
    postData.append('package_length_unit', data.package_length_unit);
    postData.append('package_receive_time', data.package_receive_time || '');
    postData.append('package_note', note);

    files.map((file, index) => postData.append(`package_image${index}`, file));

    if (id === 'add') dispatch(addPackage(postData));
    else {
      const res = await dispatch(updatePackage(id, postData));
    }
  };

  const handleKeypress = (e) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  if (!isAddUpdatePackagePending && isFetchPackagePending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        {isAddUpdatePackagePending && <CustomSpinner />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg='12'>
              {id != 'add' && packageData.consolidated_packages && (
                <Card>
                  <CardTitle>Packages to Consolidate</CardTitle>
                  <CardBody>
                    {packageData.consolidated_packages?.map((row, key) => (
                      <div className='mb-2' key={key}>
                        <b>Package Id:</b> {row.package_id}
                      </div>
                    ))}
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardTitle>Package Information</CardTitle>
                <CardBody>
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <FormControl
                        variant='outlined'
                        size='small'
                        error={errors.package_warehouse?.message ? true : false}
                        fullWidth
                        className='mb-3'
                      >
                        <InputLabel
                          ref={warehouseLabel}
                          htmlFor='package_warehouse'
                        >
                          Select Warehouse
                        </InputLabel>
                        <Select
                          native
                          inputRef={register}
                          input={
                            <OutlinedInput
                              notched
                              labelWidth={warehouseLabelWidth}
                              name='package_warehouse'
                              id='package_warehouse'
                            />
                          }
                        >
                          {warehouses.map((warehouse) => (
                            <option value={warehouse.id} key={warehouse.id}>
                              {warehouse.label}
                            </option>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.package_warehouse?.message}
                        </FormHelperText>
                      </FormControl>
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <FormControl
                        variant='outlined'
                        size='small'
                        error={errors.customer_id?.message ? true : false}
                        fullWidth
                        className='mb-3'
                      >
                        <InputLabel
                          shrink
                          ref={customerLabel}
                          htmlFor='customer_id'
                        >
                          Customer
                        </InputLabel>
                        <Select
                          native
                          inputRef={register}
                          input={
                            <OutlinedInput
                              notched
                              labelWidth={customerLabelWidth}
                              name='customer_id'
                              id='customer_id'
                            />
                          }
                        >
                          <option value=''>Select Customer</option>
                          {customers &&
                            customers.map((customer) => (
                              <option value={customer.id} key={customer.id}>
                                {customer.user_display_name} (
                                {customer.user_username})
                              </option>
                            ))}
                        </Select>
                        <FormHelperText>
                          {errors.customer_id?.message}
                        </FormHelperText>
                      </FormControl>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <FormControl
                        variant='outlined'
                        size='small'
                        error={errors.package_type?.message ? true : false}
                        fullWidth
                        className='mb-3'
                      >
                        <InputLabel ref={typeLabel} htmlFor='package_type'>
                          Package Type
                        </InputLabel>
                        <Select
                          native
                          inputRef={register}
                          input={
                            <OutlinedInput
                              notched
                              labelWidth={typeLabelWidth}
                              name='package_type'
                              id='package_type'
                            />
                          }
                        >
                          {types.map((type) => (
                            <option value={type} key={type}>
                              {type}
                            </option>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.package_type?.message}
                        </FormHelperText>
                      </FormControl>
                    </Col>

                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Commodity Type'
                        className='mb-3'
                        name='package_commodity_type'
                        id='package_commodity_type'
                        variant='outlined'
                        size='small'
                        fullWidth
                        InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                        error={
                          errors.package_commodity_type?.message ? true : false
                        }
                        helperText={errors.package_commodity_type?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <FormControl
                        variant='outlined'
                        size='small'
                        error={errors.package_condition?.message ? true : false}
                        fullWidth
                        className='mb-3'
                      >
                        <InputLabel
                          ref={conditionLabel}
                          htmlFor='package_condition'
                        >
                          Package Condition
                        </InputLabel>
                        <Select
                          native
                          inputRef={register}
                          input={
                            <OutlinedInput
                              notched
                              labelWidth={conditionLabelWidth}
                              name='package_condition'
                              id='package_condition'
                            />
                          }
                        >
                          {conditions.map((condition) => (
                            <option value={condition} key={condition}>
                              {condition}
                            </option>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.package_condition?.message}
                        </FormHelperText>
                      </FormControl>
                    </Col>
                  </Row>

                  {id !== 'add' &&
                  packageData.package_type === 'consolidate' ? (
                    ''
                  ) : (
                    <React.Fragment>
                      <Box fontSize='h6.fontSize' m={1}>
                        Inward Shipping Information
                      </Box>
                      <Divider className='mb-3' />
                      <Row>
                        <Col lg='6' md='6' xs='12'>
                          <TextField
                            label='Name of Sender'
                            className='mb-3'
                            name='package_sender_name'
                            id='package_sender_name'
                            variant='outlined'
                            size='small'
                            fullWidth
                            InputLabelProps={
                              id !== 'add' ? { shrink: true } : {}
                            }
                            error={
                              errors.package_sender_name?.message ? true : false
                            }
                            helperText={errors.package_sender_name?.message}
                            inputRef={register}
                          />
                        </Col>
                        <Col lg='6' md='6' xs='12'>
                          <FormControl
                            variant='outlined'
                            size='small'
                            error={
                              errors.package_inward_shipping_carrier?.message
                                ? true
                                : false
                            }
                            fullWidth
                            className='mb-3'
                          >
                            <InputLabel
                              shrink
                              ref={shippingLabel}
                              htmlFor='package_inward_shipping_carrier'
                            >
                              Shipping Carrier
                            </InputLabel>
                            <Select
                              native
                              inputRef={register}
                              input={
                                <OutlinedInput
                                  notched
                                  labelWidth={shippingLabelWidth}
                                  name='package_inward_shipping_carrier'
                                  id='package_inward_shipping_carrier'
                                />
                              }
                            >
                              <option value=''>Select Shipping Carrier</option>
                              {shippings &&
                                shippings.map((shipping) => (
                                  <option value={shipping.id} key={shipping.id}>
                                    {shipping.shipping_carrier_name}
                                  </option>
                                ))}
                            </Select>
                            <FormHelperText>
                              {errors.package_inward_shipping_carrier?.message}
                            </FormHelperText>
                          </FormControl>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='6' md='6' xs='12'>
                          <TextField
                            label='Inward Tracking Number'
                            className='mb-3'
                            name='package_inward_tracking_number'
                            id='package_inward_tracking_number'
                            variant='outlined'
                            size='small'
                            fullWidth
                            InputLabelProps={
                              id !== 'add' ? { shrink: true } : {}
                            }
                            error={
                              errors.package_inward_tracking_number?.message
                                ? true
                                : false
                            }
                            helperText={
                              errors.package_inward_tracking_number?.message
                            }
                            inputRef={register}
                          />
                        </Col>
                        <Col lg='6' md='6' xs='12'>
                          <TextField
                            label='Received Time'
                            className='mb-3'
                            name='package_receive_time'
                            id='package_receive_time'
                            variant='outlined'
                            size='small'
                            fullWidth
                            type='datetime-local'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              errors.package_receive_time?.message
                                ? true
                                : false
                            }
                            helperText={errors.package_receive_time?.message}
                            inputRef={register}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                  <Box fontSize='h6.fontSize' m={1}>
                    Package Metrics
                  </Box>
                  <Divider className='mb-3' />
                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Weight'
                        className='mb-3'
                        name='package_weight'
                        id='package_weight'
                        variant='outlined'
                        size='small'
                        fullWidth
                        onKeyPress={handleKeypress}
                        InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                        error={errors.package_weight?.message ? true : false}
                        helperText={errors.package_weight?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <RadioGroup
                        defaultValue='lb'
                        aria-label='package_weight_unit'
                        name='package_weight_unit'
                      >
                        <Row>
                          <Col lg='4' md='4' xs='4'>
                            <FormControlLabel
                              value='lb'
                              control={<Radio />}
                              label='LB'
                              inputRef={register}
                            />
                          </Col>
                          <Col lg='4' md='4' xs='4'>
                            <FormControlLabel
                              value='oz'
                              control={<Radio />}
                              label='OZ'
                              inputRef={register}
                            />
                          </Col>
                          <Col lg='4' md='4' xs='4'>
                            <FormControlLabel
                              value='kg'
                              control={<Radio />}
                              label='KG'
                              inputRef={register}
                            />
                          </Col>
                        </Row>
                      </RadioGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Length'
                        className='mb-3'
                        name='package_length'
                        id='package_length'
                        variant='outlined'
                        size='small'
                        fullWidth
                        onKeyPress={handleKeypress}
                        InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                        error={errors.package_length?.message ? true : false}
                        helperText={errors.package_length?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Width'
                        className='mb-3'
                        name='package_width'
                        id='package_width'
                        variant='outlined'
                        size='small'
                        fullWidth
                        onKeyPress={handleKeypress}
                        InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                        error={errors.package_width?.message ? true : false}
                        helperText={errors.package_width?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <TextField
                        label='Height'
                        className='mb-3'
                        name='package_height'
                        id='package_height'
                        variant='outlined'
                        size='small'
                        fullWidth
                        onKeyPress={handleKeypress}
                        InputLabelProps={id !== 'add' ? { shrink: true } : {}}
                        error={errors.package_height?.message ? true : false}
                        helperText={errors.package_height?.message}
                        inputRef={register}
                      />
                    </Col>
                    <Col lg='6' md='6' xs='12'>
                      <RadioGroup
                        defaultValue='in'
                        aria-label='package_length_unit'
                        name='package_length_unit'
                      >
                        <Row>
                          <Col lg='6' md='6' xs='6'>
                            <FormControlLabel
                              value='in'
                              control={<Radio />}
                              label='IN'
                              inputRef={register}
                            />
                          </Col>
                          <Col lg='6' md='6' xs='6'>
                            <FormControlLabel
                              value='cm'
                              control={<Radio />}
                              label='CM'
                              inputRef={register}
                            />
                          </Col>
                        </Row>
                      </RadioGroup>
                    </Col>
                  </Row>

                  <Box fontSize='h6.fontSize' m={1}>
                    Private Note
                  </Box>
                  <Divider className='mb-3' />

                  <Row>
                    <Col lg='12' xs='12'>
                      <ReactQuill
                        className='mb-3'
                        value={note}
                        placeholder='Write a Private note'
                        theme='snow'
                        onChange={(value) => {
                          setNote(value);
                        }}
                      />
                    </Col>
                  </Row>
                  {id == 'add' && (
                    <Row>
                      <Col lg={12}>
                        <FileDropzone
                          className='mb-3'
                          files={files}
                          onDrop={handleDrop}
                          onRemove={handleRemove}
                          onRemoveAll={handleRemoveAll}
                          fileImg={addFileImg}
                          accept='image/*'
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg={12} className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={isAddUpdatePackagePending}
                      >
                        save
                      </Button>
                      <Button
                        className='ml-2'
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.push('list/all');
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
};

export default PackageForm;
