import {
    FETCH_SETTING_SUCCESS,
    FETCH_SETTING_PENDING,
    FETCH_SETTING_ERROR,
    FETCH_SETTING,
    FETCH_SETTING_COUNT,
    SAVE_SETTING_ERROR,
    SAVE_SETTING_PENDING,
    SAVE_SETTING_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchSettingSuccess: false,
    isFetchSettingPending: false,
    fetchSettingError: null,
    saveSettingError: null,
    settings: null,
    noOfRecords: 0,
    isSaveSettingPending: false,
    isSaveSettingSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
    
        case FETCH_SETTING:
            return {
                ...state,
                settings: action.payload
            };

        case FETCH_SETTING_SUCCESS:
            return {
                ...state,
                isFetchSettingSuccess: action.payload
            };

        case FETCH_SETTING_PENDING:
            return {
                ...state,
                isFetchSettingPending: action.payload
            };

        case FETCH_SETTING_ERROR:
            return {
                ...state,
                fetchSettingError: action.payload
            };

        case FETCH_SETTING_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case SAVE_SETTING_ERROR:
            return {
                ...state,
                saveSettingError: action.payload
            };

        case SAVE_SETTING_PENDING:
            return {
                ...state,
                isSaveSettingPending: action.payload
            };

        case SAVE_SETTING_SUCCESS:
            return {
                ...state,
                isSaveSettingSuccess: action.payload
            };

        default:
            return state;
    }
}