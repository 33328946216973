import React, { useEffect, useLayoutEffect } from "react";
import { Prompt } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";

import { history } from "helpers/history";
import {
  addUser,
  fetchUser,
  setAddUpdateUserError,
  updateUser,
  setAddUpdateUserSuccess,
  deactivateUser,
  activateUser,
} from "../actions/userActions";
import { fetchAllRoles } from "app/manage/role/actions/roleActions";

import { loginAs } from "app/auth/actions/authActions";

import {
  Row,
  Col,
  InputLabel,
  CustomSpinner,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  Button,
  Card,
  CardBody,
  Avatar,
  Typography,
  Box,
  CustomModal,
  OutlinedInput,
} from "@mycentyr/centyr-ui";

import useEscape from "layouts/useEscape";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const schema = yup.object().shape({
  user_firstname: yup.string().required("First Name is required"),
  user_lastname: yup.string().required("Last Name is required"),
  user_display_name: yup.string().required("Display Name is required"),
  user_username: yup.string().required("Username is required"),
  user_email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required"),
  user_phone_number: yup
    .string()
    .matches(phoneRegExp, "Phone number is invalid")
    .required("Phone number is required"),
  user_password: yup
    .string()
    .oneOf(
      [yup.ref("confirm_password"), null],
      "Password and Confirm Password does not match"
    )
    .min(6, "Password must be Atleast 6 Charecters"),
  role: yup.mixed().required("Role is required"),
});

const initialValue = {
  user_firstname: "",
  user_lastname: "",
  user_display_name: "",
  user_username: "",
  user_email: "",
  user_phone_number: "",
  user_password: "",
  role: [],
};

const UserForm = (props) => {
  const id = props.id;
  const roleLabel = React.useRef(null);
  const [roleLabelWidth, setRoleLabelWidth] = React.useState(0);

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  useEscape(() => history.push("list"));

  const onDeactivate = () => {
    props.deactivateUser(id);
    toggle();
  };
  const onActivate = () => {
    props.activateUser(id);
    toggle1();
  };

  useEffect(() => {
    props.fetchAllRoles();

    if (id !== "add") {
      props.fetchUser(id);
    }
  }, []);

  useLayoutEffect(() => {
    if (!props.isUserDataPending) {
      setRoleLabelWidth(roleLabel.current?.offsetWidth);
    }
  }, [props.isUserDataPending]);

  useEffect(() => {
    reset(
      id === "add"
        ? initialValue
        : {
            user_firstname: props.userData.user_firstname || "",
            user_lastname: props.userData.user_lastname || "",
            user_display_name: props.userData.user_display_name || "",
            user_username: props.userData.user_username || "",
            user_email: props.userData.user_email || "",
            user_phone_number: props.userData.user_phone_number || "",
            user_password: "",
            role: props.userData.role_id || "",
          }
    );
  }, [props.userData, props.roles, id]);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });
  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const onSubmit = (data) => {
    const postData = {
      user_firstname: data.user_firstname,
      user_lastname: data.user_lastname,
      user_display_name: data.user_display_name,
      user_username: data.user_username,
      user_email: data.user_email,
      user_phone_number: data.user_phone_number,
      user_password: data.user_password,
      role: data.role,
    };
    id === "add"
      ? props.addUser(postData)
      : props.updateUser(id, postData, id === props.authUserData.id);
  };

  if (!props.isAddUpdateUserPending && props.isUserDataPending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Prompt
          when={isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Row>
          {id !== "add" && (
            <Col lg={4}>
              <Card>
                <CardBody>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <Avatar
                      style={{ height: 100, width: 100 }}
                      src={props.userData.user_profile}
                    />
                    <Typography gutterBottom variant="h5">
                      {props.userData.user_display_name}
                    </Typography>
                    <Typography variant="body1">
                      Role: {props.userData.role}
                    </Typography>
                    <Button
                      color="primary"
                      outline
                      onClick={(e) => {
                        e.stopPropagation();
                        props.loginAs({ uid: props.userData.user_uid });
                      }}
                    >
                      Login as User
                    </Button>
                  </Box>
                </CardBody>
              </Card>
            </Col>
          )}
          <Col lg={id !== "add" ? 8 : 12}>
            <Card>
              <CardBody>
                {props.isAddUpdateUserPending && <CustomSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg="6" xs="12">
                      <TextField
                        label="First Name *"
                        className="mb-3"
                        name="user_firstname"
                        id="user_firstname"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={id !== "add" ? { shrink: true } : {}}
                        error={errors.user_firstname?.message ? true : false}
                        helperText={errors.user_firstname?.message}
                        inputRef={register}
                      />
                    </Col>

                    <Col lg="6" xs="12">
                      <TextField
                        label="Last Name *"
                        className="mb-3"
                        name="user_lastname"
                        id="user_lastname"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={id !== "add" ? { shrink: true } : {}}
                        error={errors.user_lastname?.message ? true : false}
                        helperText={errors.user_lastname?.message}
                        inputRef={register}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" xs="12">
                      <TextField
                        label="Display Name *"
                        className="mb-3"
                        name="user_display_name"
                        id="user_display_name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={id !== "add" ? { shrink: true } : {}}
                        error={errors.user_display_name?.message ? true : false}
                        helperText={errors.user_display_name?.message}
                        inputRef={register}
                      />
                    </Col>

                    <Col lg="6" xs="12">
                      <TextField
                        label="Userame *"
                        className="mb-3"
                        name="user_username"
                        id="user_username"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={id !== "add" ? { shrink: true } : {}}
                        error={errors.user_username?.message ? true : false}
                        helperText={errors.user_username?.message}
                        inputRef={register}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" xs="12">
                      <TextField
                        label="Email Address *"
                        className="mb-3"
                        name="user_email"
                        id="user_email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={id !== "add" ? { shrink: true } : {}}
                        error={errors.user_email?.message ? true : false}
                        helperText={errors.user_email?.message}
                        inputRef={register}
                      />
                    </Col>

                    <Col lg="6" xs="12">
                      <TextField
                        label="Phone Number *"
                        className="mb-3"
                        name="user_phone_number"
                        id="user_phone_number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={id !== "add" ? { shrink: true } : {}}
                        error={errors.user_phone_number?.message ? true : false}
                        helperText={errors.user_phone_number?.message}
                        inputRef={register}
                      />
                    </Col>
                  </Row>
                  {id === "add" && (
                    <Row>
                      <Col lg="6" xs="12">
                        <TextField
                          label="Password"
                          inputRef={register}
                          className="mb-3"
                          name="user_password"
                          type="password"
                          id="user_password"
                          variant="outlined"
                          size="small"
                          fullWidth
                          autoComplete="off"
                          error={errors.user_password?.message ? true : false}
                          helperText={errors.user_password?.message}
                        />
                      </Col>

                      <Col lg="6" xs="12">
                        <TextField
                          label="Confirm Password"
                          inputRef={register}
                          className="mb-3"
                          name="confirm_password"
                          type="password"
                          id="confirm_password"
                          variant="outlined"
                          size="small"
                          fullWidth
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg="6" xs="12">
                      <FormControl
                        variant="outlined"
                        size="small"
                        error={errors.role?.message ? true : false}
                        fullWidth
                        className="mb-3"
                      >
                        <InputLabel ref={roleLabel} shrink htmlFor="role">
                          Role
                        </InputLabel>
                        <Select
                          native
                          multiple
                          inputRef={register}
                          input={
                            <OutlinedInput
                              notched
                              labelWidth={roleLabelWidth}
                              name="role"
                              id="role"
                            />
                          }
                        >
                          {props.roles &&
                            props.roles.map((role) => (
                              <option value={role.id} key={role.id}>
                                {role.role_name}
                              </option>
                            ))}
                        </Select>
                        <FormHelperText>{errors.role?.message}</FormHelperText>
                      </FormControl>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6" xs="12">
                      {id !== "add" && (
                        <React.Fragment>
                          {props.userData.is_active == 0 ? (
                            <Button
                              type="button"
                              color="success"
                              onClick={toggle1}
                            >
                              Activate
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              color="danger"
                              onClick={toggle}
                            >
                              Deactivate
                            </Button>
                          )}
                        </React.Fragment>
                      )}
                    </Col>
                    <Col lg="6" xs="12" className="text-right">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={props.isAddUpdateUserPending}
                      >
                        {id === "add" ? "Create" : "Update"}
                      </Button>
                      <Button
                        className="ml-2"
                        type="button"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push("list");
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CustomModal
          onModalAccept={onDeactivate}
          onModalCancel={toggle}
          modal={modal}
          toggle={toggle}
          modalTitle="Deactivate"
          modalBody="Are you sure you want to Deactivate?"
        />
        <CustomModal
          onModalAccept={onActivate}
          onModalCancel={toggle1}
          modal={modal1}
          toggle={toggle1}
          modalTitle="Activate"
          modalBody="Are you sure you want to Activate?"
        />
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
    userData: state.user.userData,
    isAddUpdateUserPending: state.user.isAddUpdateUserPending,
    addUpdateUserError: state.user.addUpdateUserError,
    isUserDataPending: state.user.isUserDataPending,
    isAddUpdateUserSuccess: state.user.isAddUpdateUserSuccess,
    isFetchRolePending: state.user.isFetchRolePending,
    roles: state.role.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (postData) => {
      dispatch(addUser(postData));
    },
    fetchUser: (id) => {
      dispatch(fetchUser(id));
    },
    setAddUpdateUserError: (error) => {
      dispatch(setAddUpdateUserError(error));
    },
    updateUser: (id, postData, currentUser) => {
      dispatch(updateUser(id, postData, currentUser));
    },
    setAddUpdateUserSuccess: (isAddUpdateUserSuccess) => {
      dispatch(setAddUpdateUserSuccess(isAddUpdateUserSuccess));
    },
    fetchAllRoles: () => {
      dispatch(fetchAllRoles());
    },
    deactivateUser: (id) => {
      dispatch(deactivateUser(id));
    },
    activateUser: (id) => {
      dispatch(activateUser(id));
    },
    loginAs: (postData) => {
      dispatch(loginAs(postData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
