import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { history } from "helpers/history";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  addresses: [],
  noOfRecords: 0,
  addressData: {},
  isFetchAddressesPending: false,
  isFetchAddressPending: false,
  isAddUpdateAddressPending: false,
};

const slice = createSlice({
  name: "addressData",
  initialState,
  reducers: {
    fetchAddresses(state, action) {
      state.addresses = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchAddress(state, action) {
      state.addressData = action.payload;
    },
    setFetchAddressesPending(state, action) {
      state.isFetchAddressesPending = action.payload;
    },
    setFetchAddressPending(state, action) {
      state.isFetchAddressPending = action.payload;
    },
    setAddUpdateAddressesPending(state, action) {
      state.isAddUpdateAddressPending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchAddresses = (
  customer_id = 0,
  pageNo = 1,
  perPage = 10,
  keyword = "",
  order = "",
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchAddressesPending(true));
  dispatch(slice.actions.fetchAddresses({ items: [], count: -1 }));

  const response = await instance
    .post(
      `customers/address/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? " desc" : ""
      }`,
      { customer_id: customer_id }
    )
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchAddressesPending(false));
  if (response) {
    dispatch(slice.actions.fetchAddresses(response.data.data));
  }
};

export const fetchAllAddress = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchAddressesPending(true));
  dispatch(slice.actions.fetchAddresses({ items: [], count: -1 }));

  const response = await instance
    .post(`customers/address/get`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchAddressesPending(false));
  if (response) {
    dispatch(
      slice.actions.fetchAddresses({
        items: response.data.data,
        count: response.data.data.length,
      })
    );
  }
};

export const fetchAddress = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchAddressPending(true));

  const response = await instance
    .post(`customers/address/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchAddressPending(false));
  if (response) {
    dispatch(slice.actions.fetchAddress(response.data.data));
  }
};

export const addAddress = (postData,toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateAddressesPending(true));

  const response = await instance
    .post("customers/address/create", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateAddressesPending(false));
  if (response) {
    CustomSnackbar("success", "Address added successfully");
    toggle();
    dispatch(fetchAddresses())
  }
};

export const updateAddress = (id, postData,toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateAddressesPending(true));

  const response = await instance
    .post(`customers/address/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateAddressesPending(false));
  if (response) {
    CustomSnackbar("success", "Address updated successfully");
    toggle();
    dispatch(fetchAddresses())
  }
};

export const deleteAddresses = (
  customer_id = 0,
  postData,
  pageNo,
  perPage,
  keyword = ""
) => async (dispatch) => {
  const response = await instance
    .post("customers/address/delete", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  if (response) {
    CustomSnackbar("success", "Address deleted Successfully");
    dispatch(fetchAddresses(customer_id, pageNo, perPage, keyword));
  }
};

export default slice;
