import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Collapse } from '@mycentyr/centyr-ui'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { history } from 'helpers/history'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      expanded: true,
      permissions: []
    }
  }

  activeRoute(prop) {
    return prop.name === this.state.selected ? 'active' : ''
  }

  setSelectedAndOpenState() {
    let selected = ''
    let openState = null

    if (this.state.selected === '') {
      this.props.routes.map((route) => {
        if (!route.redirect && !route.routeonly) {
          if (
            this.props.location.pathname.indexOf(
              route.path.replace('/site', '' + '')
            ) > -1
          )
            selected = route.name
          route.child.map((child) => {
            if (child.subchild) {
              child.subchild.map((subchild) => {
                if (
                  this.props.location.pathname.indexOf(
                    subchild.path.replace(':sitecode', '')
                  ) > -1
                )
                  openState = child.state
                return true
              })
            }
            return true
          })
        }
        return true
      })
      this.setState({
        ...this.state,
        selected:
          selected ||
          (this.props.routes.length > 0 && this.props.routes[0].name) ||
          'Not Found',
        [openState]: true
      })
    }
  }

  componentDidMount() {
    this.setSelectedAndOpenState()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ ...this.state, selected: '' })
    }
    this.setSelectedAndOpenState()
    if (
      document.getElementById('sidebarbg').className.indexOf('shrinked') !== -1
    ) {
      document.getElementById('page-wrapper').classList.add('shrinked')
      if (document.getElementsByClassName('page-header').length > 0) {
        document
          .getElementsByClassName('page-header')[0]
          .classList.add('shrinked')
      }
    } else {
      document.getElementById('page-wrapper').classList.remove('shrinked')
      if (document.getElementsByClassName('page-header').length > 0) {
        document
          .getElementsByClassName('page-header')[0]
          .classList.remove('shrinked')
      }
    }
  }

  render() {
    return (
      <div>
        <aside
          className={
            this.state.expanded ? 'left-sidebar' : 'left-sidebar shrinked'
          }
          id='sidebarbg'
          style={{ marginTop: '44px' }}
        >
          <div className='scroll-sidebar sidebar'>
            <div
              className={
                this.state.expanded
                  ? 'scrollbar-container small'
                  : 'scrollbar-container small shrinked'
              }
            >
              <PerfectScrollbar className='sidebar-nav'>
                <Nav id='sidebarnav'>
                  {this.props.routes.map((prop, key) => {
                    if (prop.redirect || prop.routeonly) return null
                    else {
                      if (this.props.authUserData.length !== 0) {
                        if (
                          checkElementsinArray(
                            prop.permission,
                            this.props.authUserData.permissions
                          )
                        )
                          return (
                            <li
                              key={key}
                              className='sidebar-item item-container item-icon'
                              id={prop.id}
                            >
                              <div
                                className={
                                  this.activeRoute(prop) + ' sidebar-link'
                                }
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    selected: prop.name
                                  })
                                }}
                              >
                                <prop.icon className='ic-lg' />
                              </div>
                            </li>
                          )
                      }
                    }
                  })}
                </Nav>
              </PerfectScrollbar>
            </div>

            <div
              className={
                this.state.expanded
                  ? 'scrollbar-container large'
                  : 'scrollbar-container large shrinked'
              }
            >
              <PerfectScrollbar className='sidebar-nav'>
                <Nav
                  id='sidebarnav'
                  style={{
                    paddingLeft: 9,
                    paddingRight: 9,
                    paddingTop: 5,
                    paddingBottom: 5
                  }}
                >
                  <h3 className='sidebar-submenu-title'>
                    {this.state.selected !== '' &&
                      this.props.routes.find(
                        (route) => route.name === this.state.selected
                      ).title}
                  </h3>
                  {this.props.routes.map((prop) => {
                    if (prop.child) {
                      if (this.props.authUserData)
                        if (this.state.selected === prop.name)
                          return prop.child.map((child, key) => {
                            if (child.navlabel) {
                              return (
                                <li
                                  className='sidebar-item item-container item-nav-label'
                                  key={key}
                                >
                                  <div className='nav-label-container'>
                                    <span className='nav-label'>
                                      {child.navlabel}
                                    </span>
                                  </div>
                                </li>
                              )
                            } else if (child.collapse) {
                              const firstdd = {}
                              firstdd[child['state']] = !this.state[child.state]

                              if (this.props.authUserData.permissions)
                                if (
                                  checkElementsinArray(
                                    child.permission,
                                    this.props.authUserData.permissions
                                  )
                                )
                                  return (
                                    <li className='sidebar-item' key={key}>
                                      <div
                                        className='sidebar-sublink'
                                        onClick={() => {
                                          this.setState(firstdd)
                                        }}
                                      >
                                        <child.icon className='sidebar-sublink-text icon ic-md' />
                                        <span className='sidebar-sublink-text arrow-container'>
                                          {child.name}
                                        </span>
                                        <span
                                          aria-expanded={
                                            this.state[child.state]
                                          }
                                          data-toggle='collapse'
                                          className='has-arrow'
                                        />
                                      </div>

                                      <Collapse
                                        isOpen={this.state[child.state]}
                                      >
                                        <ul className='first-level'>
                                          {child.subchild.map(
                                            (subchild, key) => {
                                              if (
                                                this.props.authUserData
                                                  .permissions &&
                                                this.props.authUserData.permissions.includes(
                                                  subchild.permission
                                                )
                                              )
                                                return (
                                                  <li
                                                    className='sidebar-item'
                                                    key={key}
                                                  >
                                                    <NavLink
                                                      to={subchild.path}
                                                      activeClassName='active'
                                                      className='sidebar-sublink'
                                                    >
                                                      <span>
                                                        {subchild.name}
                                                      </span>
                                                    </NavLink>
                                                  </li>
                                                )
                                            }
                                          )}
                                        </ul>
                                      </Collapse>
                                    </li>
                                  )
                            } else
                              return (
                                <li className='sidebar-item' key={key}>
                                  <NavLink
                                    to={child.path}
                                    activeClassName='active'
                                    className='sidebar-sublink'
                                  >
                                    <child.icon className='sidebar-sublink-text icon ic-md' />
                                    <span className='sidebar-sublink-text'>
                                      {child.name}
                                    </span>
                                  </NavLink>
                                </li>
                              )
                          })
                        else return null
                    }
                    return true
                  })}
                </Nav>
              </PerfectScrollbar>
            </div>
          </div>
        </aside>
        <div className='top-of-sidebar left-sidebar'>
          <div
            className='scrollbar-logo'
            style={{ padding: '4px 4px 4px 10px', background: '#f0f2f5' }}
          >
            <img
              onClick={() => {
                history.push('/dashboard')
              }}
              src={this.props.logo}
              alt='homepage'
              className='dark-logo'
              width='35px'
              height='35px'
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className='scrollbar-siteselector'>
            <h3
              className='app-title'
              style={{ margin: '9px 0px 9px 20px', color: '#f0f2f5' }}
            >
              {this.props.appName}
            </h3>
          </div>
        </div>
      </div>
    )
  }
}
export default Sidebar

function checkElementsinArray(fixedArray, inputArray) {
  var fixedArraylen = fixedArray.length

  for (var i = 0; i < fixedArraylen; i++) {
    return fixedArray.some((r) => inputArray.indexOf(r) >= 0)
  }

  return true
}
