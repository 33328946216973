import {
    FETCH_ROLE_SUCCESS,
    FETCH_ROLE_PENDING,
    FETCH_ROLE_ERROR,
    FETCH_ROLE,
    FETCH_ROLE_COUNT,
    ADD_UPDATE_ROLE_ERROR,
    FETCH_ONE_ROLE,
    FETCH_ONE_ROLE_PENDING,
    ADD_UPDATE_ROLE_PENDING,
    ADD_UPDATE_ROLE_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchRoleSuccess: false,
    isFetchRolePending: false,
    fetchRoleError: null,
    addUpdateRoleError: null,
    roleData: [],
    isRoleDataPending: false,
    roles: null,
    noOfRecords: 0,
    isAddUpdateRolePending: false,
    isAddUpdateRoleSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ROLE:
            return {
                ...state,
                roles: action.payload
            };

        case FETCH_ROLE_SUCCESS:
            return {
                ...state,
                isFetchRoleSuccess: action.payload
            };

        case FETCH_ROLE_PENDING:
            return {
                ...state,
                isFetchRolePending: action.payload
            };

        case FETCH_ROLE_ERROR:
            return {
                ...state,
                fetchRoleError: action.payload
            };

        case FETCH_ROLE_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_ROLE:
            return {
                ...state,
                roleData: action.payload
            };

        case ADD_UPDATE_ROLE_ERROR:
            return {
                ...state,
                addUpdateRoleError: action.payload
            };

        case FETCH_ONE_ROLE_PENDING:
            return {
                ...state,
                isRoleDataPending: action.payload
            };

        case ADD_UPDATE_ROLE_PENDING:
            return {
                ...state,
                isAddUpdateRolePending: action.payload
            };

        case ADD_UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                isAddUpdateRoleSuccess: action.payload
            };

        default:
            return state;
    }
}