import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { deleteShippings } from "../actions/shippingActions";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  CustomModal,
  Popover,
  PopoverBody,
  IconButton,
} from "@mycentyr/centyr-ui";
import OneShipping from "./OneShipping";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const ShippingActionButtons = (props) => {
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
    deleteShippings,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      deleteShippings({ id: row.id }, page, rowsPerPage, keyword);
    } else deleteShippings({ id: row.id }, page + 1, rowsPerPage, keyword);
    setSelected([]);
  };

  return (
    <div>
      <IconButton
        aria-label="key"
        className="no-border-onfocus"
        onClick={togglePopover}
        id={`Popover-${row.id}`}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        placement="left"
        trigger="legacy"
        isOpen={popover}
        target={`Popover-${row.id}`}
        toggle={togglePopover}
      >
        <PopoverBody>
          <a
            role="button"
            className="anchoreless-button"
            onClick={(e) => {
              e.stopPropagation();
              toggle1();
            }}
          >
            <EditIcon /> Edit
          </a>
          <a
            role="button"
            className="anchoreless-button"
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          >
            <DeleteIcon /> Delete
          </a>
        </PopoverBody>
      </Popover>
      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle="Delete"
        modalBody="Are you sure you want to delete?"
      />
      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Update Shipping</ModalHeader>
        <ModalBody>
          <OneShipping id={row.id} toggle={toggle1} />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteShippings: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteShippings(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingActionButtons);
