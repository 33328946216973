import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';

import { addEmailProvider } from '../actions/emailProviderActions';

import {
    Row,
    Col,
    InputLabel,
    CustomSpinner,
    TextField,
    Select,
    FormControl,
    FormHelperText,
    Button,
    OutlinedInput
} from '@mycentyr/centyr-ui';

const schema = yup.object().shape({
    emailprovider_name: yup.string().required('Name is required'),
    emailprovider_smtp_host: yup.string().required('SMTP Host is required'),
    emailprovider_smtp_user: yup.string().required('SMTP User is required'),
    emailprovider_smtp_password: yup.string().required('SMTP Password is required'),
    emailprovider_smtp_port: yup.string().required('SMTP Port is required'),
    emailprovider_sender_email: yup.string().required('Sender Email is required'),
});

const statuses = [
    "active",
    "inactive",
    "hidden"
]

const encryptions = [
    { "value": "", "label": "None" },
    { "value": "tls", "label": "TLS" },
    { "value": "ssl", "label": "SSL" },
]

const initialValue = {
    emailprovider_name: "",
    emailprovider_description: "",
    emailprovider_smtp_host: "",
    emailprovider_smtp_user: "",
    emailprovider_smtp_password: "",
    emailprovider_smtp_port: "",
    emailprovider_smtp_encryption: "",
    emailprovider_sender_name: "",
    emailprovider_sender_email: "",
    status: "active",
    emailprovider_reply_to: "",
    emailprovider_bcc: "",
}

const EmailProviderForm = (props) => {
    const id = props.id;

    const statusLabel = React.useRef(null);
    const [statusLabelWidth, setStatusLabelWidth] = React.useState(0);

    const encryptionLabel = React.useRef(null);
    const [encryptionLabelWidth, setEncryptionLabelWidth] = React.useState(0);

    const { register, handleSubmit, errors, setValue, reset } = useForm({
        mode: "onblur",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setStatusLabelWidth(statusLabel.current?.offsetWidth);
        setEncryptionLabelWidth(encryptionLabel.current?.offsetWidth);
        reset(initialValue);
    }, []);

    const onSubmit = data => {
        const postData = {
            emailprovider_name: data.emailprovider_name,
            emailprovider_description: data.emailprovider_description,
            emailprovider_smtp_host: data.emailprovider_smtp_host,
            emailprovider_smtp_user: data.emailprovider_smtp_user,
            emailprovider_smtp_password: data.emailprovider_smtp_password,
            emailprovider_smtp_port: data.emailprovider_smtp_port,
            emailprovider_smtp_encryption: data.emailprovider_smtp_encryption,
            emailprovider_sender_name: data.emailprovider_sender_name,
            emailprovider_sender_email: data.emailprovider_sender_email,
            status: data.status,
            emailprovider_reply_to: data.emailprovider_reply_to,
            emailprovider_bcc: data.emailprovider_bcc,
        };
        props.addEmailProvider(postData, props.toggle);
    };

    if (!props.isAddUpdateEmailProviderPending && props.isEmailProviderDataPending)
        return <CustomSpinner />
    else
        return (
            <React.Fragment>
                {props.isEmailProviderDataPending && <CustomSpinner />}

                <form onSubmit={handleSubmit(onSubmit)}>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Email Provider Name"
                                name="emailprovider_name"
                                id="emailprovider_name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={errors.emailprovider_name?.message ? true : false}
                                helperText={errors.emailprovider_name?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Email Provider Description"
                                name="emailprovider_description"
                                id="emailprovider_description"
                                variant="outlined"
                                size="small"
                                fullWidth
                                multiline
                                rows={2}
                                error={errors.emailprovider_description?.message ? true : false}
                                helperText={errors.emailprovider_description?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="SMTP Host"
                                name="emailprovider_smtp_host"
                                id="emailprovider_smtp_host"
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={errors.emailprovider_smtp_host?.message ? true : false}
                                helperText={errors.emailprovider_smtp_host?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="SMTP User"
                                name="emailprovider_smtp_user"
                                id="emailprovider_smtp_user"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_smtp_user?.message ? true : false}
                                helperText={errors.emailprovider_smtp_user?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="SMTP Password"
                                name="emailprovider_smtp_password"
                                id="emailprovider_smtp_password"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_smtp_password?.message ? true : false}
                                helperText={errors.emailprovider_smtp_password?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="SMTP Port"
                                name="emailprovider_smtp_port"
                                id="emailprovider_smtp_port"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_smtp_port?.message ? true : false}
                                helperText={errors.emailprovider_smtp_port?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <FormControl
                                variant="outlined"
                                size="small"
                                error={errors.emailprovider_smtp_encryption?.message ? true : false}
                                fullWidth
                                className="mb-3"
                            >
                                <InputLabel
                                    shrink
                                    ref={encryptionLabel}
                                    htmlFor="emailprovider_smtp_encryption"
                                >
                                    SMTP Secure
                                </InputLabel>
                                <Select
                                    native
                                    inputRef={register}
                                    input={
                                        <OutlinedInput
                                            notched
                                            labelWidth={encryptionLabelWidth}
                                            name="emailprovider_smtp_encryption"
                                            id="emailprovider_smtp_encryption"
                                        />
                                    }
                                >
                                    {encryptions.map((encryption) =>
                                        <option value={encryption.value} key={encryption.value}>{encryption.label}</option>
                                    )
                                    }
                                </Select>
                                <FormHelperText>{errors.emailprovider_smtp_encryption?.message}</FormHelperText>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Sender Name"
                                name="emailprovider_sender_name"
                                id="emailprovider_sender_name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_sender_name?.message ? true : false}
                                helperText={errors.emailprovider_sender_name?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Sender Email"
                                name="emailprovider_sender_email"
                                id="emailprovider_sender_email"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_sender_email?.message ? true : false}
                                helperText={errors.emailprovider_sender_email?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <FormControl
                                variant="outlined"
                                size="small"
                                error={errors.status?.message ? true : false}
                                fullWidth
                                className="mb-3"
                            >
                                <InputLabel
                                    shrink
                                    ref={statusLabel}
                                    htmlFor="status"
                                >
                                    Status
                                </InputLabel>
                                <Select
                                    native
                                    inputRef={register}
                                    input={
                                        <OutlinedInput
                                            notched
                                            labelWidth={statusLabelWidth}
                                            name="status"
                                            id="status"
                                        />
                                    }
                                >
                                    {statuses.map((status) =>
                                        <option value={status} key={status}>{status}</option>
                                    )
                                    }
                                </Select>
                                <FormHelperText>{errors.status?.message}</FormHelperText>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Email Reply To"
                                name="emailprovider_reply_to"
                                id="emailprovider_reply_to"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_reply_to?.message ? true : false}
                                helperText={errors.emailprovider_reply_to?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Email BCC"
                                name="emailprovider_bcc"
                                id="emailprovider_bcc"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.emailprovider_bcc?.message ? true : false}
                                helperText={errors.emailprovider_bcc?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12" className="text-right">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={props.isAddUpdateEmailProviderPending}
                            >
                                Create
                            </Button>
                            <Button
                                className="ml-2"
                                type="button"
                                color="secondary"
                                onClick={props.toggle}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </form>
            </React.Fragment>
        )
}

const mapStateToProps = (state) => {
    return {
        isAddUpdateEmailProviderPending: state.emailprovider.isAddUpdateEmailProviderPending,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addEmailProvider: (postData, toggle) => { dispatch(addEmailProvider(postData, toggle)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailProviderForm);