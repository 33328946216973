import React from "react";
import { connect } from "react-redux";
import {
  fetchGroups,
  setFetchGroupError,
  deleteGroups,
} from "../actions/groupActions";
import GroupActionButtons from "./GroupActionButtons";
import OneGroup from "./OneGroup";
import GroupSkeleeton from "./GroupSkeleeton";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  Button,
} from "@mycentyr/centyr-ui";

class ApplicationgroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  componentDidMount() {
    this.props.fetchGroups();
  }

  render() {
    const headCells = [
      { id: "id", numeric: false, disablePadding: false, label: "#" },
      {
        id: "application_group_name",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "application_group_description",
        numeric: false,
        disablePadding: false,
        label: "Description",
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (
      id,
      application_group_name,
      application_group_description
    ) => {
      return { id, application_group_name, application_group_description };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchGroups(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchGroups(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteGroups(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchGroups(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.groups) {
      this.props.groups.map((group) =>
        rows.push(
          createData(
            group.id,
            group.application_group_name,
            group.application_group_description
          )
        )
      );
    }

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div className="list-container">
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Group</ModalHeader>
          <ModalBody>
            <OneGroup id="add" toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes(
                  "add.application.groups"
                ) && (
                  <Button color="primary" className="mb-3" onClick={toggle}>
                    Add Group
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchGroupPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes(
                "delete.application.groups"
              )
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={GroupSkeleeton}
            Actions={GroupActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchGroupsuccess: state.applicationgroup.isFetchGroupsuccess,
    isFetchGroupPending: state.applicationgroup.isFetchGroupPending,
    fetchGroupError: state.applicationgroup.fetchGroupError,
    groups: state.applicationgroup.groups,
    noOfRecords: state.applicationgroup.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroups: (pageNo, perPage, keyword) => {
      dispatch(fetchGroups(pageNo, perPage, keyword));
    },
    setFetchGroupError: (postData) => {
      dispatch(setFetchGroupError(postData));
    },
    deleteGroups: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteGroups(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationgroupList);
