import React from 'react';
import indexRoutes from 'routes';
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "redux/store";
import { history } from 'helpers/history';
import authRoutes from 'app/auth/authroutes';
import PrivateRoute from 'routes/privateroutes';
import GuestRoute from 'routes/guestroutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }


    render() {

        return (
            <Provider store={configureStore()}>
                <ToastContainer
                    position="bottom-right"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    autoClose={2500}
                />
                <Router basename="/" history={history}>
                    <Switch>
                        {authRoutes.map((prop, key) => {
                            return <GuestRoute path={prop.path} key={key} component={prop.component} />;
                        })
                        }
                        {indexRoutes.map((prop, key) => {
                            return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
                        })
                        }
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App;