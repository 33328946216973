import React, { useEffect } from 'react'
import { Prompt } from 'react-router'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import { addUser, fetchUser, setAddUpdateUserError, updateUser, setAddUpdateUserSuccess } from '../actions/userActions';

import {
    Row,
    Col,
    CardTitle,
    CustomSpinner,
    TextField,
    Button,
    Card,
    CardBody,
    Avatar,
    Box,
    Typography
} from '@mycentyr/centyr-ui';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const schema = yup.object().shape({
    user_firstname: yup.string().required('First Name is required'),
    user_lastname: yup.string().required('Last Name is required'),
    user_display_name: yup.string().required('Display Name is required'),
    user_email: yup.string().email('Email is invalid').required('Email is required'),
    user_phone_number: yup.string().matches(phoneRegExp, 'Phone number is invalid').required('Phone number is required'),
});

const initialValue = {
    user_firstname: "",
    user_lastname: "",
    user_display_name: "",
    user_username: "",
    user_email: "",
    user_phone_number: "",
    password: '',
}

const UserDetail = (props) => {
    const id = props.id;

    useEffect(() => {
        reset(id === "add" ?
            initialValue : {
                user_firstname: props.authUserData.user_firstname || '',
                user_lastname: props.authUserData.user_lastname || '',
                user_display_name: props.authUserData.user_display_name || '',
                user_username: props.authUserData.user_username || '',
                user_email: props.authUserData.user_email || '',
                user_phone_number: props.authUserData.user_phone_number || '',
            })
    }, [props.authUserData, id]);

    const { register, handleSubmit, errors, reset, formState } = useForm({
        mode: "onblur",
        resolver: yupResolver(schema),
    });
    const { dirtyFields } = formState;
    const isDirtyAlt = !!Object.keys(dirtyFields).length;

    const onSubmit = data => {
        const postData = {
            user_firstname: data.user_firstname,
            user_lastname: data.user_lastname,
            user_display_name: data.user_display_name,
            user_username: data.user_username,
            user_email: data.user_email,
            user_phone_number: data.user_phone_number,
            user_password: '',
            role: props.authUserData.role_id
        };
        id === "add" ? props.addUser(postData) : props.updateUser(props.authUserData.id, postData, id === props.authUserData.id);
    };

    return (
        <React.Fragment>
            <Prompt
                when={isDirtyAlt}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <div>
                {props.isUserDataPending && <CustomSpinner />}
            </div>
            <div>
                <Row>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    flexDirection="column"
                                    textAlign="center"
                                >
                                    <Avatar
                                        style={{ height: 100, width: 100 }}
                                        src={props.authUserData.user_profile}
                                    />
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                    >
                                        {props.authUserData.user_display_name}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                    >
                                        Your Role: {props.authUserData.role}
                                    </Typography>
                                </Box>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={8}>
                        <Card>
                            <CardTitle>
                                Profile
                            </CardTitle>
                            <CardBody className="mt-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="First Name *"
                                                className="mb-3"
                                                name="user_firstname"
                                                id="user_firstname"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                                error={errors.user_firstname?.message ? true : false}
                                                helperText={errors.user_firstname?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Last Name *"
                                                className="mb-3"
                                                name="user_lastname"
                                                id="user_lastname"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                                error={errors.user_lastname?.message ? true : false}
                                                helperText={errors.user_lastname?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Display Name *"
                                                className="mb-3"
                                                name="user_display_name"
                                                id="user_display_name"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                                error={errors.user_display_name?.message ? true : false}
                                                helperText={errors.user_display_name?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Username *"
                                                className="mb-3"
                                                name="user_username"
                                                id="user_username"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                    disabled: (id !== "add"),
                                                }}
                                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                                error={errors.user_username?.message ? true : false}
                                                helperText={errors.user_username?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Email Address *"
                                                className="mb-3"
                                                name="user_email"
                                                id="user_email"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                                error={errors.user_email?.message ? true : false}
                                                helperText={errors.user_email?.message}
                                                inputRef={register}
                                            />
                                        </Col>

                                        <Col lg="6" xs="12">
                                            <TextField
                                                label="Phone Number *"
                                                className="mb-3"
                                                name="user_phone_number"
                                                id="user_phone_number"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                                error={errors.user_phone_number?.message ? true : false}
                                                helperText={errors.user_phone_number?.message}
                                                inputRef={register}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12" xs="12" className="text-right">
                                            <Button type="submit" color="primary" disabled={props.isAddUpdateUserPending}>Update</Button>
                                            <Button className="ml-2" type="button" color="secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    history.push("list");
                                                }}
                                            >Cancel</Button>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        authUserData: state.authUser.userData,
        userData: state.user.userData,
        isAddUpdateUserPending: state.user.isAddUpdateUserPending,
        addUpdateUserError: state.user.addUpdateUserError,
        isUserDataPending: state.user.isUserDataPending,
        isAddUpdateUserSuccess: state.user.isAddUpdateUserSuccess,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: (postData) => { dispatch(addUser(postData)) },
        fetchUser: (id) => { dispatch(fetchUser(id)) },
        setAddUpdateUserError: (error) => { dispatch(setAddUpdateUserError(error)) },
        updateUser: (id, postData, currentUser) => { dispatch(updateUser(id, postData, currentUser)) },
        setAddUpdateUserSuccess: (isAddUpdateUserSuccess) => { dispatch(setAddUpdateUserSuccess(isAddUpdateUserSuccess)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);