import { createSlice } from "@reduxjs/toolkit";
import { instance } from "utils/axios";
import { history } from "helpers/history";
import { CustomSnackbar } from "@mycentyr/centyr-ui";

const initialState = {
  templateTags: [],
  noOfRecords: 0,
  templateData: {},
  isFetchTemplateTagsPending: false,
  isFetchTemplateTagPending: false,
  isAddUpdateTemplateTagPending: false,
};

const slice = createSlice({
  name: "templateTagData",
  initialState,
  reducers: {
    fetchTemplateTags(state, action) {
      state.templateTags = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchTemplateTag(state, action) {
      state.templateData = action.payload;
    },
    setFetchTemplateTagsPending(state, action) {
      state.isFetchTemplateTagsPending = action.payload;
    },
    setFetchTemplateTagPending(state, action) {
      state.isFetchTemplateTagPending = action.payload;
    },
    setAddUpdateTemplateTagsPending(state, action) {
      state.isAddUpdateTemplateTagPending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchTemplateTags = (
  pageNo = 1,
  perPage = 10,
  keyword = "",
  order = "",
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchTemplateTagsPending(true));
  dispatch(slice.actions.fetchTemplateTags({ items: [], count: -1 }));

  const response = await instance
    .post(
      `templatetags/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? " desc" : ""
      }`,
      {}
    )
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchTemplateTagsPending(false));
  if (response) {
    dispatch(slice.actions.fetchTemplateTags(response.data.data));
  }
};

export const fetchTemplateTag = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchTemplateTagPending(true));

  const response = await instance
    .post(`templatetags/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchTemplateTagPending(false));
  if (response) {
    dispatch(slice.actions.fetchTemplateTag(response.data.data));
  }
};

export const addTemplateTag = (postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateTemplateTagsPending(true));

  const response = await instance
    .post("templatetags/create", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateTemplateTagsPending(false));
  if (response) {
    toggle();
    CustomSnackbar("success", "Template Tag added successfully");
    dispatch(fetchTemplateTags());
  }
};

export const updateTemplateTag = (id, postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateTemplateTagsPending(true));

  const response = await instance
    .post(`templatetags/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateTemplateTagsPending(false));
  if (response) {
    toggle();
    CustomSnackbar("success", "Template Tag updated successfully");
    dispatch(fetchTemplateTags());
  }
};

export const deleteTemplateTags = (
  postData,
  pageNo,
  perPage,
  keyword = ""
) => async (dispatch) => {
  const response = await instance
    .post("templatetags/delete", postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar("error", err.response.data.data);
      }
    });
  if (response) {
    CustomSnackbar("success", "Template Tag deleted Successfully");
    dispatch(fetchTemplateTags(pageNo, perPage, keyword));
  }
};

export default slice;
