import {
    FETCH_CUSTOMER_SUCCESS,
    FETCH_CUSTOMER_PENDING,
    FETCH_CUSTOMER_ERROR,
    FETCH_CUSTOMER,
    FETCH_CUSTOMER_COUNT,
    ADD_UPDATE_CUSTOMER_ERROR,
    FETCH_ONE_CUSTOMER,
    FETCH_ONE_CUSTOMER_PENDING,
    ADD_UPDATE_CUSTOMER_PENDING,
    ADD_UPDATE_CUSTOMER_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchCustomerSuccess: false,
    isFetchCustomerPending: false,
    fetchCustomerError: null,
    addUpdateCustomerError: null,
    customerData: [],
    isCustomerDataPending: false,
    customers: null,
    noOfRecords: 0,
    isAddUpdateCustomerPending: false,
    isAddUpdateCustomerSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_CUSTOMER:
            return {
                ...state,
                customers: action.payload
            };

        case FETCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                isFetchCustomerSuccess: action.payload
            };

        case FETCH_CUSTOMER_PENDING:
            return {
                ...state,
                isFetchCustomerPending: action.payload
            };

        case FETCH_CUSTOMER_ERROR:
            return {
                ...state,
                fetchCustomerError: action.payload
            };

        case FETCH_CUSTOMER_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_CUSTOMER:
            return {
                ...state,
                customerData: action.payload
            };

        case ADD_UPDATE_CUSTOMER_ERROR:
            return {
                ...state,
                addUpdateCustomerError: action.payload
            };

        case FETCH_ONE_CUSTOMER_PENDING:
            return {
                ...state,
                isCustomerDataPending: action.payload
            };

        case ADD_UPDATE_CUSTOMER_PENDING:
            return {
                ...state,
                isAddUpdateCustomerPending: action.payload
            };

        case ADD_UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                isAddUpdateCustomerSuccess: action.payload
            };

        default:
            return state;
    }
}