import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "redux/store";
import { fetchDepartments, deleteDepartments } from "../slices/departmentSlice";
import DepartmentActionButtons from "./DepartmentActionButtons";
import { history } from "helpers/history";
import OneDepartment from "./OneDepartment";
import DepartmentSkeleeton from "./DepartmentSkeleeton";

import {
  Row,
  Col,
  MuiTable,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "@mycentyr/centyr-ui";

const DepartmentList = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { departments, noOfRecords, isFetchDepartmentsPending } = useSelector(
    (state) => state.department
  );

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Department Name",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];

  const createData = (id, name, status) => {
    return { id, name, status };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchDepartments(1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchDepartments(pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deleteDepartments(postData, pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchDepartments(1, perPage, keyword));
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {
    let rowsLcl = [];
    departments.map((department) =>
      rowsLcl.push(
        createData(
          department.id,
          department.ticket_department_name,
          department.is_active ? "Active" : "Deactive"
        )
      )
    );
    setRows(rowsLcl);
  }, [departments]);

  useEffect(() => {
    dispatch(fetchDepartments());
  }, []);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Group</ModalHeader>
        <ModalBody>
          <OneDepartment id="add" toggle={toggle} />
        </ModalBody>
      </Modal>
      <div className="page-header">
        <Row>
          <Col className="page-title">
            <h1>{props.title}</h1>
          </Col>
          <Col className="text-right page-header-button">
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes(
                "add.helpdesk.departments"
              ) && (
                <Button color="primary" className="mb-3" onClick={toggle}>
                  Add Department
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className="content-wrapper">
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchDepartmentsPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes(
              "delete.helpdesk.departments"
            )
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={DepartmentSkeleeton}
          Actions={DepartmentActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(DepartmentList);
