import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';
import { addApplication, fetchApplications, fetchApplication, setAddUpdateApplicationError, updateApplication, setAddUpdateApplicationSuccess } from '../actions/applicationActions';
import {
    Row,
    Col,
    CustomSpinner,
    TextField,
    Button,
} from '@mycentyr/centyr-ui';

const schema = yup.object().shape({
    application_name: yup.string().required('Name is required'),
});

const initialValue = {
    application_name: "",
    application_description: "",
}

const OneApplication = (props) => {
    const id = props.id;

    useEffect(() => {

        if (id !== "add") {
            props.fetchApplication(id);
        }
    }, [id]);

    useEffect(() => {
        reset(id === "add" ?
            initialValue : {
                application_name: props.applicationData.application_name || '',
                application_description: props.applicationData.application_description || '',
            })
    }, [props.applicationData, id]);

    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onblur",
        resolver: yupResolver(schema),
    });

    const onSubmit = data => {
        const postData = {
            application_name: data.application_name,
            application_description: data.application_description,
        };
        id === "add" ? props.addApplication(postData, props.toggle) : props.updateApplication(id, postData, props.toggle)
    };
    if (!props.isAddUpdateApplicationPending && props.isApplicationDataPending)
        return <CustomSpinner />
    else
        return (
            <React.Fragment>
                {props.isApplicationDataPending && <CustomSpinner />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Application Name *"
                                name="application_name"
                                id="application_name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.application_name?.message ? true : false}
                                helperText={errors.application_name?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                label="Application Description"
                                className="mb-3"
                                name="application_description"
                                id="application_description"
                                variant="outlined"
                                size="small"
                                multiline
                                rows="2"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.application_description?.message ? true : false}
                                helperText={errors.application_description?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12" className="text-right">
                            <Button type="submit" color="primary" disabled={props.isAddUpdateApplicationPending}>{id === "add" ? "Create" : "Update"}</Button>
                            <Button className="ml-2" type="button" color="secondary"
                            onClick={props.toggle}
                            >Cancel</Button>
                        </Col>
                    </Row>
                </form>
            </React.Fragment>
        )
}

const mapStateToProps = (state) => {
    return {
        applicationData: state.application.applicationData,
        isFetchApplicationPending: state.application.isFetchApplicationPending,
        applications: state.application.applications,
        isAddUpdateApplicationPending: state.application.isAddUpdateApplicationPending,
        addUpdateApplicationError: state.application.addUpdateApplicationError,
        isApplicationDataPending: state.application.isApplicationDataPending,
        isAddUpdateApplicationSuccess: state.application.isAddUpdateApplicationSuccess,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addApplication: (postData, toggle) => { dispatch(addApplication(postData, toggle)) },
        fetchApplications: (pageNo, perPage, keyword) => { dispatch(fetchApplications(pageNo, perPage, keyword)) },
        fetchApplication: (id) => { dispatch(fetchApplication(id)) },
        setAddUpdateApplicationError: (error) => { dispatch(setAddUpdateApplicationError(error)) },
        updateApplication: (id, postData, toggle) => { dispatch(updateApplication(id, postData, toggle)) },
        setAddUpdateApplicationSuccess: (isAddUpdateApplicationSuccess) => { dispatch(setAddUpdateApplicationSuccess(isAddUpdateApplicationSuccess)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneApplication);