import React from 'react'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';

const PermissionAllowDenyAction = (props) => {

    return (
        <React.Fragment>
            <a role='button' className='anchoreless-button text-success' style={(props.selected < 1) ? { pointerEvents: 'none' } : {}} onClick={(e) => {
                e.stopPropagation();
                props.otherProps.changePermission(props.selected, 'allow')
                props.setSelected([])
            }}>
                <CheckCircleOutlineIcon />{' '}Grant
            </a>
            <a role='button' className='anchoreless-button text-danger' style={(props.selected < 1) ? { pointerEvents: 'none' } : {}} onClick={(e) => {
                e.stopPropagation();
                props.otherProps.changePermission(props.selected, 'deny')
                props.setSelected([])
            }}>
                <BlockIcon />{' '}Deny
            </a>
        </React.Fragment>
    )
}


export default PermissionAllowDenyAction;