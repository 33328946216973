//customer
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_PENDING = 'FETCH_CUSTOMER_PENDING';
export const FETCH_CUSTOMER_ERROR = 'FETCH_CUSTOMER_ERROR';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_COUNT = 'FETCH_CUSTOMER_COUNT';
export const ADD_UPDATE_CUSTOMER_ERROR = 'ADD_UPDATE_CUSTOMER_ERROR';
export const FETCH_ONE_CUSTOMER = 'FETCH_ONE_CUSTOMER';
export const FETCH_ONE_CUSTOMER_PENDING = 'FETCH_ONE_CUSTOMER_PENDING';
export const ADD_UPDATE_CUSTOMER_PENDING = 'ADD_UPDATE_CUSTOMER_PENDING';
export const ADD_UPDATE_CUSTOMER_SUCCESS = 'ADD_UPDATE_CUSTOMER_SUCCESS';
