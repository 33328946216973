import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";
import { changePassword } from "../../../auth/actions/authActions";

import {
  Row,
  Col,
  CardTitle,
  CustomSpinner,
  TextField,
  Button,
  Card,
  CardBody,
} from "@mycentyr/centyr-ui";

const schema = yup.object().shape({
  current_password: yup.string().required("Current Password is required"),
  new_password: yup
    .string()
    .required("New Password is required")
    .oneOf(
      [yup.ref("confirm_password"), null],
      "Confirm Password does not match"
    )
    .min(6, "Password must be Atleast 6 Charecters"),
});

const ChangePasswordForm = (props) => {
  const id = props.id;

  const { register, handleSubmit, errors } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
      current_password: data.current_password,
      new_password: data.new_password,
    };
    props.changePassword(postData, props.authUserData.id);
  };

  return (
    <React.Fragment>
      <div>{props.isUserDataPending && <CustomSpinner />}</div>
      <div>
        <Row>
          <Col lg="4">
            <Card>
              <CardTitle>Change Password</CardTitle>
              <CardBody className="mt-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col lg="12" xs="12">
                      <TextField
                        label="Current Password"
                        className="mb-3"
                        name="current_password"
                        id="current_password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="password"
                        autoComplete="off"
                        error={errors.current_password?.message ? true : false}
                        helperText={errors.current_password?.message}
                        inputRef={register}
                      />
                    </Col>

                    <Col lg="12" xs="12">
                      <TextField
                        label="New Password"
                        className="mb-3"
                        name="new_password"
                        id="new_password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="password"
                        autoComplete="off"
                        error={errors.new_password?.message ? true : false}
                        helperText={errors.new_password?.message}
                        inputRef={register}
                      />
                    </Col>

                    <Col lg="12" xs="12">
                      <TextField
                        label="Confirm Password"
                        className="mb-3"
                        name="confirm_password"
                        id="confirm_password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="password"
                        autoComplete="off"
                        error={errors.confirm_password?.message ? true : false}
                        helperText={errors.confirm_password?.message}
                        inputRef={register}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" xs="12">
                      <Button
                        className="pull-right"
                        type="submit"
                        color="primary"
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
    isResetPasswordPending: state.auth.isResetPasswordPending,
    resetPasswordError: state.auth.resetPasswordError,
    resetPasswordSuccess: state.auth.resetPasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (postData, id) => {
      dispatch(changePassword(postData, id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
