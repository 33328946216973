import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_ROLE_SUCCESS,
    FETCH_ROLE_PENDING,
    FETCH_ROLE_ERROR,
    FETCH_ROLE,
    FETCH_ROLE_COUNT,
    ADD_UPDATE_ROLE_ERROR,
    ADD_UPDATE_ROLE_PENDING,
    ADD_UPDATE_ROLE_SUCCESS,
    FETCH_ONE_ROLE,
    FETCH_ONE_ROLE_PENDING,
} from './types';
import { fetchPermissions } from 'app/setup/permission/actions/permissionActions';

const setFetchRoleSuccess = isFetchRoleSuccess => dispatch => {
    dispatch({
        type: FETCH_ROLE_SUCCESS,
        payload: isFetchRoleSuccess
    });
}

const setFetchRolePending = isFetchRolePending => dispatch => {
    dispatch({
        type: FETCH_ROLE_PENDING,
        payload: isFetchRolePending
    });
}

const setAddUpdateRolePending = isAddUpdateRolePending => dispatch => {
    dispatch({
        type: ADD_UPDATE_ROLE_PENDING,
        payload: isAddUpdateRolePending
    });
}

const setFetchOneRolePending = isRoleDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_ROLE_PENDING,
        payload: isRoleDataPending
    });
}

export const setAddUpdateRoleError = addUpdateRoleError => dispatch => {
    dispatch({
        type: ADD_UPDATE_ROLE_ERROR,
        payload: addUpdateRoleError
    });
}

export const setAddUpdateRoleSuccess = isAddUpdateRoleSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_ROLE_SUCCESS,
        payload: isAddUpdateRoleSuccess
    });
}

export const setFetchRoleError = fetchRoleError => dispatch => {
    dispatch({
        type: FETCH_ROLE_ERROR,
        payload: fetchRoleError
    });
}

export const fetchAllRoles = () => async dispatch => {

    dispatch(setFetchRolePending(true));
    dispatch({
        type: FETCH_ROLE_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_ROLE,
        payload: null
    });

    instance.post(`roles/get`, {})
        .then(response => {
            dispatch(setFetchRoleSuccess(true));
            dispatch({
                type: FETCH_ROLE_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_ROLE,
                payload: response.data.data
            });
            dispatch(setFetchRolePending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchRoleError(err.response.data.data));
            dispatch(setFetchRolePending(false));
        });

}

export const fetchRoles = (pageNo = 1, perPage = 10, keyword = "", order = "", desc = false) => async dispatch => {

    dispatch(setFetchRolePending(true));

    dispatch({
        type: FETCH_ROLE_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_ROLE,
        payload: null
    });

    instance.post(`roles/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchRoleSuccess(true));
            dispatch({
                type: FETCH_ROLE_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_ROLE,
                payload: response.data.data.items
            });
            dispatch(setFetchRolePending(false));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchRoleError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
            dispatch(setFetchRolePending(false));
        });

}

export const addRole = (postData) => async dispatch => {
    dispatch(setAddUpdateRoleSuccess(false));
    dispatch(setAddUpdateRolePending(true));
    instance.post('roles/create', postData)
        .then(response => {
            CustomSnackbar('success', 'Role added successfully');
            dispatch(setAddUpdateRoleSuccess(true));
            history.replace(response.data.data.id)
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateRoleError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateRolePending(false));
        });

}

export const updateRole = (id, postData) => async dispatch => {

    dispatch(setAddUpdateRoleSuccess(false));
    dispatch(setAddUpdateRolePending(true));
    dispatch(setFetchOneRolePending(true));

    instance.post(`roles/update/${id}`, postData)
        .then(response => {
            dispatch({
                type: FETCH_ONE_ROLE,
                payload: response.data.data
            });
            CustomSnackbar('success', 'Role updated successfully');
            dispatch(setAddUpdateRoleSuccess(true));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateRoleError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateRolePending(false));
            dispatch(setFetchOneRolePending(false));
        });

}

export const fetchRole = (id) => async dispatch => {

    dispatch(setFetchOneRolePending(true));
    dispatch({
        type: FETCH_ONE_ROLE,
        payload: []
    });
    instance.post(`roles/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_ROLE,
                payload: response.data.data
            })
            dispatch(setFetchOneRolePending(false));
        })
        .catch(err => {
            dispatch(setFetchOneRolePending(false));
        });
}

export const deleteRoles = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('roles/delete', postData)
        .then(response => {
            dispatch(fetchRoles(pageNo, perPage, keyword));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchRoleError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}
export const allowRolePermissions = (id, permissions, group_id, application_id) => async dispatch => {

    instance.post(`roles/allowPermission/${id}`, { permissions: permissions })
        .then(response => {
            CustomSnackbar('success', response.data.data);
            dispatch(fetchPermissions(1, 10, "", group_id, application_id));
        })
        .catch(err => {
            if (err.response) {
                CustomSnackbar('error', err.response.data.data);
            }
        });
}
export const denyRolePermissions = (id, permissions, group_id, application_id) => async dispatch => {

    instance.post(`roles/denyPermission/${id}`, { permissions: permissions })
        .then(response => {
            CustomSnackbar('success', response.data.data);
            dispatch(fetchPermissions(1, 10, "", group_id, application_id));
        })
        .catch(err => {
            if (err.response) {
                CustomSnackbar('error', err.response.data.data);
            }
        });
}