import React from "react";
import logoIcon from "assets/images/WeShip1.png";
import img2 from "assets/images/background/login-register.jpg";
const sidebarBackground = {
  backgroundImage: "url(" + img2 + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
};

const Authlayout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="">
        <div
          className="auth-wrapper d-flex no-block justify-content-center align-items-center"
          style={sidebarBackground}
        >
          <div className="auth-box on-sidebar">
            <div id="authform">
              <div className="logo">
                <span className="db">
                  <img
                    src={logoIcon}
                    height={35}
                    className="authLogo"
                    alt="logo"
                  />
                </span>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Authlayout;
