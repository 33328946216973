import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";
import { history } from "helpers/history";

import Authlayout from "layouts/authlayout";

import { register } from "../actions/authActions";
import { appName } from "utils/axios";

import {
  Row,
  Col,
  TextField,
  Button,
  CustomSpinner,
} from "@mycentyr/centyr-ui";

const passwordPattern = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/;
const schema = yup.object().shape({
  user_firstname: yup.string().required("First Name is required"),
  user_lastname: yup.string().required("Last Name is required"),
  user_email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required"),
  user_password: yup
    .string()
    .required("Password is required")
    .matches(passwordPattern, "Password should contain atleast one number and one special character")
    .oneOf(
      [yup.ref("confirm_password"), null],
      "Password and Confirm Password does not match"
    )
    .min(6, "Password must be Atleast 6 Charecters"),
});

const Register = (props) => {
  document.title = "Sign Up " + appName;

  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
      user_firstname: data.user_firstname,
      user_lastname: data.user_lastname,
      user_email: data.user_email,
      user_password: data.user_password,
    };
    props.registerUser(postData);
  };

  return (
    <Authlayout>
      <div className="text-center">
        <h5 className="font-medium mb-3">Sign Up to Dashboard</h5>
      </div>
      <Row>
        <Col xs="12">
          {props.isRegisterPending && <CustomSpinner />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="First Name *"
              className="mb-3"
              name="user_firstname"
              id="user_firstname"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.user_firstname?.message ? true : false}
              helperText={errors.user_firstname?.message}
              inputRef={register}
            />
            <TextField
              label="Last Name *"
              className="mb-3"
              name="user_lastname"
              id="user_lastname"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.user_lastname?.message ? true : false}
              helperText={errors.user_lastname?.message}
              inputRef={register}
            />
            <TextField
              label="Email Address *"
              className="mb-3"
              name="user_email"
              id="user_email"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.user_email?.message ? true : false}
              helperText={errors.user_email?.message}
              inputRef={register}
            />
            <TextField
              label="Password"
              inputRef={register}
              className="mb-3"
              name="user_password"
              type="password"
              id="user_password"
              variant="outlined"
              size="small"
              fullWidth
              autoComplete="off"
              error={errors.user_password?.message ? true : false}
              helperText={errors.user_password?.message}
            />
           
            <TextField
              label="Confirm Password"
              inputRef={register}
              className="mb-3"
              name="confirm_password"
              type="password"
              id="confirm_password"
              variant="outlined"
              size="small"
              fullWidth
              autoComplete="off"
            />
            <Row className="mb-3">
              <Col xs="12">
                <Button type="submit" color="primary" block>
                  Register
                </Button>
                <p>*By continuing, you agree to our Terms of Service.</p>
              </Col>
            </Row>
            <Row className="mb-3 text-center">
              <Col xs="12">
              Already have an account?  Sign in!
                <Button
                  type="button"
                  color="primary"
                  block
                  outline
                  onClick={() => history.push("/sign/in")}
                >
                  Sign In
                </Button>
                
              </Col>
            </Row>

            {props.registerError && (
              <div className={"alert alert-danger"}>{props.registerError}</div>
            )}
          </form>

          <p>
              <strong> Password Requirements:</strong><br /><br />
              1) Your password must be between 6 and 30 characters.<br />
              2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)<br />
              3) Your password must contain at least one lowercase letter.<br />
              4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)<br />
              5) Your password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,)<br />
            </p>
        </Col>
      </Row>
    </Authlayout>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isRegisterPending: state.auth.isRegisterPending,
    registerError: state.auth.registerError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (postData, from) => {
      dispatch(register(postData, from));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
