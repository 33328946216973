import React from "react";
import { connect } from "react-redux";
import {
  fetchShippings,
  setFetchShippingError,
  deleteShippings,
} from "../actions/shippingActions";
import ShippingActionButtons from "./ShippingActionButtons";
import OneShipping from "./OneShipping";
import ShippingSkeleeton from "./ShippingSkeleeton";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  Button,
} from "@mycentyr/centyr-ui";

class ShippingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  componentDidMount() {
    this.props.fetchShippings();
  }

  render() {
    const headCells = [
      { id: "id", numeric: false, disablePadding: false, label: "#" },
      {
        id: "shipping_name",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (id, shipping_name, shipping_description) => {
      return { id, shipping_name, shipping_description };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchShippings(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchShippings(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteShippings(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchShippings(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.shippings) {
      this.props.shippings.map((shipping) =>
        rows.push(
          createData(
            shipping.id,
            shipping.shipping_carrier_name
          )
        )
      );
    }

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div className="list-container">
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Shipping</ModalHeader>
          <ModalBody>
            <OneShipping id="add" toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              <Button color="primary" className="mb-3" onClick={toggle}>
                Add Shipping
              </Button>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchShippingPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes("delete.shippings")
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={ShippingSkeleeton}
            Actions={ShippingActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchShippingsuccess: state.shipping.isFetchShippingsuccess,
    isFetchShippingPending: state.shipping.isFetchShippingPending,
    fetchShippingError: state.shipping.fetchShippingError,
    shippings: state.shipping.shippings,
    noOfRecords: state.shipping.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchShippings: (pageNo, perPage, keyword) => {
      dispatch(fetchShippings(pageNo, perPage, keyword));
    },
    setFetchShippingError: (postData) => {
      dispatch(setFetchShippingError(postData));
    },
    deleteShippings: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteShippings(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingList);
