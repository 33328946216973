import React from 'react'
import {
    Row,
    Col,
    Divider,
    Box,
    Tabs,
    Tab,
} from '@mycentyr/centyr-ui';

import UserDetail from './UserDetail';
import ChangePasswordForm from './ChangePasswordForm';

function Profile(props) {
    const [value, setValue] = React.useState('general');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <Row>
                    <Col className="page-title">
                        <h1 className='page-title'>User Profile</h1>
                    </Col>
                </Row>
            </div>
            <div className="content-wrapper">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="full width tabs example"
                >
                    <Tab value={'general'} label="General" />
                    <Tab value={'security'} label="Security" />
                </Tabs>
                <Divider />
                <Box
                    py={3}
                    pb={6}
                >
                    {value === 'general' && <UserDetail />}
                    {value === 'security' && <ChangePasswordForm />}
                </Box>
            </div>
        </React.Fragment>
    )
}

export default Profile;

