import { mainUserReducer } from 'app/manage/user/reducers';
import { mainRoleReducer } from 'app/manage/role/reducers';
import { mainSettingReducer } from 'app/manage/settings/reducers';
import { mainLogReducer } from 'app/manage/logs/reducers';
import { mainEmailProviderReducer } from 'app/manage/email/emailprovider/reducers';
import { mainEmailTemplateReducer } from 'app/manage/email/emailtemplate/slices';

export const mainManageReducer = {
    ...mainUserReducer,
    ...mainRoleReducer,
    ...mainSettingReducer,
    ...mainLogReducer,
    ...mainEmailProviderReducer,
    ...mainEmailTemplateReducer
};
