import { instance } from 'utils/axios';

import { FETCH_USER_DETAIL } from './types';

export const fetchUserDetail = () => (dispatch) => {
  dispatch({
    type: FETCH_USER_DETAIL,
    payload: [],
  });
  instance.post(`users/detail`, {}).then((response) => {
    dispatch({
      type: FETCH_USER_DETAIL,
      payload: response.data.data,
    });
  });
};
