import { CustomSnackbar } from '@mycentyr/centyr-ui';
import { createSlice } from '@reduxjs/toolkit';
import { instance } from 'utils/axios';

const initialState = {
  departments: [],
  noOfRecords: 0,
  department: {},
  isFetchDepartmentsPending: false,
  isFetchDepartmentPending: false,
  isAddUpdateDepartmentPending: false,
};

const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    fetchDepartments(state, action) {
      state.departments = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchDepartment(state, action) {
      state.department = action.payload;
    },
    setFetchDepartmentsPending(state, action) {
      state.isFetchDepartmentsPending = action.payload;
    },
    setFetchDepartmentPending(state, action) {
      state.isFetchDepartmentPending = action.payload;
    },
    setAddUpdateDepartmentsPending(state, action) {
      state.isAddUpdateDepartmentPending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchDepartments = (
  pageNo = 1,
  perPage = 10,
  keyword = '',
  order = '',
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchDepartmentsPending(true));
  dispatch(slice.actions.fetchDepartments({ items: [], count: -1 }));

  const response = await instance
    .post(
      `helpdesk/departments/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? ' desc' : ''
      }`,
      {}
    )
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchDepartmentsPending(false));
  if (response) {
    dispatch(slice.actions.fetchDepartments(response.data.data));
  }
};

export const fetchAllDepartments = () => async (dispatch) => {
  dispatch(slice.actions.setFetchDepartmentsPending(true));
  dispatch(slice.actions.fetchDepartments({ items: [], count: -1 }));

  const response = await instance
    .post('helpdesk/departments/get', {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchDepartmentsPending(false));
  if (response) {
    dispatch(
      slice.actions.fetchDepartments({
        items: response.data.data,
        count: response.data.data.length,
      })
    );
  }
};

export const fetchDepartment = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchDepartmentPending(true));

  const response = await instance
    .post(`helpdesk/departments/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchDepartmentPending(false));
  if (response) {
    dispatch(slice.actions.fetchDepartment(response.data.data));
  }
};

export const addDepartment = (postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateDepartmentsPending(true));

  const response = await instance
    .post('helpdesk/departments/create', postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateDepartmentsPending(false));
  if (response) {
    toggle();
    CustomSnackbar('success', 'Department added successfully');
    dispatch(fetchDepartments());
  }
};

export const updateDepartment = (id, postData, toggle) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateDepartmentsPending(true));

  const response = await instance
    .post(`helpdesk/departments/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateDepartmentsPending(false));
  if (response) {
    toggle();
    CustomSnackbar('success', 'Department updated successfully');
    dispatch(fetchDepartments());
  }
};

export const deleteDepartments = (
  postData,
  pageNo,
  perPage,
  keyword = ''
) => async (dispatch) => {
  const response = await instance
    .post('helpdesk/departments/delete', postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  if (response) {
    CustomSnackbar('success', 'Department deleted successfully');
    dispatch(fetchDepartments(pageNo, perPage, keyword));
  }
};

export default slice;
