//setting
export const FETCH_SETTING_SUCCESS = 'FETCH_SETTING_SUCCESS';
export const FETCH_SETTING_PENDING = 'FETCH_SETTING_PENDING';
export const FETCH_SETTING_ERROR = 'FETCH_SETTING_ERROR';
export const FETCH_SETTING = 'FETCH_SETTING';
export const FETCH_SETTING_COUNT = 'FETCH_SETTING_COUNT';
export const SAVE_SETTING_ERROR = 'SAVE_SETTING_ERROR';
export const SAVE_SETTING_PENDING = 'SAVE_SETTING_PENDING';
export const SAVE_SETTING_SUCCESS = 'SAVE_SETTING_SUCCESS';

//country
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_PENDING = 'FETCH_COUNTRY_PENDING';
export const FETCH_COUNTRY_ERROR = 'FETCH_COUNTRY_ERROR';
export const FETCH_COUNTRY = 'FETCH_COUNTRY';
export const FETCH_COUNTRY_COUNT = 'FETCH_COUNTRY_COUNT';