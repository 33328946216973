import React from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'redux/store';
import PackageForm from './PackageForm';
import PackageImages from './PackageImages';
import PackageLogs from './PackageLogs';

import { Row, Col, Tab, Tabs, Box, Divider } from '@mycentyr/centyr-ui';
import { appName } from 'utils/axios';

const OnePackage = (props) => {
  const { id } = props.match.params;
  const [value, setValue] = React.useState('general');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { packageData, isFetchPackagePending, isAddUpdatePackagePending } =
    useSelector((state) => state.package);

  if (id !== 'add') document.title = 'Manage Package' + appName;
  else document.title = 'Create New Package' + appName;

  return (
    <React.Fragment>
      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1 className='page-title'>
              {id === 'add'
                ? 'Create New Package'
                : packageData.package_id &&
                  `Manage Package (${packageData.package_id})`}
            </h1>
          </Col>
          <Col className='text-right page-header-button'></Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        {id === 'add' ? (
          <PackageForm id={id} />
        ) : (
          <React.Fragment>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              aria-label='full width tabs example'
            >
              <Tab value={'general'} label='Package Information' />
              <Tab value={'images'} label='Package Images' />
              <Tab value={'activity'} label='Package Activity' />
            </Tabs>
            <Divider />
            <Box py={3} pb={6}>
              {value === 'general' && <PackageForm id={id} />}
              {value === 'images' && <PackageImages id={id} {...props} />}
              {value === 'activity' && <PackageLogs id={id} />}
            </Box>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default OnePackage;
