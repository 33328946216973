import {
    FETCH_USER_SUCCESS,
    FETCH_USER_PENDING,
    FETCH_USER_ERROR,
    FETCH_USER,
    FETCH_USER_COUNT,
    ADD_UPDATE_USER_ERROR,
    FETCH_ONE_USER,
    FETCH_ONE_USER_PENDING,
    ADD_UPDATE_USER_PENDING,
    ADD_UPDATE_USER_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchUserSuccess: false,
    isFetchUserPending: false,
    fetchUserError: null,
    addUpdateUserError: null,
    userData: [],
    isUserDataPending: false,
    users: null,
    noOfRecords: 0,
    isAddUpdateUserPending: false,
    isAddUpdateUserSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        
        case FETCH_USER:
            return {
                ...state,
                users: action.payload
            };

        case FETCH_USER_SUCCESS:
            return {
                ...state,
                isFetchUserSuccess: action.payload
            };

        case FETCH_USER_PENDING:
            return {
                ...state,
                isFetchUserPending: action.payload
            };

        case FETCH_USER_ERROR:
            return {
                ...state,
                fetchUserError: action.payload
            };

        case FETCH_USER_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_USER:
            return {
                ...state,
                userData: action.payload
            };

        case ADD_UPDATE_USER_ERROR:
            return {
                ...state,
                addUpdateUserError: action.payload
            };

        case FETCH_ONE_USER_PENDING:
            return {
                ...state,
                isUserDataPending: action.payload
            };

        case ADD_UPDATE_USER_PENDING:
            return {
                ...state,
                isAddUpdateUserPending: action.payload
            };

        case ADD_UPDATE_USER_SUCCESS:
            return {
                ...state,
                isAddUpdateUserSuccess: action.payload
            };

        default:
            return state;
    }
}