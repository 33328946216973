import React from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import createSvgIcon from '@material-ui/core/utils/createSvgIcon'
import { bytesToSize } from 'utils/fun'

const XIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
  >
    <path
      fillRule='evenodd'
      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
      clipRule='evenodd'
    />
  </svg>,
  'X'
)

const DuplicateIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
  >
    <path d='M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z' />
    <path d='M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z' />
  </svg>,
  'Duplicate'
)

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    border: '1px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.divider,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    outline: 'none',
    padding: theme.spacing(6),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
      opacity: 0.5
    }
  },
  dragRoot: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  imgContainer: {
    '& img': {
      width: 100
    }
  },
  eachFile: {
    border: '1px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.divider,
    '& + &': {
      marginTop: theme.spacing(1)
    }
  }
}))

const FileDropzone = (props) => {
  const classes = useStyles()

  const {
    accept,
    disabled,
    files,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    fileImg,
    ...other
  } = props

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop
  })

  return (
    <div {...other}>
      <Box
        className={classes.root + (isDragActive ? ' ' + classes.dragRoot : '')}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box className={classes.imgContainer}>
          <img alt='Select file' src={fileImg} />
        </Box>
        <Box p={2}>
          <Typography color='textPrimary' variant='h6'>
            {`Select file${maxFiles && maxFiles === 1 ? '' : 's'}`}
          </Typography>
          <Box mt={2}>
            <Typography color='textPrimary' variant='body1'>
              {`Drop file${maxFiles && maxFiles === 1 ? '' : 's'}`}{' '}
              <Link color='primary' underline='always'>
                browse
              </Link>{' '}
              thorough your machine
            </Typography>
          </Box>
        </Box>
      </Box>
      {files.length > 0 && (
        <Box mt={2}>
          <List>
            {files.map((file, index) => (
              <ListItem key={index} className={classes.eachFile}>
                <ListItemIcon>
                  <DuplicateIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'subtitle2'
                  }}
                  secondary={bytesToSize(file.size)}
                />
                <IconButton
                  edge='end'
                  onClick={() => onRemove && onRemove(file)}
                >
                  <XIcon fontSize='small' />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  )
}

export default FileDropzone
