import { instance } from 'utils/axios'
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_COUNTRY_SUCCESS,
    FETCH_COUNTRY_PENDING,
    FETCH_COUNTRY_ERROR,
    FETCH_COUNTRY,
    FETCH_COUNTRY_COUNT,
} from './types';

const setFetchCountrySuccess = isFetchCountrySuccess => dispatch => {
    dispatch({
        type: FETCH_COUNTRY_SUCCESS,
        payload: isFetchCountrySuccess
    });
}

const setFetchCountryPending = isFetchCountryPending => dispatch => {
    dispatch({
        type: FETCH_COUNTRY_PENDING,
        payload: isFetchCountryPending
    });
}

export const setFetchCountryError = fetchCountryError => dispatch => {
    dispatch({
        type: FETCH_COUNTRY_ERROR,
        payload: fetchCountryError
    });
}

export const fetchCountries = () => async dispatch => {

    dispatch(setFetchCountryPending(true));
    dispatch({
        type: FETCH_COUNTRY_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_COUNTRY,
        payload: null
    });

    instance.post(`countries/get`, {})
        .then(response => {
            dispatch(setFetchCountrySuccess(true));
            dispatch({
                type: FETCH_COUNTRY_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_COUNTRY,
                payload: response.data.data
            });
            dispatch(setFetchCountryPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchCountryError(err.response.data.data));
            dispatch(setFetchCountryPending(false));
        });

}
