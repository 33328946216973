import { createSlice } from '@reduxjs/toolkit';
import { instance } from 'utils/axios';
import { history } from 'helpers/history';
import { CustomSnackbar } from '@mycentyr/centyr-ui';

const initialState = {
  tickets: [],
  noOfRecords: 0,
  ticket: {},
  isFetchTicketsPending: false,
  isFetchTicketPending: false,
  isAddUpdateTicketPending: false,
  isAddUpdateReplyPending: false,
};

const slice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    fetchTickets(state, action) {
      state.tickets = action.payload.items;
      state.noOfRecords = action.payload.count;
    },
    fetchTicket(state, action) {
      state.ticket = action.payload;
    },
    setFetchTicketsPending(state, action) {
      state.isFetchTicketsPending = action.payload;
    },
    setFetchTicketPending(state, action) {
      state.isFetchTicketPending = action.payload;
    },
    setAddUpdateTicketsPending(state, action) {
      state.isAddUpdateTicketPending = action.payload;
    },
    setAddUpdateReplyPending(state, action) {
      state.isAddUpdateReplyPending = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const fetchTickets = (
  pageNo = 1,
  perPage = 10,
  keyword = '',
  order = '',
  desc = false
) => async (dispatch) => {
  dispatch(slice.actions.setFetchTicketsPending(true));
  dispatch(slice.actions.fetchTickets({ items: [], count: -1 }));

  const response = await instance
    .post(
      `helpdesk/tickets/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${
        desc ? ' desc' : ''
      }`,
      {}
    )
    .catch((error) => {
      CustomSnackbar('error', error.errorToDisplay);
    });
  dispatch(slice.actions.setFetchTicketsPending(false));
  if (response) {
    dispatch(slice.actions.fetchTickets(response.data.data));
  }
};

export const fetchTicket = (id) => async (dispatch) => {
  dispatch(slice.actions.setFetchTicketPending(true));

  const response = await instance
    .post(`helpdesk/tickets/get/${id}`, {})
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setFetchTicketPending(false));
  if (response) {
    dispatch(slice.actions.fetchTicket(response.data.data));
  }
};

export const addTicket = (postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateTicketsPending(true));

  const response = await instance
    .post('helpdesk/tickets/create', postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateTicketsPending(false));
  if (response) {
    history.push(`${response.data.data.id}/view`);
  }
};

export const updateTicket = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateTicketsPending(true));
  dispatch(slice.actions.setFetchTicketPending(true));

  const response = await instance
    .post(`helpdesk/tickets/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateTicketsPending(false));
  dispatch(slice.actions.setFetchTicketPending(false));
  if (response) {
    dispatch(fetchTicket(id));
  }
};

export const deleteTickets = (
  postData,
  pageNo,
  perPage,
  keyword = ''
) => async (dispatch) => {
  const response = await instance
    .post('helpdesk/tickets/delete', postData)
    .catch((error) => {
      CustomSnackbar('error', error.errorToDisplay);
    });
  if (response) {
    dispatch(fetchTickets(pageNo, perPage, keyword));
  }
};

export const addReply = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateReplyPending(true));

  const response = await instance
    .post('helpdesk/tickets/replies/create', postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateReplyPending(false));
  if (response) {
    dispatch(fetchTicket(id));
  }
};

export const updateReply = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateReplyPending(true));

  const response = await instance
    .post(`helpdesk/tickets/replies/update/${id}`, postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateReplyPending(false));
  if (response) {
    dispatch(fetchTicket(postData.ticket_id));
  }
};

export const deleteReply = (id, postData) => async (dispatch) => {
  dispatch(slice.actions.setAddUpdateReplyPending(true));

  const response = await instance
    .post('helpdesk/tickets/replies/delete', postData)
    .catch((err) => {
      if (err.response) {
        CustomSnackbar('error', err.response.data.data);
      }
    });
  dispatch(slice.actions.setAddUpdateReplyPending(false));
  if (response) {
    dispatch(fetchTicket(id));
  }
};

export default slice;
