import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";

import Authlayout from "layouts/authlayout";

import { history } from "helpers/history";
import { resetPassword, setResetPasswordSuccess } from "../actions/authActions";
import { appName } from "utils/axios";

import { Row, Col, TextField, Button } from "@mycentyr/centyr-ui";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter valid email"),
});

const ResetPasswordForm = (props) => {
  document.title = "Forgot Password " + appName;
  const { register, handleSubmit, errors } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let email = data.email;
    props.resetPassword({ email });
  };

  const handleCancelResetPasswordClick = () => {
    props.setResetPasswordSuccess(null);
    history.push("sign/in");
  };
  return (
    <Authlayout>
      <div className="text-center">
        <h5 className="font-medium mb-3">Recover Password</h5>
        <span>Enter your Email and instructions will be sent to you!</span>
      </div>
      <Row>
        <Col xs="12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              className="mt-3"
              label="Email Address"
              name="email"
              id="reset_password_email"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.email?.message ? true : false}
              helperText={errors.email?.message}
              inputRef={register}
            />
            <Row className="mt-3">
              <Col xs="6" className="mb-3">
                <Button type="submit" color="primary" block>
                  submit
                </Button>
              </Col>
              <Col xs="6" className="mb-3">
                <Button
                  color="primary"
                  block
                  outline
                  onClick={handleCancelResetPasswordClick}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
          {props.resetPasswordError && (
            <div className={"alert alert-danger"}>
              {props.resetPasswordError}
            </div>
          )}
          {props.resetPasswordSuccess && handleCancelResetPasswordClick && (
            <div className={"alert alert-success"}>
              {props.resetPasswordSuccess}
            </div>
          )}
        </Col>
      </Row>
    </Authlayout>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isResetPasswordPending: state.auth.isResetPasswordPending,
    resetPasswordError: state.auth.resetPasswordError,
    resetPasswordSuccess: state.auth.resetPasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (postData) => {
      dispatch(resetPassword(postData));
    },
    setResetPasswordSuccess: (data) => {
      dispatch(setResetPasswordSuccess(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
