//Import Components for Manage Routes
import Dashboard from 'app/manage/dashboard/views/dashboard';

//Import icons for Manage Nav Section
import DashboardIcon from '@material-ui/icons/Dashboard';

const dashboardroutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        title: 'Dashboard',
        id: 'dashboard',
        icon: DashboardIcon,
        permission: [],
        child: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                title: 'Main Dashboard',
                icon: DashboardIcon,
                component: Dashboard,
            }
        ]
    },
]

export default dashboardroutes;