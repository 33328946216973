import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import {CustomSnackbar} from '@mycentyr/centyr-ui'

import {
    FETCH_APPLICATION_SUCCESS,
    FETCH_APPLICATION_PENDING,
    FETCH_APPLICATION_ERROR,
    FETCH_APPLICATION,
    FETCH_APPLICATION_COUNT,
    ADD_UPDATE_APPLICATION_ERROR,
    ADD_UPDATE_APPLICATION_PENDING,
    ADD_UPDATE_APPLICATION_SUCCESS,
    FETCH_ONE_APPLICATION,
    FETCH_ONE_APPLICATION_PENDING,
} from './types';

const setFetchApplicationSuccess = isFetchApplicationSuccess => dispatch => {
    dispatch({
        type: FETCH_APPLICATION_SUCCESS,
        payload: isFetchApplicationSuccess
    });
}

const setFetchApplicationPending = isFetchApplicationPending => dispatch => {
    dispatch({
        type: FETCH_APPLICATION_PENDING,
        payload: isFetchApplicationPending
    });
}

const setAddUpdateApplicationPending = isAddUpdateApplicationPending => dispatch => {
    dispatch({
        type: ADD_UPDATE_APPLICATION_PENDING,
        payload: isAddUpdateApplicationPending
    });
}

const setFetchOneApplicationPending = isApplicationDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_APPLICATION_PENDING,
        payload: isApplicationDataPending
    });
}

export const setAddUpdateApplicationError = addUpdateApplicationError => dispatch => {
    dispatch({
        type: ADD_UPDATE_APPLICATION_ERROR,
        payload: addUpdateApplicationError
    });
}

export const setAddUpdateApplicationSuccess = isAddUpdateApplicationSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_APPLICATION_SUCCESS,
        payload: isAddUpdateApplicationSuccess
    });
}

export const setFetchApplicationError = fetchApplicationError => dispatch => {
    dispatch({
        type: FETCH_APPLICATION_ERROR,
        payload: fetchApplicationError
    });
}

export const fetchApplications = (pageNo = 1, perPage = 10, keyword = "", order = "", postType = "post", desc = false) => async dispatch => {

    dispatch(setFetchApplicationPending(true));
    dispatch({
        type: FETCH_APPLICATION_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_APPLICATION,
        payload: null
    });

    instance.post(`setup/applications/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchApplicationSuccess(true));
            dispatch({
                type: FETCH_APPLICATION_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_APPLICATION,
                payload: response.data.data.items
            });
            dispatch(setFetchApplicationPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchApplicationError(err.response.data.data));
            dispatch(setFetchApplicationPending(false));
        });

}

export const fetchAllApplications = () => async dispatch => {

    dispatch(setFetchApplicationPending(true));
    dispatch({
        type: FETCH_APPLICATION_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_APPLICATION,
        payload: []
    });

    instance.post(`setup/applications/get`, {})
        .then(response => {
            dispatch(setFetchApplicationSuccess(true));
            dispatch({
                type: FETCH_APPLICATION_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_APPLICATION,
                payload: response.data.data
            });
            dispatch(setFetchApplicationPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchApplicationError(err.response.data.data));
            dispatch(setFetchApplicationPending(false));
        });

}

export const addApplication = (postData, toggle) => async dispatch => {
    dispatch(setAddUpdateApplicationSuccess(false));
    dispatch(setAddUpdateApplicationPending(true));
    instance.post('setup/applications/create', postData)
        .then(response => {
            dispatch(setAddUpdateApplicationSuccess(true));
            toggle();
            CustomSnackbar('success', 'Application added successfully');
            dispatch(fetchApplications());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateApplicationError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateApplicationPending(false));
        });

}

export const updateApplication = (id, postData, toggle) => async dispatch => {

    dispatch(setAddUpdateApplicationSuccess(false));
    dispatch(setAddUpdateApplicationPending(true));
    dispatch(setFetchOneApplicationPending(true));

    instance.post(`setup/applications/update/${id}`, postData)
        .then(response => {
            dispatch(setAddUpdateApplicationSuccess(true));
            CustomSnackbar('success', 'Application updated successfully');
            toggle();
            dispatch(fetchApplications());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateApplicationError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateApplicationPending(false));
            dispatch(setFetchOneApplicationPending(false));
        });

}

export const fetchApplication = (id) => async dispatch => {

    dispatch(setFetchOneApplicationPending(true));

    instance.post(`setup/applications/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_APPLICATION,
                payload: response.data.data
            });
            dispatch(setFetchOneApplicationPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneApplicationPending(false));
        });
}

export const deleteApplications = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('setup/applications/delete', postData)
        .then(response => {
            dispatch(fetchApplications(pageNo, perPage, keyword));
            CustomSnackbar('success', response.data.message);
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchApplicationError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}
