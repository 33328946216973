import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_PERMISSION_SUCCESS,
    FETCH_PERMISSION_PENDING,
    FETCH_PERMISSION_ERROR,
    FETCH_PERMISSION,
    FETCH_PERMISSION_COUNT,
    ADD_UPDATE_PERMISSION_ERROR,
    ADD_UPDATE_PERMISSION_PENDING,
    ADD_UPDATE_PERMISSION_SUCCESS,
    FETCH_ONE_PERMISSION,
    FETCH_ONE_PERMISSION_PENDING,
} from './types';

const setFetchPermissionSuccess = isFetchPermissionSuccess => dispatch => {
    dispatch({
        type: FETCH_PERMISSION_SUCCESS,
        payload: isFetchPermissionSuccess
    });
}

const setFetchPermissionPending = isFetchPermissionPending => dispatch => {
    dispatch({
        type: FETCH_PERMISSION_PENDING,
        payload: isFetchPermissionPending
    });
}

const setAddUpdatePermissionPending = isAddUpdatePermissionPending => dispatch => {
    dispatch({
        type: ADD_UPDATE_PERMISSION_PENDING,
        payload: isAddUpdatePermissionPending
    });
}

const setFetchOnePermissionPending = isPermissionDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_PERMISSION_PENDING,
        payload: isPermissionDataPending
    });
}

export const setAddUpdatePermissionError = addUpdatePermissionError => dispatch => {
    dispatch({
        type: ADD_UPDATE_PERMISSION_ERROR,
        payload: addUpdatePermissionError
    });
}

export const setAddUpdatePermissionSuccess = isAddUpdatePermissionSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_PERMISSION_SUCCESS,
        payload: isAddUpdatePermissionSuccess
    });
}

export const setFetchPermissionError = fetchPermissionError => dispatch => {
    dispatch({
        type: FETCH_PERMISSION_ERROR,
        payload: fetchPermissionError
    });
}
export const fetchAllPermissions = () => async dispatch => {

    dispatch(setFetchPermissionPending(true));
    dispatch({
        type: FETCH_PERMISSION_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_PERMISSION,
        payload: null
    });

    instance.post(`setup/permissions/get`, {})
        .then(response => {
            dispatch(setFetchPermissionSuccess(true));
            dispatch({
                type: FETCH_PERMISSION_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_PERMISSION,
                payload: response.data.data
            });
            dispatch(setFetchPermissionPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchPermissionError(err.response.data.data));
            dispatch(setFetchPermissionPending(false));
        });

}


export const fetchPermissions = (pageNo = 1, perPage = 10, keyword = "", group_id = 0, application_id = 0, order = "", desc = false) => async dispatch => {

    dispatch(setFetchPermissionPending(true));
    dispatch({
        type: FETCH_PERMISSION_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_PERMISSION,
        payload: null
    });

    instance.post(`setup/permissions/getAll/${pageNo}/${perPage}/search=${keyword}&group_id=${group_id}&application_id=${application_id}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchPermissionSuccess(true));
            dispatch({
                type: FETCH_PERMISSION_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_PERMISSION,
                payload: response.data.data.items
            });
            dispatch(setFetchPermissionPending(false));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchPermissionError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
            dispatch(setFetchPermissionPending(false));
        });

}

export const addPermission = (postData, toggle) => async dispatch => {
    dispatch(setAddUpdatePermissionSuccess(false));
    dispatch(setAddUpdatePermissionPending(true));
    instance.post('setup/permissions/create', postData)
        .then(response => {
            CustomSnackbar('success', 'Permission added successfully');
            dispatch(setAddUpdatePermissionSuccess(true));
            toggle();
            dispatch(fetchPermissions());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdatePermissionError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdatePermissionPending(false));
        });

}

export const updatePermission = (id, postData, toggle) => async dispatch => {

    dispatch(setAddUpdatePermissionSuccess(false));
    dispatch(setAddUpdatePermissionPending(true));
    dispatch(setFetchOnePermissionPending(true));

    instance.post(`setup/permissions/update/${id}`, postData)
        .then(response => {
            dispatch({
                type: FETCH_ONE_PERMISSION,
                payload: response.data.data
            });
            CustomSnackbar('success', 'Permission updated successfully');
            dispatch(setAddUpdatePermissionSuccess(true));
            toggle();
            dispatch(fetchPermissions());
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdatePermissionError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdatePermissionPending(false));
            dispatch(setFetchOnePermissionPending(false));
        });

}

export const fetchPermission = (id) => async dispatch => {

    dispatch(setFetchOnePermissionPending(true));

    instance.post(`setup/permissions/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_PERMISSION,
                payload: response.data.data
            });
            dispatch(setFetchOnePermissionPending(false));
        })
        .catch(err => {
            dispatch(setFetchOnePermissionPending(false));
        });
}

export const deletePermissions = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('setup/permissions/delete', postData)
        .then(response => {
            dispatch(fetchPermissions(pageNo, perPage, keyword));
            CustomSnackbar('success', response.data.message);
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchPermissionError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}