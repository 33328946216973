import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import FileCopy from "@material-ui/icons/FileCopy";

var QuickLink = [
  {
    path: "/crm/customers/add",
    name: "Customer",
    permission: "add.crm.customers",
    icon: PeopleAltIcon,
  },
  {
    path: "/manage/users/add",
    name: "User",
    permission: "add.users",
    icon: GroupAddIcon,
  },
];
export default QuickLink;
