import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { deleteTemplateTags } from "../slices/templateTagSlice";
import { connect } from "react-redux";
import { useDispatch } from "redux/store";
import {
  Modal,
  ModalBody,
  ModalHeader,
  CustomModal,
  Popover,
  PopoverBody,
  IconButton,
} from "@mycentyr/centyr-ui";
import { history } from "helpers/history";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import OneTemplateTag from "./OneTemplateTag";

const TemplateTagActionButtons = (props) => {
  const dispatch = useDispatch();
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      dispatch(deleteTemplateTags({ id: row.id }, page, rowsPerPage, keyword));
    } else
      dispatch(
        deleteTemplateTags({ id: row.id }, page + 1, rowsPerPage, keyword)
      );
    setSelected([]);
  };

  return (
    <div>
      <IconButton
        aria-label="key"
        className="no-border-onfocus"
        onClick={togglePopover}
        id={`Popover-${row.id}`}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        placement="left"
        trigger="legacy"
        isOpen={popover}
        target={`Popover-${row.id}`}
        toggle={togglePopover}
      >
        <PopoverBody>
          {props.authUserData.permissions &&
            props.authUserData.permissions.includes("edit.template.tags") && (
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  togglePopover();
                  toggle1();
                }}
              >
                <EditIcon /> Edit
              </a>
            )}
          {props.authUserData.permissions &&
            props.authUserData.permissions.includes("delete.template.tags") && (
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggle();
                }}
              >
                <DeleteIcon /> Delete
              </a>
            )}
        </PopoverBody>
      </Popover>
      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle="Delete"
        modalBody="Are you sure you want to delete?"
      />
      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Update Template Tag</ModalHeader>
        <ModalBody>
          <OneTemplateTag id={row.id} toggle={toggle1} />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(TemplateTagActionButtons);
