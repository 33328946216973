import { mainPermissionReducer } from "app/setup/permission/reducers";
import { mainApplicationGroupReducer } from "app/setup/group/reducers";
import { mainApplicationReducer } from "app/setup/application/reducers";
import { mainTemplateTagReducer } from "app/setup/templatetag/slices";
export const mainSetupReducer = {
  ...mainPermissionReducer,
  ...mainApplicationGroupReducer,
  ...mainApplicationReducer,
  ...mainTemplateTagReducer,
};
