import React, { useEffect } from "react";
import { Prompt } from "react-router";
import { appName } from "utils/axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";
import { history } from "helpers/history";
import {
  addRole,
  fetchRole,
  setAddUpdateRoleError,
  updateRole,
  setAddUpdateRoleSuccess,
} from "../actions/roleActions";
import useEscape from "layouts/useEscape";
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CustomSpinner,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  CardTitle,
} from "@mycentyr/centyr-ui";

import PermissionTable from "./PermissionTable";

//eslint-disable-next-line
var regEx = /[a-z\-\_]$/;
const schema = yup.object().shape({
  role_slug: yup
    .string()
    .matches(regEx, "Role is invalid")
    .required("Role is required"),
  role_name: yup.string().required("Name is required"),
});

const initialValue = {
  role_name: "",
  role_slug: "",
  role_description: "",
};
const OneRole = (props) => {
  const { id } = props.match.params;
  if (id !== "add") document.title = "Edit Role" + appName;
  else document.title = "Add Role" + appName;

  useEscape(() => history.push("list"));
  useEffect(() => {
    if (id !== "add") {
      props.fetchRole(id);
    }
  }, [id]);

  useEffect(() => {
    reset(
      id === "add"
        ? initialValue
        : {
            role_name: props.roleData.role_name || "",
            role_slug: props.roleData.role_slug || "",
            role_description: props.roleData.role_description || "",
            is_default: props.roleData.is_default || [],
          }
    );
  }, [props.roleData, id]);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });
  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const onSubmit = (data) => {
    const postData = {
      role_name: data.role_name,
      role_slug: data.role_slug,
      role_description: data.role_description,
      is_default: data.is_default || 0,
    };
    id === "add" ? props.addRole(postData) : props.updateRole(id, postData);
  };

  if (!props.isAddUpdateRolePending && props.isRoleDataPending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Prompt
          when={isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">
                {id === "add" ? "Create New Role" : "Update Role"}
              </h1>
            </Col>
            <Col className="text-right page-header-button"></Col>
          </Row>
        </div>
        <div className="content-wrapper">
          {props.isAddUpdateRolePending && <CustomSpinner />}
          <Row>
            <Col lg="6" xs="12">
              <Card>
                <CardBody className="">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          label="Role Name"
                          className="mb-3"
                          name="role_name"
                          id="role_name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={errors.name?.message ? true : false}
                          helperText={errors.name?.message}
                          inputRef={register}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          label="Role"
                          className="mb-3"
                          name="role_slug"
                          id="role_slug"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={errors.role_slug?.message ? true : false}
                          helperText={errors.role_slug?.message}
                          inputRef={register}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          label="Role Description"
                          className="mb-3"
                          name="role_description"
                          id="role_description"
                          variant="outlined"
                          size="small"
                          multiline
                          rows="2"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.role_description?.message ? true : false
                          }
                          helperText={errors.role_description?.message}
                          inputRef={register}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="is_default"
                              id="is_default"
                              color="primary"
                              value={1}
                              inputRef={register}
                              defaultChecked={
                                id !== 1 && props.roleData.is_default === 1
                              }
                            />
                          }
                          label="Check to Set Default Role"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12" className="text-right">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={props.isAddUpdateRolePending}
                        >
                          {id === "add"
                            ? "Create and Select Permissions"
                            : "Update"}
                        </Button>
                        <Button
                          className="ml-2"
                          type="button"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("list");
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xs="12">
              {id !== "add" && (
                <Card>
                  <CardTitle>Role</CardTitle>
                  <CardBody>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle1">User</Typography>
                          </TableCell>
                          <TableCell width="2">:</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {props.roleData.user_count}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle1">
                              Granted Permissions
                            </Typography>
                          </TableCell>
                          <TableCell width="2">:</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {props.roleData.granted_permission_count}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle1">
                              Denied Permissions
                            </Typography>
                          </TableCell>
                          <TableCell width="2">:</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {props.roleData.denied_permission_count}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {id !== "add" && (
            <Row>
              <Col lg="12" xs="12">
                <PermissionTable
                  allowedPermissions={props.roleData.permissions}
                  id={id}
                  type="role"
                />
              </Col>
            </Row>
          )}
        </div>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    roleData: state.role.roleData,
    isAddUpdateRolePending: state.role.isAddUpdateRolePending,
    addUpdateRoleError: state.role.addUpdateRoleError,
    isRoleDataPending: state.role.isRoleDataPending,
    isAddUpdateRoleSuccess: state.role.isAddUpdateRoleSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRole: (postData) => {
      dispatch(addRole(postData));
    },
    fetchRole: (id) => {
      dispatch(fetchRole(id));
    },
    setAddUpdateRoleError: (error) => {
      dispatch(setAddUpdateRoleError(error));
    },
    updateRole: (id, postData) => {
      dispatch(updateRole(id, postData));
    },
    setAddUpdateRoleSuccess: (isAddUpdateRoleSuccess) => {
      dispatch(setAddUpdateRoleSuccess(isAddUpdateRoleSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneRole);
