const LocalStorageService = (() => {
    var _service;
    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    //uid-selector
    function _setUid(uid) {
        localStorage.setItem('uid', uid);
    }
    function _getUid() {
        return localStorage.getItem('uid');
    }
    function _clearUid() {
        localStorage.removeItem('uid');
    }

    return {
        getService: _getService,
        setUid: _setUid,
        getUid: _getUid,
        clearUid: _clearUid
    }
})();

export default LocalStorageService;
