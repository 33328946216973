import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import { appName } from 'utils/axios';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { history } from 'helpers/history';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'redux/store';
import { addTicket } from '../slices/ticketSlice';
import { fetchAllDepartments } from 'app/helpdesk/department/slices/departmentSlice';
import { fetchAllCustomers } from 'app/crm/customer/actions/customerActions';

import {
  Card,
  CardBody,
  Row,
  Col,
  CustomSpinner,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  TextField,
  Button,
} from '@mycentyr/centyr-ui';
import useEscape from "layouts/useEscape";
import { FileDropzone } from '@mycentyr/centyr-ui';
import ReactQuill from 'react-quill';

import addFileImg from 'assets/images/undraw_add_file2_gvbb.svg';

const schema = yup.object().shape({
  customer: yup.string().required('Customer is required'),
  subject: yup.string().required('Subject is required'),
  department: yup.string().required('Department is required'),
  priority: yup.string().required('Priority is required'),
  message: yup.string().required('Message is required'),
});

const initialValue = {
  customer: '',
  subject: '',
  department: '',
  priority: 'medium',
  message: '',
};

// const customers = [
//   { value: '1', label: 'Ct 1' },
//   { value: '2', label: 'Ct 2' },
// ];

const priorities = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];

var formSubmited = false;

const OneTicket = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [files, setFiles] = useState([]);

  const { id } = params;
  const { customers } = props;
  const { isAddUpdateTicketPending } = useSelector((state) => state.ticket);
  const { departments } = useSelector((state) => state.department);

  document.title = 'Add Ticket' + appName;
  useEscape(() => history.push("list"));
  useEffect(() => {
    if (id !== 'add') {
      history.replace('/');
    }
    dispatch(fetchAllDepartments());
    dispatch(fetchAllCustomers());
    formSubmited = false;
  }, []);

  const { register, handleSubmit, errors, reset, formState, control } = useForm(
    {
      mode: 'onblur',
      resolver: yupResolver(schema),
      defaultValues: initialValue,
    }
  );
  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const onSubmit = (data) => {
    const postData = new FormData();
    postData.append('customer_id', data.customer);
    postData.append('ticket_subject', data.subject);
    postData.append('department_id', data.department);
    postData.append('ticket_priority', data.priority);
    postData.append('ticket_status', 'open');
    postData.append('ticket_content', data.message);
    files.map((file, index) =>
      postData.append(`ticket_attachment${index}`, file)
    );
    // ticket_notes: '',
    // ticket_assigned: '',
    formSubmited = true;
    dispatch(addTicket(postData));
  };

  const handleDrop = (newFiles, rejectedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_file) => _file.path !== file.path)
    );
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  if (isAddUpdateTicketPending) return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Prompt
          when={!formSubmited && isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">Open New Ticket</h1>
            </Col>
            <Col className="text-right page-header-button"></Col>
          </Row>
        </div>
        <div className="content-wrapper">
          {isAddUpdateTicketPending && <CustomSpinner />}
          <Row>
            <Col lg="12" xs="12">
              <Card>
                <CardBody className="">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col lg="12" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.customer}
                          className="mb-3"
                        >
                          <InputLabel>Customer</InputLabel>
                          <Controller
                            as={
                              <Select native label="Customer">
                                <option aria-label="None" value="" />
                                {customers?.map((customer, index) => (
                                  <option key={index} value={customer.id}>
                                    {customer.user_display_name}
                                  </option>
                                ))}
                              </Select>
                            }
                            name="customer"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.customer?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          label="Subject"
                          className="mb-3"
                          name="subject"
                          id="subject"
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.subject}
                          helperText={errors.subject?.message}
                          inputRef={register}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.department}
                          className="mb-3"
                        >
                          <InputLabel>Department</InputLabel>
                          <Controller
                            as={
                              <Select native label="Department">
                                <option aria-label="None" value="" />
                                {departments.map((department, index) => (
                                  <option key={index} value={department.id}>
                                    {department.ticket_department_name}
                                  </option>
                                ))}
                              </Select>
                            }
                            name="department"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.department?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                      <Col lg="6" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.priority}
                          className="mb-3"
                        >
                          <InputLabel>Priority</InputLabel>
                          <Controller
                            as={
                              <Select native label="Priority">
                                {priorities.map((priority, index) => (
                                  <option key={index} value={priority.value}>
                                    {priority.label}
                                  </option>
                                ))}
                              </Select>
                            }
                            name="priority"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.priority?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.message}
                          className="mb-3"
                        >
                          <label>Message</label>
                          <Controller
                            name="message"
                            control={control}
                            render={(props) => {
                              return (
                                <ReactQuill
                                  value={props.value}
                                  theme="snow"
                                  onChange={(
                                    content,
                                    delta,
                                    source,
                                    editor
                                  ) => {
                                    props.onChange(
                                      editor.getLength() > 1 ? content : ''
                                    );
                                  }}
                                />
                              );
                            }}
                          />
                          <FormHelperText>
                            {errors.message?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12">
                        <label>Attach Files</label>
                        <FileDropzone
                          className="mb-3"
                          files={files}
                          onDrop={handleDrop}
                          onRemove={handleRemove}
                          onRemoveAll={handleRemoveAll}
                          fileImg={addFileImg}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" xs="12" className="text-right">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isAddUpdateTicketPending}
                        >
                          Create Ticket
                        </Button>
                        <Button
                          className="ml-2"
                          type="button"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("list");
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    isFetchCustomerPending: state.customer.isFetchCustomerPending,
    customers: state.customer.customers,
  };
};

export default connect(mapStateToProps, null)(OneTicket);
