import {
    FETCH_PERMISSION_SUCCESS,
    FETCH_PERMISSION_PENDING,
    FETCH_PERMISSION_ERROR,
    FETCH_PERMISSION,
    FETCH_PERMISSION_COUNT,
    ADD_UPDATE_PERMISSION_ERROR,
    FETCH_ONE_PERMISSION,
    FETCH_ONE_PERMISSION_PENDING,
    ADD_UPDATE_PERMISSION_PENDING,
    ADD_UPDATE_PERMISSION_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchPermissionSuccess: false,
    isFetchPermissionPending: false,
    fetchPermissionError: null,
    addUpdatePermissionError: null,
    permissionData: [],
    isPermissionDataPending: false,
    permissions: null,
    noOfRecords: 0,
    isAddUpdatePermissionPending: false,
    isAddUpdatePermissionSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_PERMISSION:
            return {
                ...state,
                permissions: action.payload
            };

        case FETCH_PERMISSION_SUCCESS:
            return {
                ...state,
                isFetchPermissionSuccess: action.payload
            };

        case FETCH_PERMISSION_PENDING:
            return {
                ...state,
                isFetchPermissionPending: action.payload
            };

        case FETCH_PERMISSION_ERROR:
            return {
                ...state,
                fetchPermissionError: action.payload
            };

        case FETCH_PERMISSION_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_PERMISSION:
            return {
                ...state,
                permissionData: action.payload
            };

        case ADD_UPDATE_PERMISSION_ERROR:
            return {
                ...state,
                addUpdatePermissionError: action.payload
            };

        case FETCH_ONE_PERMISSION_PENDING:
            return {
                ...state,
                isPermissionDataPending: action.payload
            };

        case ADD_UPDATE_PERMISSION_PENDING:
            return {
                ...state,
                isAddUpdatePermissionPending: action.payload
            };

        case ADD_UPDATE_PERMISSION_SUCCESS:
            return {
                ...state,
                isAddUpdatePermissionSuccess: action.payload
            };

        default:
            return state;
    }
}