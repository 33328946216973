import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_CUSTOMER_SUCCESS,
    FETCH_CUSTOMER_PENDING,
    FETCH_CUSTOMER_ERROR,
    FETCH_CUSTOMER,
    FETCH_CUSTOMER_COUNT,
    ADD_UPDATE_CUSTOMER_ERROR,
    ADD_UPDATE_CUSTOMER_PENDING,
    ADD_UPDATE_CUSTOMER_SUCCESS,
    FETCH_ONE_CUSTOMER,
    FETCH_ONE_CUSTOMER_PENDING,
} from './types';

const setFetchCustomerSuccess = isFetchCustomerSuccess => dispatch => {
    dispatch({
        type: FETCH_CUSTOMER_SUCCESS,
        payload: isFetchCustomerSuccess
    });
}

const setFetchCustomerPending = isFetchCustomerPending => dispatch => {
    dispatch({
        type: FETCH_CUSTOMER_PENDING,
        payload: isFetchCustomerPending
    });
}

const setAddUpdateCustomerPending = isAddUpdateCustomerPending => dispatch => {
    dispatch({
        type: ADD_UPDATE_CUSTOMER_PENDING,
        payload: isAddUpdateCustomerPending
    });
}

const setFetchOneCustomerPending = isCustomerDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_CUSTOMER_PENDING,
        payload: isCustomerDataPending
    });
}

export const setAddUpdateCustomerError = addUpdateCustomerError => dispatch => {
    dispatch({
        type: ADD_UPDATE_CUSTOMER_ERROR,
        payload: addUpdateCustomerError
    });
}

export const setAddUpdateCustomerSuccess = isAddUpdateCustomerSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_CUSTOMER_SUCCESS,
        payload: isAddUpdateCustomerSuccess
    });
}

export const setFetchCustomerError = fetchCustomerError => dispatch => {
    dispatch({
        type: FETCH_CUSTOMER_ERROR,
        payload: fetchCustomerError
    });
}

export const fetchCustomers = (pageNo = 1, perPage = 10, keyword = "", order = "", desc = false) => async dispatch => {
    dispatch(setFetchCustomerPending(true));
    
    dispatch({
        type: FETCH_CUSTOMER_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_CUSTOMER,
        payload: null
    });

    instance.post(`customers/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchCustomerSuccess(true));
            dispatch({
                type: FETCH_CUSTOMER_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_CUSTOMER,
                payload: response.data.data.items
            });
            dispatch(setFetchCustomerPending(false));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchCustomerError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
            dispatch(setFetchCustomerPending(false));
        });

}

export const fetchAllCustomers = () => async dispatch => {

    dispatch(setFetchCustomerPending(true));
    dispatch({
        type: FETCH_CUSTOMER_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_CUSTOMER,
        payload: []
    });

    instance.post(`customers/get`, {})
        .then(response => {
            dispatch(setFetchCustomerSuccess(true));
            dispatch({
                type: FETCH_CUSTOMER_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_CUSTOMER,
                payload: response.data.data
            });
            dispatch(setFetchCustomerPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchCustomerError(err.response.data.data));
            dispatch(setFetchCustomerPending(false));
        });

}

export const addCustomer = (postData) => async dispatch => {
    dispatch(setAddUpdateCustomerSuccess(false));
    dispatch(setAddUpdateCustomerPending(true));
    instance.post('customers/create', postData)
        .then(response => {
            CustomSnackbar('success', 'Customer added successfully');
            dispatch(setAddUpdateCustomerSuccess(true));
            history.replace('/crm/customers/list');
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateCustomerError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateCustomerPending(false));
        });

}

export const updateCustomer = (id, postData, currentCustomer) => async dispatch => {

    dispatch(setAddUpdateCustomerSuccess(false));
    dispatch(setAddUpdateCustomerPending(true));
    dispatch(setFetchOneCustomerPending(true));

    instance.post(`customers/update/${id}`, postData)
        .then(response => {
            dispatch({
                type: FETCH_ONE_CUSTOMER,
                payload: response.data.data
            });
            CustomSnackbar('success', 'Customer updated successfully');
            dispatch(setAddUpdateCustomerSuccess(true));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateCustomerError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateCustomerPending(false));
            dispatch(setFetchOneCustomerPending(false));
        });

}

export const fetchCustomer = (id) => async dispatch => {

    dispatch(setFetchOneCustomerPending(true));

    instance.post(`customers/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_CUSTOMER,
                payload: response.data.data
            });
            dispatch(setFetchOneCustomerPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneCustomerPending(false));
        });
}

export const deleteCustomers = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('customers/delete', postData)
        .then(response => {
            dispatch(fetchCustomers(pageNo, perPage, keyword));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchCustomerError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}
