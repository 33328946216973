import React from 'react'
import {
    Row,
    Col,
    Divider,
    Box,
    Tabs,
    Tab,
} from '@mycentyr/centyr-ui';
import { connect } from 'react-redux';

import UserForm from './UserForm';
import PermissionTable from 'app/manage/role/views/PermissionTable';
import UserPassword from './UserPassword';

import { appName } from 'utils/axios'

function OneUser(props) {
    const { id } = props.match.params;

    if (id !== "add")
        document.title = "Update User" + appName;
    else
        document.title = "Create New User" + appName;

    const [value, setValue] = React.useState('general');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <Row>
                    <Col className="page-title">
                        <h1 className='page-title'>{id === "add" ? 'Create New User' : `Update User (${props.userData.user_display_name})`}</h1>
                    </Col>
                </Row>
            </div>
            <div className="content-wrapper">
                {(id === 'add') ?
                    <UserForm id={id} />
                    :
                    <React.Fragment>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="full width tabs example"
                        >
                            <Tab value={'general'} label="General" />
                            <Tab value={'security'} label="Security" />
                            <Tab value={'permission'} label="Permissions" />
                        </Tabs>
                        <Divider />
                        <Box
                            py={3}
                            pb={6}
                        >
                            {value === 'general' && <UserForm id={id} />}
                            {value === 'security' && <UserPassword />}
                            {value === 'permission' && <PermissionTable allowedPermissions={props.userData.permissions} id={id} type="user" />}
                        </Box>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.user.userData,
    };
};
export default connect(mapStateToProps, null)(OneUser);