import {
    FETCH_APPLICATION_SUCCESS,
    FETCH_APPLICATION_PENDING,
    FETCH_APPLICATION_ERROR,
    FETCH_APPLICATION,
    FETCH_APPLICATION_COUNT,
    ADD_UPDATE_APPLICATION_ERROR,
    FETCH_ONE_APPLICATION,
    FETCH_ONE_APPLICATION_PENDING,
    ADD_UPDATE_APPLICATION_PENDING,
    ADD_UPDATE_APPLICATION_SUCCESS,
} from '../actions/types';

const initialState = {
    isFetchApplicationSuccess: false,
    isFetchApplicationPending: false,
    fetchApplicationError: null,
    addUpdateApplicationError: null,
    applicationData: [],
    isApplicationDataPending: false,
    applications: [],
    noOfRecords: 0,
    isAddUpdateApplicationPending: false,
    isAddUpdateApplicationSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_APPLICATION:
            return {
                ...state,
                applications: action.payload
            };

        case FETCH_APPLICATION_SUCCESS:
            return {
                ...state,
                isFetchApplicationSuccess: action.payload
            };

        case FETCH_APPLICATION_PENDING:
            return {
                ...state,
                isFetchApplicationPending: action.payload
            };

        case FETCH_APPLICATION_ERROR:
            return {
                ...state,
                fetchApplicationError: action.payload
            };

        case FETCH_APPLICATION_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_APPLICATION:
            return {
                ...state,
                applicationData: action.payload
            };

        case ADD_UPDATE_APPLICATION_ERROR:
            return {
                ...state,
                addUpdateApplicationError: action.payload
            };

        case FETCH_ONE_APPLICATION_PENDING:
            return {
                ...state,
                isApplicationDataPending: action.payload
            };

        case ADD_UPDATE_APPLICATION_PENDING:
            return {
                ...state,
                isAddUpdateApplicationPending: action.payload
            };

        case ADD_UPDATE_APPLICATION_SUCCESS:
            return {
                ...state,
                isAddUpdateApplicationSuccess: action.payload
            };

        default:
            return state;
    }
}