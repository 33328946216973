import React from 'react'

export default function NoProagation(props) {

    const stop = (e) => {
        e.stopPropagation();
    }

    return (
        <div
            onClick={stop}
            onContextMenu={stop}
            onDoubleClick={stop}
            onDrag={stop}
            onDragEnd={stop}
            onDragEnter={stop}
            onDragExit={stop}
            onDragLeave={stop}
            onDragOver={stop}
            onDragStart={stop}
            onDrop={stop}
            onMouseDown={stop}
            onMouseEnter={stop}
            onMouseLeave={stop}
            onMouseMove={stop}
            onMouseOver={stop}
            onMouseOut={stop}
            onMouseUp={stop}

            onKeyDown={stop}
            onKeyPress={stop}
            onKeyUp={stop}

            onFocus={stop}
            onBlur={stop}

            onChange={stop}
            onInput={stop}
            onInvalid={stop}
            onSubmit={stop}
        >
            {props.children}
        </div>
    )
}
