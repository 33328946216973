import { instance } from 'utils/axios'
import { history } from 'helpers/history';
import { CustomSnackbar } from '@mycentyr/centyr-ui'

import {
    FETCH_EMAILPROVIDER_SUCCESS,
    FETCH_EMAILPROVIDER_PENDING,
    FETCH_EMAILPROVIDER_ERROR,
    FETCH_EMAILPROVIDER,
    FETCH_EMAILPROVIDER_COUNT,
    ADD_UPDATE_EMAILPROVIDER_ERROR,
    ADD_UPDATE_EMAILPROVIDER_PENDING,
    ADD_UPDATE_EMAILPROVIDER_SUCCESS,
    FETCH_ONE_EMAILPROVIDER,
    FETCH_ONE_EMAILPROVIDER_PENDING,

    TEST_EMAIL_PENDING,
    TEST_EMAIL_SUCCESS,
    TEST_EMAIL_ERROR,
    TEST_EMAIL_RESPONSE,
} from './types';

const setFetchEmailProviderSuccess = isFetchEmailProviderSuccess => dispatch => {
    dispatch({
        type: FETCH_EMAILPROVIDER_SUCCESS,
        payload: isFetchEmailProviderSuccess
    });
}

const setFetchEmailProviderPending = isFetchEmailProviderPending => dispatch => {
    dispatch({
        type: FETCH_EMAILPROVIDER_PENDING,
        payload: isFetchEmailProviderPending
    });
}

const setAddUpdateEmailProviderPending = isAddUpdateEmailProviderPending => dispatch => {
    dispatch({
        type: ADD_UPDATE_EMAILPROVIDER_PENDING,
        payload: isAddUpdateEmailProviderPending
    });
}

const setFetchOneEmailProviderPending = isEmailProviderDataPending => dispatch => {
    dispatch({
        type: FETCH_ONE_EMAILPROVIDER_PENDING,
        payload: isEmailProviderDataPending
    });
}

export const setAddUpdateEmailProviderError = addUpdateEmailProviderError => dispatch => {
    dispatch({
        type: ADD_UPDATE_EMAILPROVIDER_ERROR,
        payload: addUpdateEmailProviderError
    });
}

export const setAddUpdateEmailProviderSuccess = isAddUpdateEmailProviderSuccess => dispatch => {
    dispatch({
        type: ADD_UPDATE_EMAILPROVIDER_SUCCESS,
        payload: isAddUpdateEmailProviderSuccess
    });
}

export const setFetchEmailProviderError = fetchEmailProviderError => dispatch => {
    dispatch({
        type: FETCH_EMAILPROVIDER_ERROR,
        payload: fetchEmailProviderError
    });
}

export const setTestEmailSuccess = testEmailSuccess => dispatch => {
    dispatch({
        type: TEST_EMAIL_SUCCESS,
        payload: testEmailSuccess
    });
}

export const setTestEmailPending = testEmailPending => dispatch => {
    dispatch({
        type: TEST_EMAIL_PENDING,
        payload: testEmailPending
    });
}

export const setTestEmailError = testEmailError => dispatch => {
    dispatch({
        type: TEST_EMAIL_ERROR,
        payload: testEmailError
    });
}

export const fetchAllEmailProviders = () => async dispatch => {

    dispatch(setFetchEmailProviderPending(true));
    dispatch({
        type: FETCH_EMAILPROVIDER_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_EMAILPROVIDER,
        payload: null
    });

    instance.post(`emailproviders/get`, {})
        .then(response => {
            dispatch(setFetchEmailProviderSuccess(true));
            dispatch({
                type: FETCH_EMAILPROVIDER_COUNT,
                payload: response.data.data.length
            });
            dispatch({
                type: FETCH_EMAILPROVIDER,
                payload: response.data.data
            });
            dispatch(setFetchEmailProviderPending(false));
        })
        .catch(err => {
            err.response && dispatch(setFetchEmailProviderError(err.response.data.data));
            dispatch(setFetchEmailProviderPending(false));
        });

}


export const fetchEmailProviders = (pageNo = 1, perPage = 10, keyword = "", order = "", desc = false) => async dispatch => {

    dispatch(setFetchEmailProviderPending(true));
    dispatch({
        type: FETCH_EMAILPROVIDER_COUNT,
        payload: -1
    });
    dispatch({
        type: FETCH_EMAILPROVIDER,
        payload: null
    });

    instance.post(`emailproviders/getAll/${pageNo}/${perPage}/search=${keyword}&order=${order}${desc ? " desc" : ""}`, {})
        .then(response => {
            dispatch(setFetchEmailProviderSuccess(true));
            dispatch({
                type: FETCH_EMAILPROVIDER_COUNT,
                payload: parseInt(response.data.data.count)
            });
            dispatch({
                type: FETCH_EMAILPROVIDER,
                payload: response.data.data.items
            });
            dispatch(setFetchEmailProviderPending(false));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchEmailProviderError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
            dispatch(setFetchEmailProviderPending(false));
        });

}

export const addEmailProvider = (postData, toggle) => async dispatch => {
    dispatch(setAddUpdateEmailProviderSuccess(false));
    dispatch(setAddUpdateEmailProviderPending(true));
    instance.post('emailproviders/create', postData)
        .then(response => {
            CustomSnackbar('success', 'EmailProvider added successfully');
            dispatch(setAddUpdateEmailProviderSuccess(true));
            toggle();
            history.push('/manage/email/emailproviders/' + response.data.data.id);
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateEmailProviderError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateEmailProviderPending(false));
        });

}

export const updateEmailProvider = (id, postData) => async dispatch => {

    dispatch(setAddUpdateEmailProviderSuccess(false));
    dispatch(setAddUpdateEmailProviderPending(true));
    dispatch(setFetchOneEmailProviderPending(true));
    dispatch({
        type: TEST_EMAIL_RESPONSE,
        payload: null
    });

    instance.post(`emailproviders/update/${id}`, postData)
        .then(response => {
            dispatch({
                type: FETCH_ONE_EMAILPROVIDER,
                payload: response.data.data
            });
            CustomSnackbar('success', 'EmailProvider updated successfully');
            dispatch(setAddUpdateEmailProviderSuccess(true));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setAddUpdateEmailProviderError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setAddUpdateEmailProviderPending(false));
            dispatch(setFetchOneEmailProviderPending(false));
        });

}

export const fetchEmailProvider = (id) => async dispatch => {

    dispatch(setFetchOneEmailProviderPending(true));
    dispatch({
        type: TEST_EMAIL_RESPONSE,
        payload: null
    });

    instance.post(`emailproviders/get/${id}`, {})
        .then(response => {
            if (!response.data.data) {
                history.push("/not-found")
            }
            dispatch({
                type: FETCH_ONE_EMAILPROVIDER,
                payload: response.data.data
            });
            dispatch(setFetchOneEmailProviderPending(false));
        })
        .catch(err => {
            dispatch(setFetchOneEmailProviderPending(false));
        });
}

export const deleteEmailProviders = (postData, pageNo, perPage, keyword = "") => async dispatch => {

    instance.post('emailproviders/delete', postData)
        .then(response => {
            dispatch(fetchEmailProviders(pageNo, perPage, keyword));
            CustomSnackbar('success', response.data.message);
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchEmailProviderError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        });
}

export const testEmail = (id, postData) => async dispatch => {

    dispatch(setTestEmailSuccess(false));
    dispatch(setTestEmailPending(true));
    dispatch({
        type: TEST_EMAIL_RESPONSE,
        payload: null
    });

    instance.post(`emailproviders/testemail/${id}`, postData)
        .then(response => {
            dispatch({
                type: TEST_EMAIL_RESPONSE,
                payload: response.data.data
            });
            dispatch(setTestEmailSuccess(true));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setTestEmailError(err.response.data.data));
                CustomSnackbar('error', err.response.data.data);
            }
        })
        .finally(() => {
            dispatch(setTestEmailPending(false));
        });

}