import {
    FETCH_USER_DETAIL,
    FETCH_USER_PENDING
} from '../actions/types';

const initialState = {
    userData: [],
    isFetchUserPending: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_DETAIL:
            return {
                ...state,
                userData: action.payload
            };
        case FETCH_USER_PENDING:
            return {
                ...state,
                isFetchUserPending: action.payload
            };

        default:
            return state;
    }
}

