import { combineReducers } from "redux";
import { mainAuthReducer } from "app/auth/reducers/";

import { mainSetupReducer } from "app/setup/reducer";

import { mainManageReducer } from "app/manage/reducer";

import { mainCustomerReducer } from "app/crm/customer/reducers";

import { mainTicketReducer } from "app/helpdesk/ticket/slices";
import { mainDepartmentReducer } from "app/helpdesk/department/slices";
import { mainAddressReducer } from "app/crm/address/slices";

import { mainWSAReducer } from "app/wsa/reducer";

const reducers = combineReducers({
  ...mainSetupReducer,
  ...mainAuthReducer,
  ...mainManageReducer,
  ...mainCustomerReducer,
  ...mainTicketReducer,
  ...mainDepartmentReducer,
  ...mainAddressReducer,

  ...mainWSAReducer,
});

export default reducers;
