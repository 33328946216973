//Import Components for Manage Routes
import PermissionList from "app/setup/permission/views/PermissionList";
import ApplicationList from "app/setup/application/views/ApplicationList";
import ApplicationgroupList from "app/setup/group/views/GroupList";
import TemplateTagList from "app/setup/templatetag/views/TemplateTagList";

//Import icons for Manage Nav Section
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import TagIcon from "@material-ui/icons/LocalOffer";

const setuproutes = [
  {
    path: "/setup",
    name: "Setup",
    title: "Setup",
    id: "setup",
    icon: LanguageIcon,
    permission: [
      "list.application.groups",
      "list.permissions",
      "add.permissions",
    ],
    child: [
      {
        collapse: true,
        name: "Apps & Permissions",
        icon: LockIcon,
        state: "Permissions",
        permission: ["list.permissions", "add.permissions"],
        subchild: [
          {
            path: "/setup/permissions/list",
            title: "Manage Permissions",
            name: "All Permissions",
            component: PermissionList,
            permission: "list.permissions",
          },
          {
            path: "/setup/applications/groups/list",
            title: "Manage Application Groups",
            name: "Application Groups",
            component: ApplicationgroupList,
            permission: "list.application.groups",
          },
          {
            path: "/setup/applications/list",
            title: "Manage Application",
            name: "All Applications",
            component: ApplicationList,
            permission: "list.applications",
          },
        ],
      },
      {
        collapse: true,
        name: "Template Tags",
        icon: TagIcon,
        state: "TemplateTags",
        permission: ["list.template.tags", "add.template.tags"],
        subchild: [
          {
            path: "/setup/templatetags/list",
            title: "Manage Template Tags",
            name: "All Template Tags",
            component: TemplateTagList,
            permission: "list.template.tags",
          },
        ],
      },
    ],
  },
];

export default setuproutes;
