import { createStore, applyMiddleware, compose } from 'redux';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';

// const logger = createLogger();

export function configureStore(initialState) {
  const middlewares = [thunk];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();
