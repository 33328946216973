import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'redux/store';
import {
  fetchConsolidatePackages,
  deletePackages,
} from '../slices/packageSlice';
import PackageActionButtons from './PackageActionButtons';
import { history } from 'helpers/history';
import PackageSkeleeton from './PackageSkeleeton';

import { Row, Col, MuiTable, Button } from '@mycentyr/centyr-ui';

const ConsolidatePackageList = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [rows, setRows] = useState([]);

  const { packages, noOfRecords, isFetchPackagesPending } = useSelector(
    (state) => state.package
  );

  const headCells = [
    {
      id: 'package_id',
      numeric: false,
      disablePadding: false,
      label: 'Package ID',
    },
    {
      id: 'package_owner',
      numeric: false,
      disablePadding: false,
      label: 'Package Owner',
    },
    {
      id: 'package_type',
      numeric: false,
      disablePadding: false,
      label: 'Package Type',
    },
    {
      id: 'package_inward_tracking_number',
      numeric: false,
      disablePadding: false,
      label: 'Inward Tracking Number',
    },
    {
      id: 'package_status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
  ];

  const createData = (
    id,
    package_id,
    package_owner,
    package_type,
    package_inward_tracking_number,
    package_status
  ) => {
    return {
      id,
      package_id,
      package_owner,
      package_type,
      package_inward_tracking_number,
      package_status,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(fetchConsolidatePackages(1, rowsPerPage, keyword));
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(fetchConsolidatePackages(pageNo, perPage, keyword));
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(deletePackages(postData, pageNo, perPage, keyword));
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(fetchConsolidatePackages(1, perPage, keyword));
  };

  useEffect(() => {
    let rowsLcl = [];
    packages.map((pkg) =>
      rowsLcl.push(
        createData(
          pkg.id,
          pkg.package_id,
          pkg.package_owner,
          pkg.package_type,
          pkg.package_inward_tracking_number,
          pkg.package_status
        )
      )
    );
    setRows(rowsLcl);
  }, [packages]);

  useEffect(() => {
    dispatch(fetchConsolidatePackages());
  }, []);

  return (
    <div>
      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1>{props.title}</h1>
          </Col>
          <Col className='text-right page-header-button'>
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes('add.wsa.packages') && (
                <Button
                  color='primary'
                  className='mb-3'
                  onClick={() => {
                    history.push('add');
                  }}
                >
                  Add Package
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchPackagesPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes('delete.wsa.packages')
              ? true
              : false
          }
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={PackageSkeleeton}
          Actions={PackageActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(ConsolidatePackageList);
