import React, { useEffect } from 'react'
import { appName } from 'utils/axios'

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { connect } from 'react-redux';

import { addPermission, fetchPermission, setAddUpdatePermissionError, updatePermission, setAddUpdatePermissionSuccess } from '../actions/permissionActions';
import { fetchAllGroups } from 'app/setup/group/actions/groupActions'
import { fetchAllApplications } from 'app/setup/application/actions/applicationActions'


import {
    Row,
    Col,
    InputLabel,
    CustomSpinner,
    TextField,
    Select,
    FormControl,
    FormHelperText,
    Button,
    OutlinedInput
} from '@mycentyr/centyr-ui';

//eslint-disable-next-line
var regEx = /[a-z\-\_]$/
const schema = yup.object().shape({
    application_id: yup.string().required('App is required'),
    application_group_id: yup.string().required('Group is required'),
    permission_slug: yup.string().matches(regEx, 'Slug is invalid').required('Slug is required'),
    permission_name: yup.string().required('Name is required'),
});

const initialValue = {
    application_id: '',
    application_group_id: '',
    permission_name: "",
    permission_slug: "",
    permission_description: "",
}

const OnePermission = (props) => {
    const id = props.id;

    const appLabel = React.useRef(null);
    const groupLabel = React.useRef(null);
    const [appLabelWidth, setAppLabelWidth] = React.useState(0);
    const [groupLabelWidth, setGroupLabelWidth] = React.useState(0);

    useEffect(() => {
        setAppLabelWidth(appLabel.current.offsetWidth);
        setGroupLabelWidth(groupLabel.current.offsetWidth);
    }, []);

    if (id !== "add")
        document.title = "Edit Permission" + appName;
    else
        document.title = "Add Permission" + appName;

    useEffect(() => {
        props.fetchAllGroups();
        props.fetchAllApplications();

        if (props.groups && id !== "add") {
            props.fetchPermission(id);
        }
    }, [id]);

    useEffect(() => {
        reset(id === "add" ?
            initialValue : {
                application_id: props.permissionData.application_id || '',
                application_group_id: props.permissionData.application_group_id || '',
                permission_name: props.permissionData.permission_name || '',
                permission_slug: props.permissionData.permission_slug || '',
                permission_description: props.permissionData.permission_description || '',
            })
    }, [props.applications, props.groups, props.permissionData, id]);

    const { register, handleSubmit, errors, setValue, reset } = useForm({
        mode: "onblur",
        resolver: yupResolver(schema),
    });

    const onSubmit = data => {
        const postData = {
            application_id: data.application_id,
            application_group_id: data.application_group_id,
            permission_name: data.permission_name,
            permission_slug: data.permission_slug,
            permission_description: data.permission_description,
        };
        id === "add" ? props.addPermission(postData, props.toggle) : props.updatePermission(id, postData, props.toggle);
    };

    if (!props.isAddUpdatePermissionPending && props.isPermissionDataPending)
        return <CustomSpinner />
    else
        return (
            <React.Fragment>
                {props.isPermissionDataPending && <CustomSpinner />}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col lg="12" xs="12">
                            <FormControl
                                variant="outlined"
                                size="small"
                                error={errors.application_id?.message ? true : false}
                                fullWidth
                                className="mb-3"
                            >
                                <InputLabel
                                    ref={appLabel}
                                    htmlFor="application_id"
                                >
                                    App
                                </InputLabel>
                                <Select
                                    native
                                    inputRef={register}
                                    input={
                                        <OutlinedInput
                                            labelWidth={appLabelWidth}
                                            name="application_id"
                                            id="application_id"
                                        />
                                    }
                                >
                                    <option aria-label="None" value="" />
                                    {props.applications && props.applications.map((application) =>
                                        <option value={application.id} key={application.id}>{application.application_name}</option>
                                    )
                                    }
                                </Select>
                                <FormHelperText>{errors.application_id?.message}</FormHelperText>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <FormControl
                                variant="outlined"
                                size="small"
                                error={errors.application_group_id?.message ? true : false}
                                fullWidth
                                className="mb-3"
                            >
                                <InputLabel
                                    ref={groupLabel}
                                    htmlFor="application_group_id"
                                >
                                    Group
                                </InputLabel>
                                <Select
                                    native
                                    inputRef={register}
                                    input={
                                        <OutlinedInput
                                            labelWidth={groupLabelWidth}
                                            name="application_group_id"
                                            id="application_group_id"
                                        />
                                    }
                                >
                                    <option aria-label="None" value="" />
                                    {props.groups && props.groups.map((group) =>
                                        <option value={group.id} key={group.id}>{group.application_group_name}</option>
                                    )
                                    }
                                </Select>
                                <FormHelperText>{errors.application_group_id?.message}</FormHelperText>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Permission Name"
                                name="permission_name"
                                id="permission_name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.permission_name?.message ? true : false}
                                helperText={errors.permission_name?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Permission"
                                name="permission_slug"
                                id="permission_slug"
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.permission_slug?.message ? true : false}
                                helperText={errors.permission_slug?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12">
                            <TextField
                                className="mb-3"
                                label="Permission Description"
                                name="permission_description"
                                id="permission_description"
                                variant="outlined"
                                size="small"
                                multiline
                                rows="2"
                                fullWidth
                                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                                error={errors.permission_description?.message ? true : false}
                                helperText={errors.permission_description?.message}
                                inputRef={register}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" xs="12" className="text-right">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={props.isAddUpdatePermissionPending}
                            >
                                {id === "add" ? "Create" : "Update"}
                            </Button>
                            <Button
                                className="ml-2"
                                type="button"
                                color="secondary"
                                onClick={props.toggle}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </form>
            </React.Fragment>
        )
}

const mapStateToProps = (state) => {
    return {
        permissionData: state.permission.permissionData,
        isAddUpdatePermissionPending: state.permission.isAddUpdatePermissionPending,
        addUpdatePermissionError: state.permission.addUpdatePermissionError,
        isPermissionDataPending: state.permission.isPermissionDataPending,
        isAddUpdatePermissionSuccess: state.permission.isAddUpdatePermissionSuccess,
        groups: state.applicationgroup.groups,
        applications: state.application.applications,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addPermission: (postData, toggle) => { dispatch(addPermission(postData, toggle)) },
        fetchPermission: (id) => { dispatch(fetchPermission(id)) },
        setAddUpdatePermissionError: (error) => { dispatch(setAddUpdatePermissionError(error)) },
        updatePermission: (id, postData, toggle) => { dispatch(updatePermission(id, postData, toggle)) },
        setAddUpdatePermissionSuccess: (isAddUpdatePermissionSuccess) => { dispatch(setAddUpdatePermissionSuccess(isAddUpdatePermissionSuccess)) },
        fetchAllGroups: () => { dispatch(fetchAllGroups()) },
        fetchAllApplications: () => { dispatch(fetchAllApplications()) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnePermission);