import {
    FETCH_COUNTRY_SUCCESS,
    FETCH_COUNTRY_PENDING,
    FETCH_COUNTRY_ERROR,
    FETCH_COUNTRY,
    FETCH_COUNTRY_COUNT,
} from '../actions/types';

const initialState = {
    isFetchCountrySuccess: false,
    isFetchCountryPending: false,
    fetchCountryError: null,
    countries: null,
    noOfRecords: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
    
        case FETCH_COUNTRY:
            return {
                ...state,
                countries: action.payload
            };

        case FETCH_COUNTRY_SUCCESS:
            return {
                ...state,
                isFetchCountrySuccess: action.payload
            };

        case FETCH_COUNTRY_PENDING:
            return {
                ...state,
                isFetchCountryPending: action.payload
            };

        case FETCH_COUNTRY_ERROR:
            return {
                ...state,
                fetchCountryError: action.payload
            };

        case FETCH_COUNTRY_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        default:
            return state;
    }
}