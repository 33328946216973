import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default function CustomModal(props) {
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>{props.modalTitle}</ModalHeader>
      <ModalBody>
        {props.modalBody}
        {props.modalBody1 && <br />}
        {props.modalBody1}
      </ModalBody>
      <ModalFooter>
        {props.onModalAccept && (
          <Button color='primary' onClick={props.onModalAccept}>
            {props.successButtonText || 'Yes'}
          </Button>
        )}{' '}
        {props.onModalCancel && (
          <Button color='secondary' onClick={props.onModalCancel}>
            Cancel
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
