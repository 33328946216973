import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Prompt } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { history } from 'helpers/history';
import { isObjectEmpty } from 'utils/fun';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'redux/store';
import {
  fetchTicket,
  updateTicket,
  addReply,
  updateReply,
  deleteReply,
} from '../slices/ticketSlice';
import { fetchAllDepartments } from 'app/helpdesk/department/slices/departmentSlice';
import { fetchCustomer } from 'app/crm/customer/actions/customerActions';
import { fetchAllUsers } from 'app/manage/user/actions/userActions';
import moment from 'moment';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab/';

import { styled } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import {
  Row,
  Col,
  CardTitle,
  CustomSpinner,
  TextField,
  Button,
  Card,
  CardBody,
  Avatar,
  Box,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@mycentyr/centyr-ui';

import { FileDropzone } from '@mycentyr/centyr-ui';
import ReactQuill from 'react-quill';

import DOMPurify from 'dompurify';

import addFileImg from 'assets/images/undraw_add_file2_gvbb.svg';

const MuiTimelineItem = styled(TimelineItem)({
  '&:before': {
    display: 'none',
  },
});

const schemaTicket = yup.object().shape({
  department: yup.string().required('Department is required'),
  status: yup.string().required('Status is required'),
});

const schemaReply = yup.object().shape({
  reply: yup.string().required('Department is required'),
});

const initialValueTicket = {
  department: '',
  assigned: '',
  status: 'open',
  note: '',
};

const initialValueReply = {
  reply: '',
};

const statuses = [
  { value: 'open', label: 'Open' },
  { value: 'hold', label: 'On Hold' },
  { value: 'escalated', label: 'Escalated' },
  { value: 'closed', label: 'Closed' },
];

const TicketDetails = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const { id } = params;
  const {
    authUserData,
    isCustomerDataPending,
    customerData,
    isFetchUserPending,
    users,
  } = props;
  const { ticket, isFetchTicketPending } = useSelector((state) => state.ticket);
  const { departments, isFetchDepartmentsPending } = useSelector(
    (state) => state.department
  );

  // const ref = useRef(null);
  const [replyType, setReplyType] = useState('customer');
  const [uploadModal, setUploadModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editableContent, setEditableContent] = useState('');
  const [editableContentExtras, setEditableContentExtras] = useState({});

  useEffect(() => {
    dispatch(fetchTicket(id));
    dispatch(fetchAllDepartments());
    dispatch(fetchAllUsers());
  }, []);

  // useEffect(() => {
  //   if (ref.current?.editor) {
  //     ref.current.editor.getBody().style.backgroundColor =
  //       replyType === 'customer' ? '#FFFFFF' : '#FFF6D9';
  //   }
  // }, [replyType]);

  useEffect(() => {
    if (!isObjectEmpty(ticket)) dispatch(fetchCustomer(ticket.customer_id));
  }, [ticket.id]);

  useEffect(() => {
    if (departments?.length > 0 && ticket) {
      resetTicket({
        department: ticket.department_id,
        assigned: ticket.ticket_assigned,
        status: ticket.ticket_status,
        note: ticket.ticket_notes,
      });
    }
  }, [departments, ticket]);

  const {
    handleSubmit: handleSubmitTicket,
    errors: errorsTicket,
    reset: resetTicket,
    formState: formStateTicket,
    control: controlTicket,
  } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schemaTicket),
    defaultValues: initialValueTicket,
  });
  const { dirtyFields: dirtyFieldsTicket } = formStateTicket;
  const isDirtyAltTicket = !!Object.keys(dirtyFieldsTicket).length;

  const onSubmitTicket = (data) => {
    const postData = {
      customer_id: ticket.customer_id,
      ticket_subject: ticket.ticket_subject,
      department_id: data.department,
      ticket_priority: ticket.ticket_priority,
      ticket_status: data.status,
      ticket_notes: data.note,
      ticket_assigned: data.assigned,
      ticket_content: ticket.ticket_content,
    };
    dispatch(updateTicket(id, postData));
  };

  const {
    handleSubmit: handleSubmitReply,
    errors: errorsReply,
    reset: resetReply,
    formState: formStateReply,
    control: controlReply,
  } = useForm({
    mode: 'onblur',
    resolver: yupResolver(schemaReply),
    defaultValues: initialValueReply,
  });
  const { dirtyFields: dirtyFieldsReply } = formStateReply;
  const isDirtyAltReply = !!Object.keys(dirtyFieldsReply).length;

  const onSubmitReply = (data) => {
    const postData = new FormData();
    postData.append('ticket_reply_content', data.reply);
    files.map((file, index) =>
      postData.append(`ticket_reply_attachment${index}`, file)
    );
    postData.append('ticket_id', ticket.id);
    postData.append('is_internal', replyType === 'internal' ? 1 : 0);
    dispatch(addReply(ticket.id, postData));
  };

  const handleDrop = (newFiles, rejectedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_file) => _file.path !== file.path)
    );
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const toggleUploadModal = () => setUploadModal(!uploadModal);
  const toggleEditModal = () => setEditModal(!editModal);

  if (
    isFetchUserPending ||
    isFetchTicketPending ||
    isCustomerDataPending ||
    isFetchDepartmentsPending
  )
    return <CustomSpinner />;

  return (
    <React.Fragment>
      <Prompt
        when={isDirtyAltTicket || isDirtyAltReply}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {/* <div>{isFetchTicketPending && <CustomSpinner />}</div> */}

      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1>{ticket.ticket_subject}</h1>
          </Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        <Row>
          <Col md='4' xs='12'>
            <Card>
              <CardBody className=''>
                <Row className='mb-3'>
                  <Col xs='6'>
                    Priority: {ticket.ticket_priority?.toUpperCase()}
                  </Col>
                  <Col xs='6'>Status: {ticket.ticket_status}</Col>
                </Row>
                <Divider />
                <div className='my-3'>
                  <div className='mb-2'>
                    <b>Ticket:</b> {ticket.id}
                  </div>
                  <div>
                    <b>Customer:</b> {customerData.user_display_name}
                  </div>
                </div>
                <Divider />
                <form onSubmit={handleSubmitTicket(onSubmitTicket)}>
                  <div className='mt-3'>
                    <FormControl
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={!!errorsTicket.department}
                      className='mb-3'
                    >
                      <InputLabel>Department</InputLabel>
                      <Controller
                        as={
                          <Select native label='Department'>
                            <option aria-label='None' value='' />
                            {departments.map((department, index) => (
                              <option key={index} value={department.id}>
                                {department.ticket_department_name}
                              </option>
                            ))}
                          </Select>
                        }
                        name='department'
                        control={controlTicket}
                      />
                      <FormHelperText>
                        {errorsTicket.department?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={!!errorsTicket.assigned}
                      className='mb-3'
                    >
                      <InputLabel>Assigned to </InputLabel>
                      <Controller
                        as={
                          <Select native label='Assigned To'>
                            <option aria-label='None' value='' />
                            {users?.map((user, index) => (
                              <option key={index} value={user.id}>
                                {user.user_display_name}
                              </option>
                            ))}
                          </Select>
                        }
                        name='assigned'
                        control={controlTicket}
                      />
                      <FormHelperText>
                        {errorsTicket.assigned?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={!!errorsTicket.status}
                      className='mb-3'
                    >
                      <InputLabel>Status</InputLabel>
                      <Controller
                        as={
                          <Select native label='Status'>
                            {statuses.map((status, index) => (
                              <option key={index} value={status.value}>
                                {status.label}
                              </option>
                            ))}
                          </Select>
                        }
                        name='status'
                        control={controlTicket}
                      />
                      <FormHelperText>
                        {errorsTicket.status?.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <Divider />
                  <div className='mt-3'>
                    <FormControl
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={!!errorsTicket.note}
                      className='mb-3'
                    >
                      <label>Note</label>
                      <Controller
                        name='note'
                        control={controlTicket}
                        render={(props) => {
                          return (
                            <ReactQuill
                              value={props.value || ''}
                              theme='snow'
                              onChange={(content, delta, source, editor) => {
                                props.onChange(
                                  editor.getLength() > 1 ? content : ''
                                );
                              }}
                            />
                          );
                        }}
                      />
                      <FormHelperText>
                        {errorsTicket.note?.message}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      type='submit'
                      color='primary'
                      // disabled={props.isAddUpdateGroupPending}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col md='8' xs='12'>
            <Timeline align='left'>
              <MuiTimelineItem>
                <TimelineSeparator>
                  <div
                    style={{
                      width: '100px',
                      textAlign: 'center',
                      margin: '8px 0',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      {moment
                        .duration(moment(ticket.created_at).diff(moment()))
                        .humanize(true)}
                    </p>
                  </div>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent></TimelineContent>
              </MuiTimelineItem>
              <MuiTimelineItem>
                <TimelineSeparator style={{ height: 'inherit' }}>
                  <Box my={1} textAlign='center'>
                    <div
                      style={{
                        width: '100px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar
                        alt={customerData.user_display_name}
                        src={customerData.user_profile}
                      />
                    </div>
                  </Box>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div
                    style={{
                      textAlign: 'center',
                      margin: '8px 0',
                    }}
                  >
                    <Card>
                      <CardBody
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        <div className='mb-2'>
                          <b>{customerData.user_display_name}</b>
                        </div>
                        <Divider />
                        <div
                          className='my-2'
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(ticket.ticket_content),
                          }}
                        ></div>
                        <Divider />
                        <div className='my-3'>
                          <Button
                            onClick={() => {
                              setEditableContent(ticket.ticket_content);
                              setEditableContentExtras({
                                id: '',
                                internal: '',
                                isTicket: true,
                              });
                              toggleEditModal();
                            }}
                            type='submit'
                            color='secondary'
                          >
                            Edit
                          </Button>
                        </div>
                        <div className='mt-2'>
                          {ticket.ticket_attachment?.map(
                            (attachment, index) => (
                              <img
                                key={index}
                                width={300}
                                height='auto'
                                src={process.env.REACT_APP_API_URL + attachment}
                                alt='reply attachment'
                              />
                            )
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </TimelineContent>
              </MuiTimelineItem>
              {ticket.replies?.map((reply, index) => (
                <Fragment key={index}>
                  <MuiTimelineItem>
                    <TimelineSeparator>
                      <div
                        style={{
                          width: '100px',
                          textAlign: 'center',
                          margin: '8px 0',
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {moment
                            .duration(moment(reply.created_at).diff(moment()))
                            .humanize(true)}
                        </p>
                      </div>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent></TimelineContent>
                  </MuiTimelineItem>
                  <MuiTimelineItem>
                    <TimelineSeparator style={{ height: 'inherit' }}>
                      <Box my={1} textAlign='center'>
                        <div
                          style={{
                            width: '100px',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Avatar
                            alt={customerData.user_display_name}
                            src={customerData.user_profile}
                          />
                        </div>
                      </Box>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '8px 0',
                        }}
                      >
                        <Card>
                          <CardBody
                            style={{
                              textAlign: 'left',
                              background:
                                reply.is_internal === '1'
                                  ? '#FFF6D9'
                                  : '#FFFFFF',
                            }}
                          >
                            <div className='mb-2'>
                              <b>{customerData.user_display_name}</b>
                            </div>
                            <Divider />
                            <div
                              className='my-2'
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  reply.ticket_reply_content
                                ),
                              }}
                            ></div>
                            <Divider />
                            <div className='my-3'>
                              <Button
                                onClick={() => {
                                  setEditableContent(
                                    reply.ticket_reply_content
                                  );
                                  setEditableContentExtras({
                                    id: reply.id,
                                    internal: parseInt(reply.is_internal),
                                    isTicket: false,
                                  });
                                  toggleEditModal();
                                }}
                                type='submit'
                                color='secondary'
                              >
                                Edit
                              </Button>
                              <button
                                type='button'
                                className='btn btn-danger ml-3'
                                onClick={() =>
                                  dispatch(
                                    deleteReply(ticket.id, {
                                      id: reply.id,
                                    })
                                  )
                                }
                              >
                                Delete
                              </button>
                              {parseInt(reply.is_internal) === 1 && (
                                <button
                                  type='button'
                                  className='btn btn-primary ml-3'
                                  onClick={() => {
                                    dispatch(
                                      updateReply(reply.id, {
                                        ticket_reply_content:
                                          reply.ticket_reply_content,
                                        ticket_id: ticket.id,
                                        is_internal: 0,
                                      })
                                    );
                                  }}
                                >
                                  Make Public
                                </button>
                              )}
                            </div>
                            <div className='mt-2'>
                              {reply.ticket_reply_attachment?.map(
                                (attachment, index) => (
                                  <img
                                    key={index}
                                    width={300}
                                    height='auto'
                                    src={
                                      process.env.REACT_APP_API_URL + attachment
                                    }
                                    alt='reply attachment'
                                  />
                                )
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </TimelineContent>
                  </MuiTimelineItem>
                </Fragment>
              ))}
              <MuiTimelineItem>
                <TimelineSeparator>
                  <div
                    style={{
                      width: '100px',
                      textAlign: 'center',
                      margin: '8px 0',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      Add Reply
                    </p>
                  </div>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent></TimelineContent>
              </MuiTimelineItem>
              <MuiTimelineItem>
                <TimelineSeparator style={{ height: 'inherit' }}>
                  <Box my={1} textAlign='center'>
                    <div
                      style={{
                        width: '100px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar
                        alt={authUserData.user_display_name}
                        src={authUserData.user_profile}
                      />
                    </div>
                  </Box>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div
                    style={{
                      textAlign: 'center',
                      margin: '8px 0',
                    }}
                  >
                    <Card>
                      <CardBody className='' style={{ textAlign: 'left' }}>
                        <div className='mb-3'>
                          <button
                            onClick={() => setReplyType('customer')}
                            type='button'
                            className={`btn ${
                              replyType === 'customer'
                                ? 'btn-secondary'
                                : 'btn-link'
                            }`}
                          >
                            Customer
                          </button>
                          <button
                            onClick={() => setReplyType('internal')}
                            type='button'
                            className={`ml-2 btn ${
                              replyType === 'internal'
                                ? 'btn-secondary'
                                : 'btn-link'
                            }`}
                          >
                            Internal
                          </button>
                        </div>
                        <form onSubmit={handleSubmitReply(onSubmitReply)}>
                          <div className='my-3'>
                            <FormControl
                              variant='outlined'
                              size='small'
                              fullWidth
                              error={!!errorsReply.reply}
                              className='mb-3 reply-quill'
                            >
                              {replyType !== 'customer' && (
                                <style>{`.reply-quill .ql-container {background:#FFF6D9;}`}</style>
                              )}
                              <Controller
                                name='reply'
                                control={controlReply}
                                render={(props) => {
                                  return (
                                    <ReactQuill
                                      value={props.value || ''}
                                      theme='snow'
                                      onChange={(
                                        content,
                                        delta,
                                        source,
                                        editor
                                      ) => {
                                        props.onChange(
                                          editor.getLength() > 1 ? content : ''
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                              <FormHelperText>
                                {errorsReply.reply?.message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className='mt-2'>
                            <button
                              type='button'
                              className='btn btn-outline-primary btn-sm'
                              onClick={toggleUploadModal}
                            >
                              <AttachFileIcon />
                              Attach File
                            </button>
                          </div>
                          <div className='mt-2'>
                            <button type='submit' className='btn btn-primary'>
                              Submit
                            </button>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </div>
                </TimelineContent>
              </MuiTimelineItem>
            </Timeline>
          </Col>
        </Row>
        <Modal isOpen={uploadModal} toggle={toggleUploadModal}>
          <ModalHeader toggle={toggleUploadModal}>Attach Files</ModalHeader>
          <ModalBody>
            <FileDropzone
              files={files}
              onDrop={handleDrop}
              onRemove={handleRemove}
              onRemoveAll={handleRemoveAll}
              fileImg={addFileImg}
            />
          </ModalBody>
          <ModalFooter>
            <button
              onClick={toggleUploadModal}
              type='button'
              className='btn btn-primary'
            >
              Ok
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit</ModalHeader>
          <ModalBody>
            <FormControl
              variant='outlined'
              size='small'
              fullWidth
              // error={!editableContent.length}
            >
              <ReactQuill
                value={editableContent || ''}
                theme='snow'
                onChange={(content, delta, source, editor) => {
                  setEditableContent(editor.getLength() > 1 ? content : '');
                }}
              />
              {/* <FormHelperText>{!editableContent.length && ""}</FormHelperText> */}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={() => {
                toggleEditModal();
                if (editableContentExtras.isTicket)
                  dispatch(
                    updateTicket(ticket.id, {
                      customer_id: ticket.customer_id,
                      ticket_subject: ticket.ticket_subject,
                      department_id: ticket.department_id,
                      ticket_priority: ticket.ticket_priority,
                      ticket_status: ticket.ticket_status,
                      ticket_notes: ticket.ticket_notes,
                      ticket_assigned: ticket.ticket_assigned,
                      ticket_content: editableContent,
                    })
                  );
                else
                  dispatch(
                    updateReply(editableContentExtras.id, {
                      ticket_reply_content: editableContent,
                      ticket_id: ticket.id,
                      is_internal: editableContentExtras.internal,
                    })
                  );
              }}
              type='button'
              className='btn btn-primary'
            >
              Ok
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
    isCustomerDataPending: state.customer.isCustomerDataPending,
    customerData: state.customer.customerData,
    isFetchUserPending: state.user.isFetchUserPending,
    users: state.user.users,
  };
};

export default connect(mapStateToProps, null)(TicketDetails);
