export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
//register
export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_ERROR = 'REGISTER_ERROR';

//login
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGINAS_PENDING = 'LOGINAS_PENDING';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';


// for login user detail
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const FETCH_USER_PENDING = 'FETCH_USER_PENDING';