import React from "react";
import { connect } from "react-redux";
import {
  fetchRoles,
  setFetchRoleError,
  deleteRoles,
} from "../actions/roleActions";
import RoleActionButtons from "./RoleActionButtons";
import { history } from "helpers/history";
import RoleSkeleeton from "./RoleSkeleeton";

import { Row, Col, MuiTable, Button } from "@mycentyr/centyr-ui";

class RoleList extends React.Component {
  componentDidMount() {
    this.props.fetchRoles();
  }

  render() {
    const headCells = [
      { id: "role_name", numeric: false, disablePadding: false, label: "Name" },
      { id: "role_slug", numeric: false, disablePadding: false, label: "Role" },
      {
        id: "role_description",
        numeric: false,
        disablePadding: false,
        label: "Description",
      },
      {
        id: "user_count",
        numeric: true,
        disablePadding: false,
        label: "Users",
      },
      {
        id: "granted_permission_count",
        numeric: true,
        disablePadding: false,
        label: "Granted Permissions",
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (
      id,
      role_name,
      role_slug,
      role_description,
      user_count,
      granted_permission_count
    ) => {
      return {
        id,
        role_name,
        role_slug,
        role_description,
        user_count,
        granted_permission_count,
      };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchRoles(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchRoles(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteRoles(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchRoles(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.roles) {
      this.props.roles.map((role) =>
        rows.push(
          createData(
            role.id,
            role.role_name,
            role.role_slug,
            role.role_description,
            role.user_count,
            role.granted_permission_count + "/" + role.all_permission_count
          )
        )
      );
    }
    return (
      <div>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes("add.roles") && (
                  <Button
                    color="primary"
                    className="mb-3"
                    onClick={() => {
                      history.push("add");
                    }}
                  >
                    Add Role
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchRolePending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes("delete.roles")
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={RoleSkeleeton}
            Actions={RoleActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchRoleSuccess: state.role.isFetchRoleSuccess,
    isFetchRolePending: state.role.isFetchRolePending,
    fetchRoleError: state.role.fetchRoleError,
    roles: state.role.roles,
    noOfRecords: state.role.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: (pageNo, perPage, keyword) => {
      dispatch(fetchRoles(pageNo, perPage, keyword));
    },
    setFetchRoleError: (postData) => {
      dispatch(setFetchRoleError(postData));
    },
    deleteRoles: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteRoles(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
