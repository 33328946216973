import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'redux/store';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  CustomModal,
} from '@mycentyr/centyr-ui';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab/';
import { styled } from '@material-ui/core/styles';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
import { changeStatus } from '../slices/packageSlice';

const MuiTimelineItem = styled(TimelineItem)({
  '&:before': {
    display: 'none',
  },
});

const PackageLogs = (props) => {
  const id = props.id;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [modal1, setModal1] = React.useState(false);
  const toggle1 = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { packageData, isFetchPackagePending, isAddUpdatePackagePending } =
    useSelector((state) => state.package);

  const OnReadytoMailout = () => {
    dispatch(changeStatus(id, 'ready-to-mailout', toggle));
  };

  const OnDelivered = () => {
    dispatch(changeStatus(id, 'delivered', toggle));
  };

  return (
    <React.Fragment>
      <CustomModal
        onModalAccept={OnReadytoMailout}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle='Ready to Mailout'
        modalBody='Are you sure package Ready to Mailout?'
      />
      <CustomModal
        onModalAccept={OnDelivered}
        onModalCancel={toggle1}
        modal={modal1}
        toggle={toggle1}
        modalTitle='Delivered'
        modalBody='Are you sure package Delivered?'
      />
      <Row>
        <Col lg='8'>
          <Timeline align='left'>
            {packageData.package_logs?.map((log, index) => (
              <React.Fragment key={index}>
                <MuiTimelineItem>
                  <TimelineSeparator>
                    <AccessTimeIcon />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div
                      style={{
                        margin: '-5px',
                        paddingBottom: '13px',
                        fontSize: '1rem',
                      }}
                    >
                      {moment
                        .duration(moment(log.log_created_at).diff(moment()))
                        .humanize(true)}
                    </div>
                    <Card>
                      <CardTitle>{log.status}</CardTitle>
                      <CardBody>
                        <div>{log.message}</div>
                        <p className='text-right'>
                          <span className='text-right text-muted'>
                            {log.log_created_at}
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </TimelineContent>
                </MuiTimelineItem>
              </React.Fragment>
            ))}

            {packageData &&
              parseInt(packageData.is_paid) === 1 &&
              packageData.package_weight != 0 && (
                <MuiTimelineItem>
                  <TimelineSeparator>
                    <AccessTimeIcon />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <div
                      style={{
                        margin: '-5px',
                        paddingBottom: '13px',
                        fontSize: '1rem',
                      }}
                    >
                      Now
                    </div>
                    <Card>
                      <CardTitle>Your Action</CardTitle>
                      <CardBody className=''>
                        <p>What do you want to do with your package?</p>
                        <Row>
                          <Col lg={12}>
                            {packageData.package_status !==
                              'shipment-processing' &&
                              packageData.package_status !==
                                'ready-to-mailout' && (
                                <Button
                                  color='primary'
                                  outline
                                  onClick={toggle}
                                >
                                  Ready to Mailout
                                </Button>
                              )}{' '}
                            <Button color='primary' outline onClick={toggle1}>
                              Delivered
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TimelineContent>
                </MuiTimelineItem>
              )}
          </Timeline>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PackageLogs;
