import React, { useEffect, useLayoutEffect } from "react";
import { Prompt } from "react-router";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "redux/store";

import {
  Row,
  Col,
  Card,
  CardBody,
  TextField,
  Button,
  CardTitle,
  CustomSpinner,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  FormHelperText,
} from "@mycentyr/centyr-ui";
import {
  fetchSettings,
  saveSetting,
} from "app/manage/settings/actions/settingsActions";
import DeleteIcon from "@material-ui/icons/Delete";

const schema = yup.object().shape({
  // commission_rate: yup.number().required("Rate is required"),
});

const initialValue = {
  commission: [],
};

const types = [
  { value: "flat", label: "Flat" },
  { value: "percentage", label: "Percentage" },
];

function WSASettings(props) {
  const [group, SetGroup] = React.useState("wsa");
  const typeLabel = React.useRef(null);
  const [typeLabelWidth, setTypeLabelWidth] = React.useState(0);

  const { register, handleSubmit, errors, reset, formState, control } = useForm(
    {
      mode: "onblur",
      resolver: yupResolver(schema),
    }
  );

  const dispatch = useDispatch();
  const { settings, isFetchSettingPending, isSaveSettingPending } = useSelector(
    (state) => state.setting
  );

  useEffect(() => {
    dispatch(fetchSettings(group));
  }, []);

  useEffect(() => {
    settings &&
      reset({
        commission: settings.commission,
      });

    if (!isFetchSettingPending)
      setTypeLabelWidth(typeLabel.current?.offsetWidth);
  }, [settings]);

  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commission",
  });

  const onSubmit = (data) => {
    const postData = {
      commission: JSON.stringify(data.commission),
    };
    dispatch(saveSetting(group, postData));
  };

  if (isFetchSettingPending) return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        {/* <Prompt
          when={isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        /> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {isSaveSettingPending && <CustomSpinner />}
          <div className="page-header">
            <Row>
              <Col className="page-title">
                <h1 className="page-title">{props.title}</h1>
              </Col>
              <Col className="text-right page-header-button">
                <Button
                  type="submit"
                  color="primary"
                  className="mb-3"
                  disabled={props.isSaveSettingPending}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </div>
          <div className="content-wrapper">
            <Button
              type="button"
              onClick={() => {
                append({
                  commision_minimum_amount: 0,
                  commision_maximum_amount: 0,
                  commission_type: "flat",
                  commision_rate: 0,
                });
              }}
              color="primary"
              className="mb-3"
            >
              Add Item
            </Button>
            <div className="clearfix"></div>
            <Row>
              {fields &&
                fields?.map((commission, index) => (
                  <Col key={index} lg={4}>
                    <Card>
                      <CardTitle>
                        <Button
                          className="pull-right"
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </CardTitle>
                      <CardBody>
                        <Row>
                          <Col lg="12" xs="12">
                            <TextField
                              label="Minimum Amount"
                              className="mb-3"
                              name={`commission[${index}].commision_minimum_amount`}
                              variant="outlined"
                              size="small"
                              fullWidth
                              defaultValue={commission.commision_minimum_amount}
                              InputLabelProps={{ shrink: true }}
                              inputRef={register({})}
                            />
                          </Col>
                          <Col lg="12" xs="12">
                            <TextField
                              label="Maximum Amount"
                              className="mb-3"
                              name={`commission[${index}].commision_maximum_amount`}
                              variant="outlined"
                              size="small"
                              fullWidth
                              defaultValue={commission.commision_maximum_amount}
                              InputLabelProps={{ shrink: true }}
                              inputRef={register({})}
                            />
                          </Col>
                          <Col lg="12">
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                              className="mb-3"
                            >
                              <InputLabel
                                shrink
                                ref={typeLabel}
                                htmlFor="commission_type"
                              >
                                Type
                              </InputLabel>
                              <Select
                                native
                                inputRef={register({})}
                                defaultValue={commission.commission_type}
                                input={
                                  <OutlinedInput
                                    notched
                                    labelWidth={typeLabelWidth}
                                    name={`commission[${index}].commision_type`}
                                  />
                                }
                              >
                                {types &&
                                  types.map((type, index) => (
                                    <option value={type.value} key={index}>
                                      {type.label}
                                    </option>
                                  ))}
                              </Select>
                            </FormControl>
                          </Col>
                          <Col lg="12" xs="12">
                            <TextField
                              label="Rate"
                              className="mb-3"
                              name={`commission[${index}].commision_rate`}
                              variant="outlined"
                              size="small"
                              fullWidth
                              defaultValue={commission.commision_rate}
                              InputLabelProps={{ shrink: true }}
                              inputRef={register({})}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        </form>
      </React.Fragment>
    );
}

export default WSASettings;
