import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'redux/store';
import { fetchPackages, deletePackages } from '../slices/packageSlice';
import PackageActionButtons from './PackageActionButtons';
import { history } from 'helpers/history';
import { appName } from 'utils/axios';
import PackageSkeleeton from './PackageSkeleeton';

import { fetchAllCustomers } from 'app/crm/customer/actions/customerActions';
import moment from 'moment';

import {
  Row,
  Col,
  MuiTable,
  Button,
  IconButton,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  TextField,
} from '@mycentyr/centyr-ui';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

const PackageList = (props) => {
  const { status } = props.match.params;

  let title = '';
  if (status == 'all') {
    title = 'All Packages';
  } else if (status == 'received') {
    title = 'All Packages';
  } else if (status == 'ready-to-mailout') {
    title = 'Ready for Mailout Packages';
  } else if (status == 'on-the-way') {
    title = 'On the Way Packages';
  } else if (status == 'hold') {
    title = 'Hold Packages';
  } else if (status == 'action-required') {
    title = 'Action Required';
  } else if (status == 'delivered') {
    title = 'Delivered Packages';
  }

  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [rows, setRows] = useState([]);

  const [open, SetOpen] = useState(false);
  const customerLabel = React.useRef(null);
  const [customerLabelWidth, setCustomerLabelWidth] = React.useState(0);

  const { packages, noOfRecords, isFetchPackagesPending } = useSelector(
    (state) => state.package
  );
  const { customers } = useSelector((state) => state.customer);

  const headCells = [
    {
      id: 'package_id',
      numeric: false,
      disablePadding: false,
      label: 'Package ID',
    },
    {
      id: 'package_owner',
      numeric: false,
      disablePadding: false,
      label: 'Package Owner',
    },
    {
      id: 'package_type',
      numeric: false,
      disablePadding: false,
      label: 'Package Type',
    },
    {
      id: 'package_inward_tracking_number',
      numeric: false,
      disablePadding: false,
      label: 'Inward Tracking Number',
    },
    {
      id: 'package_receive_time',
      numeric: false,
      disablePadding: false,
      label: 'Received on',
    },
    {
      id: 'package_status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
  ];

  const createData = (
    id,
    package_id,
    package_owner,
    package_type,
    package_inward_tracking_number,
    package_receive_time,
    package_status,
    is_paid
  ) => {
    return {
      id,
      package_id,
      package_owner,
      package_type,
      package_inward_tracking_number,
      package_receive_time,
      package_status,
      is_paid,
    };
  };

  const onReload = (rowsPerPage, keyword) => {
    dispatch(
      fetchPackages(
        1,
        rowsPerPage,
        keyword,
        status,
        customerId,
        startDate,
        endDate
      )
    );
  };

  const handlePaginationChange = (pageNo, perPage, keyword) => {
    dispatch(
      fetchPackages(
        pageNo,
        perPage,
        keyword,
        status,
        customerId,
        startDate,
        endDate
      )
    );
  };

  const onMultiDelete = (postData, pageNo, perPage, keyword) => {
    dispatch(
      deletePackages(
        postData,
        pageNo,
        perPage,
        keyword,
        status,
        customerId,
        startDate,
        endDate
      )
    );
  };

  const onSearch = (keyword, perPage) => {
    if (keyword.length > 2 || keyword.length === 0)
      dispatch(
        fetchPackages(
          1,
          perPage,
          keyword,
          status,
          customerId,
          startDate,
          endDate
        )
      );
  };

  useEffect(() => {
    document.title = title + appName;
    dispatch(fetchPackages(1, 10, '', status, customerId, startDate, endDate));
  }, [status]);

  useEffect(() => {
    dispatch(fetchAllCustomers());
  }, []);

  useEffect(() => {
    let rowsLcl = [];
    packages.map((pkg) =>
      rowsLcl.push(
        createData(
          pkg.id,
          pkg.package_id,
          pkg.package_owner,
          pkg.package_type,
          pkg.package_inward_tracking_number,
          moment(pkg.package_receive_time).format('DD-MM-YYYY'),
          pkg.package_status,
          pkg.is_paid
        )
      )
    );
    setRows(rowsLcl);
  }, [packages]);

  const onClose = () => {
    SetOpen(false);
  };

  const FilterActionButton = () => {
    return (
      <IconButton
        className='no-border-onfocus'
        aria-label='Filter'
        onClick={() => SetOpen(true)}
      >
        <FilterListIcon />
      </IconButton>
    );
  };

  return (
    <div>
      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1>{title}</h1>
          </Col>
          <Col className='text-right page-header-button'>
            {props.authUserData.permissions &&
              props.authUserData.permissions.includes('add.wsa.packages') && (
                <Button
                  color='primary'
                  className='mb-3'
                  onClick={() => {
                    history.replace('/packages/add');
                  }}
                >
                  Add Package
                </Button>
              )}
          </Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        <Drawer
          anchor='right'
          classes={{ paper: 'filter-paper' }}
          onClose={onClose}
          open={open}
          variant='temporary'
        >
          <div className='filter-action-buttons-container'>
            <Button
              onClick={onClose}
              color='danger'
              outline
              size='sm'
              className='mb-3'
            >
              <CloseIcon /> Close
            </Button>
            <Row className='mt-4'>
              <Col lg={12}>
                <FormControl
                  variant='outlined'
                  size='small'
                  fullWidth
                  className='mb-3'
                  onChange={(event) => setCustomerId(event.target.value)}
                >
                  <InputLabel shrink htmlFor='customer_id'>
                    Customer
                  </InputLabel>
                  <Select
                    native
                    input={
                      <OutlinedInput
                        notched
                        name='customer_id'
                        id='customer_id'
                      />
                    }
                    value={customerId}
                  >
                    <option value=''>Select Customer</option>
                    {customers &&
                      customers.map((customer) => (
                        <option value={customer.id} key={customer.id}>
                          {customer.user_display_name} ({customer.user_username}
                          )
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col lg={12}>
                <TextField
                  label='Start Date'
                  className='mb-3'
                  name='start_date'
                  id='start_date'
                  variant='outlined'
                  size='small'
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col lg={12}>
                <TextField
                  label='End Date'
                  className='mb-3'
                  name='end_date'
                  id='end_date'
                  variant='outlined'
                  size='small'
                  fullWidth
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </Col>
            </Row>
            <Button
              color='primary'
              block
              className='filter-action-button'
              onClick={() => {
                dispatch(
                  fetchPackages(
                    1,
                    10,
                    '',
                    status,
                    customerId,
                    startDate,
                    endDate
                  )
                );
                onClose();
              }}
            >
              Apply filters
            </Button>
            <Button
              color='primary'
              block
              outline
              className='filter-action-button'
              onClick={() => {
                setCustomerId('');
                setStartDate('');
                setEndDate('');
              }}
            >
              <DeleteIcon /> Clear
            </Button>
          </div>
        </Drawer>
        <MuiTable
          headCells={headCells}
          rows={rows}
          onReload={onReload} // comment this to disable refresh option
          isFetchPending={isFetchPackagesPending}
          // chooseColumn={false}   //=>to false display column option
          noOfRecords={noOfRecords}
          handlePaginationChange={handlePaginationChange}
          multipleDelete={
            props.authUserData.permissions &&
            props.authUserData.permissions.includes('delete.wsa.packages')
              ? true
              : false
          }
          FilterComponent={FilterActionButton}
          onMultiDelete={onMultiDelete}
          onSearch={onSearch}
          CustomSkeleton={PackageSkeleeton}
          Actions={PackageActionButtons}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(PackageList);
