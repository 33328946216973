import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { useParams } from "react-router-dom";
import { appName } from "utils/axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "redux/store";
import { history } from "helpers/history";

import {
  fetchEmailTemplate,
  testEmail,
  addEmailTemplate,
  updateEmailTemplate,
} from "../slices/emailTemplateSlice";

import { fetchAllEmailProviders } from "app/manage/email/emailprovider/actions/emailProviderActions";

import useEscape from "layouts/useEscape";

import {
  Row,
  Col,
  InputLabel,
  Label,
  CustomSpinner,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Button,
  OutlinedInput,
  CardBody,
  CardTitle,
  Checkbox,
  Card,
  Alert,
  CustomSnackbar,
} from "@mycentyr/centyr-ui";
import ReactQuill from "react-quill";
const statuses = [
  { value: "1", label: "Active" },
  { value: "0", label: "In-active" },
];

const schema = yup.object().shape({
  email_template_name: yup.string().required("Name is required"),
  email_template_subject: yup.string().required("Subject is required"),
  emailprovider_id: yup.string().required("Email Provider is required"),
});
const schema2 = yup.object().shape({
  email: yup.string().required("Email is required").email("Email is invalid"),
});

const initialValue = {
  email_template_name: "",
  email_template_subject: "",
  email_template_message: "",
  emailprovider_id: "",
  is_active: 1,
  is_raw: 0,
};

const OneEmailTemplate = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [templateMessage, setTemplateMessage] = useState("");
  const { id } = params;

  const {
    emailTemplate,
    isAddUpdateEmailTemplatePending,
    isFetchEmailTemplatePending,
    testEmailResponse,
    testEmailPending,
  } = useSelector((state) => state.emailTemplate);

  const [emailResponse, setEmailResponse] = React.useState(null);
  const { emailproviders } = useSelector((state) => state.emailprovider);
  
  useEscape(() => history.push("list"));

  if (id !== "add") {
    document.title = "Update Email Template" + appName;
  } else {
    document.title = "Create New Email Template" + appName;
  }

  useEffect(() => {
    if (id !== "add") {
      dispatch(fetchEmailTemplate(id));
    }
    dispatch(fetchAllEmailProviders());
  }, [id]);

  useEffect(() => {
    setEmailResponse(testEmailResponse);
  }, [testEmailResponse]);

  useEffect(() => {
    reset(
      id === "add"
        ? initialValue
        : {
            email_template_name: emailTemplate.email_template_name || "",
            email_template_subject: emailTemplate.email_template_subject || "",
            email_template_message: emailTemplate.email_template_message || "",
            emailprovider_id: emailTemplate.emailprovider_id || "",
            is_active: emailTemplate.is_active || 1,
            is_raw: emailTemplate.is_raw || 0,
          }
    );

    if (id !== "add") {
      setTemplateMessage(emailTemplate.email_template_message || "");
    } else {
      setTemplateMessage("");
    }
  }, [emailTemplate, id]);

  useEffect(() => {});

  const { register, handleSubmit, errors, reset, formState, control } = useForm(
    {
      mode: "onblur",
      resolver: yupResolver(schema),
      defaultValues:initialValue
    }
  );
  const { dirtyFields } = formState;
  const isDirtyAlt = !!Object.keys(dirtyFields).length;

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema2),
  });

  const onSubmit = (data) => {
    const postData = {
      email_template_name: data.email_template_name,
      email_template_subject: data.email_template_subject,
      email_template_message: templateMessage,
      emailprovider_id: data.emailprovider_id,
      is_active: data.is_active,
      is_raw: data.is_raw ? 1 : 0,
    };
    id !== "add"
      ? dispatch(updateEmailTemplate(id, postData))
      : dispatch(addEmailTemplate(postData));
  };

  const onSubmit2 = (data) => {
    const postData = {
      email: data.email,
    };

    dispatch(testEmail(id, postData));
  };

  if (!isAddUpdateEmailTemplatePending && isFetchEmailTemplatePending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        <Prompt
          when={isDirtyAlt}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1 className="page-title">
                {id !== "add"
                  ? "Update Email Template"
                  : "Create New Email Template"}
              </h1>
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <Row>
            <Col lg="6" xs="12">
              <Card>
                <CardTitle>Email Template Details</CardTitle>
                <CardBody>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {isFetchEmailTemplatePending && <CustomSpinner />}
                    <Row>
                      <Col lg="12" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email Template Name"
                          name="email_template_name"
                          id="email_template_name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.email_template_name?.message ? true : false
                          }
                          helperText={errors.email_template_name?.message}
                          inputRef={register}
                        />
                      </Col>
                      <Col lg="12" xs="12">
                        <TextField
                          className="mb-3"
                          label="Email Template Subject"
                          name="email_template_subject"
                          id="email_template_subject"
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputLabelProps={id !== "add" ? { shrink: true } : {}}
                          error={
                            errors.email_template_subject?.message
                              ? true
                              : false
                          }
                          helperText={errors.email_template_subject?.message}
                          inputRef={register}
                        />
                      </Col>

                      <Col lg="12" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.emailprovider_id}
                          className="mb-3"
                        >
                          <InputLabel>Email Provider</InputLabel>
                          <Controller
                            as={
                              <Select native label="Email Provider">
                                <option aria-label="None" value="" />
                                {emailproviders?.map((emailprovider, index) => (
                                  <option key={index} value={emailprovider.id}>
                                    {emailprovider.emailprovider_name}
                                  </option>
                                ))}
                              </Select>
                            }
                            name="emailprovider_id"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.emailprovider_id?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                      <Col lg="12" xs="12">
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          error={!!errors.is_active}
                          className="mb-3"
                        >
                          <InputLabel>Status</InputLabel>
                          <Controller
                            as={
                              <Select native label="Status">
                                {statuses.map((status) => (
                                  <option
                                    value={status.value}
                                    key={status.value}
                                  >
                                    {status.label}
                                  </option>
                                ))}
                              </Select>
                            }
                            name="is_active"
                            control={control}
                          />
                          <FormHelperText>
                            {errors.is_active?.message}
                          </FormHelperText>
                        </FormControl>
                      </Col>
                      <Col lg="12" xs="12">
                        <FormControlLabel
                          className="mb-3"
                          control={
                            <Checkbox
                              name="is_raw"
                              defaultChecked={emailTemplate.is_raw === 1}
                              value={1}
                              id="is_raw"
                              color="primary"
                              inputRef={register}
                            />
                          }
                          label="Send as Plaintext"
                        />
                      </Col>
                      <Col lg="12" xs="12">
                        <Label>Email Template Message</Label>
                        <ReactQuill
                          className="mb-3"
                          value={templateMessage}
                          onChange={(value) => {
                            setTemplateMessage(value);
                          }}
                        />
                      </Col>
                      <Col lg="12" xs="12" className="text-right">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isAddUpdateEmailTemplatePending}
                        >
                          {id !== "add" ? "Update" : "Create"}
                        </Button>
                        <Button
                          className="ml-2"
                          type="button"
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("list");
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xs="12">
              {id !== "add" && (
                <Card>
                  <CardTitle>Send Test Email</CardTitle>
                  <CardBody>
                    <form onSubmit={handleSubmit2(onSubmit2)}>
                      {testEmailPending && <CustomSpinner />}
                      <Row>
                        <Col lg="12" xs="12">
                          <TextField
                            className="mb-3"
                            label="Email"
                            name="email"
                            id="email"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={errors2.email?.message ? true : false}
                            helperText={errors2.email?.message}
                            inputRef={register2}
                          />
                        </Col>

                        <Col lg="12" xs="12" className="text-right">
                          <Button type="submit" color="primary">
                            Submit
                          </Button>
                        </Col>

                        <Col lg="12" xs="12" className="mt-3">
                          {emailResponse && (
                            <Alert
                              color={
                                emailResponse.success &&
                                emailResponse.success === true
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {emailResponse.message}
                            </Alert>
                          )}
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
};

export default OneEmailTemplate;
