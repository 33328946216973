import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  addShipping,
  fetchShippings,
  fetchShipping,
  setAddUpdateShippingError,
  updateShipping,
  setAddUpdateShippingSuccess,
} from "../actions/shippingActions";
import {
  Row,
  Col,
  CustomSpinner,
  TextField,
  Button,
} from "@mycentyr/centyr-ui";

const schema = yup.object().shape({
  shipping_name: yup.string().required("Name is required"),
});

const initialValue = {
  shipping_name: "",
};

const OneShipping = (props) => {
  const id = props.id;

  useEffect(() => {
    if (id !== "add") {
      props.fetchShipping(id);
    }
  }, [id]);

  useEffect(() => {
    reset(
      id === "add"
        ? initialValue
        : {
            shipping_name: props.shippingData.shipping_carrier_name || "",
          }
    );
  }, [props.shippingData, id]);

  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onblur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const postData = {
        shipping_carrier_name: data.shipping_name,
    };
    id === "add"
      ? props.addShipping(postData, props.toggle)
      : props.updateShipping(id, postData, props.toggle);
  };
  if (!props.isAddUpdateShippingPending && props.isShippingDataPending)
    return <CustomSpinner />;
  else
    return (
      <React.Fragment>
        {props.isShippingDataPending && <CustomSpinner />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="12" xs="12">
              <TextField
                className="mb-3"
                label="Shipping Name *"
                name="shipping_name"
                id="shipping_name"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={id !== "add" ? { shrink: true } : {}}
                error={errors.shipping_name?.message ? true : false}
                helperText={errors.shipping_name?.message}
                inputRef={register}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12" xs="12" className="text-right">
              <Button
                type="submit"
                color="primary"
                disabled={props.isAddUpdateShippingPending}
              >
                {id === "add" ? "Create" : "Update"}
              </Button>
              <Button
                className="ml-2"
                type="button"
                color="secondary"
                onClick={props.toggle}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
  return {
    shippingData: state.shipping.shippingData,
    isFetchShippingPending: state.shipping.isFetchShippingPending,
    shippings: state.shipping.shippings,
    isAddUpdateShippingPending: state.shipping.isAddUpdateShippingPending,
    addUpdateShippingError: state.shipping.addUpdateShippingError,
    isShippingDataPending: state.shipping.isShippingDataPending,
    isAddUpdateShippingSuccess: state.shipping.isAddUpdateShippingSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addShipping: (postData, toggle) => {
      dispatch(addShipping(postData, toggle));
    },
    fetchShippings: (pageNo, perPage, keyword) => {
      dispatch(fetchShippings(pageNo, perPage, keyword));
    },
    fetchShipping: (id) => {
      dispatch(fetchShipping(id));
    },
    setAddUpdateShippingError: (error) => {
      dispatch(setAddUpdateShippingError(error));
    },
    updateShipping: (id, postData, toggle) => {
      dispatch(updateShipping(id, postData, toggle));
    },
    setAddUpdateShippingSuccess: (isAddUpdateShippingSuccess) => {
      dispatch(setAddUpdateShippingSuccess(isAddUpdateShippingSuccess));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneShipping);
