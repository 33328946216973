//Import Components for Manage Routes
import UserList from "app/manage/user/views/UserList";
import Profile from "app/manage/user/views/Profile";
import OneUser from "app/manage/user/views/OneUser";

import RoleList from "app/manage/role/views/RoleList";
import OneRole from "app/manage/role/views/OneRole";

import GeneralSettings from "app/manage/settings/views/GeneralSettings";

import Logs from "app/manage/logs/views/Logs";
import EmailProviderList from "app/manage/email/emailprovider/views/EmailProviderList";
import OneEmailProvider from "app/manage/email/emailprovider/views/OneEmailProvider";
import EmailTemplateList from "app/manage/email/emailtemplate/views/EmailTemplateList";
import OneEmailTemplate from "app/manage/email/emailtemplate/views/OneEmailTemplate";

//Import icons for Manage Nav Section
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import EmailIcon from "@material-ui/icons/Email";
import ReceiptIcon from "@material-ui/icons/Receipt";

const manageroutes = [
  {
    path: "/manage",
    name: "Manage Sites and Users",
    title: "Manage",
    id: "manage",
    icon: SettingsIcon,
    permission: [
      "list.users",
      "add.users",
      "list.roles",
      "add.roles",
      "list.permission.groups",
      "list.permissions",
      "add.permissions",
    ],
    child: [
      {
        collapse: true,
        name: "Users",
        icon: PeopleAltIcon,
        state: "Users",
        permission: ["list.users", "add.users"],
        subchild: [
          {
            path: "/manage/users/profile",
            name: "Your Profile",
            title: "Your Profile",
            component: Profile,
          },
          {
            path: "/manage/users/list",
            title: "Manage Users",
            name: "All Users",
            component: UserList,
            permission: "list.users",
          },
          {
            path: "/manage/users/add",
            title: "Create New User",
            name: "Add New",
            permission: "add.users",
          },
        ],
      },
      {
        collapse: true,
        name: "Roles",
        icon: LockIcon,
        state: "Roles",
        permission: [
          "list.roles",
          "add.roles",
        ],
        subchild: [
          {
            path: "/manage/roles/list",
            title: "Manage Roles",
            name: "All Roles",
            component: RoleList,
            permission: "list.roles",
          },
          {
            path: "/manage/roles/add",
            title: "Create New Role",
            name: "Add Role",
            permission: "add.roles",
          },
        ],
      },
      {
        collapse: true,
        name: "Emails",
        icon: EmailIcon,
        state: "Emails",
        permission: [
          "list.emailproviders",
          "add.emailproviders",
          "list.email.templates",
          "add.email.templates",
        ],
        subchild: [
          {
            path: "/manage/email/emailproviders/list",
            name: "Email Providers",
            title: "Email Providers",
            component: EmailProviderList,
            permission: "list.emailproviders",
          },
          {
            path: "/manage/email/emailtemplates/list",
            name: "Email Templates",
            title: "Email Templates",
            component: EmailTemplateList,
            permission: "list.email.templates",
          },
        ],
      },
      {
        collapse: true,
        name: "Settings",
        icon: SettingsIcon,
        state: "Settings",
        permission: ["manage.settings"],
        subchild: [
          {
            path: "/manage/settings/general",
            title: "General Settings",
            name: "General",
            component: GeneralSettings,
            permission: "manage.settings",
          },
        ],
      },
      {
        path: "/manage/logs",
        name: "Logs",
        permission: ["view.logs"],
        title: "Activity Logs",
        icon: ReceiptIcon,
        component: Logs,
      },
    ],
  },
  {
    path: "/manage/users/:id",
    name: "Create New User",
    component: OneUser,
    routeonly: true,
  },
  {
    path: "/manage/roles/:id",
    name: "Create New Role",
    component: OneRole,
    routeonly: true,
  },
  {
    path: "/manage/email/emailproviders/:id",
    name: "Update Email Provider",
    component: OneEmailProvider,
    routeonly: true,
  },
  {
    path: "/manage/email/emailtemplates/:id",
    name: "Update Email Template",
    component: OneEmailTemplate,
    routeonly: true,
  },
];

export default manageroutes;
