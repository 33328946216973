// Import Required Components
import { NotFound } from '@mycentyr/centyr-ui';

//import all routes files
import setuproutes from 'app/setup/setuproutes';
import manageroutes from 'app/manage/manageroutes';
import crmroutes from 'app/crm/crmroutes';
import dashboardroutes from './dashboardroutes';
import helpdeskroutes from 'app/helpdesk/helpdeskroutes';
import wsaroutes from 'app/wsa/wsaroutes';

var ThemeRoutes = [
  ...dashboardroutes,
  ...crmroutes,
  ...wsaroutes,
  ...helpdeskroutes,
  ...manageroutes,
  ...setuproutes,

  { path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true },
  {
    path: '/not-found',
    name: 'Not Found',
    component: NotFound,
    routeonly: true,
  },
];
export default ThemeRoutes;
