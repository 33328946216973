import React from "react";
import { connect } from "react-redux";
import {
  fetchApplications,
  setFetchApplicationError,
  deleteApplications,
} from "../actions/applicationActions";
import ApplicationActionButtons from "./ApplicationActionButtons";
import OneApplication from "./OneApplication";
import ApplicationSkeleeton from "./ApplicationSkeleeton";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  Button,
} from "@mycentyr/centyr-ui";

class ApplicationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  componentDidMount() {
    this.props.fetchApplications();
  }

  render() {
    const headCells = [
      { id: "id", numeric: false, disablePadding: false, label: "#" },
      {
        id: "application_name",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "application_description",
        numeric: false,
        disablePadding: false,
        label: "Description",
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (id, application_name, application_description) => {
      return { id, application_name, application_description };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchApplications(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchApplications(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteApplications(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchApplications(1, perPage, keyword);
    };

    var rows = [];
    if (this.props.applications) {
      this.props.applications.map((application) =>
        rows.push(
          createData(
            application.id,
            application.application_name,
            application.application_description
          )
        )
      );
    }

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div className="list-container">
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Application</ModalHeader>
          <ModalBody>
            <OneApplication id="add" toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes(
                  "add.applications"
                ) && (
                  <Button color="primary" className="mb-3" onClick={toggle}>
                    Add Application
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchApplicationPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes(
                "delete.applications"
              )
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={ApplicationSkeleeton}
            Actions={ApplicationActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchApplicationsuccess: state.application.isFetchApplicationsuccess,
    isFetchApplicationPending: state.application.isFetchApplicationPending,
    fetchApplicationError: state.application.fetchApplicationError,
    applications: state.application.applications,
    noOfRecords: state.application.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApplications: (pageNo, perPage, keyword) => {
      dispatch(fetchApplications(pageNo, perPage, keyword));
    },
    setFetchApplicationError: (postData) => {
      dispatch(setFetchApplicationError(postData));
    },
    deleteApplications: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteApplications(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationList);
