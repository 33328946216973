//Import Components for Manage Routes
import ShippingList from "app/wsa/shipping/views/ShippingList";
import PackageList from "app/wsa/package/views/PackageList";
import ConsolidatePackageList from "app/wsa/package/views/ConsolidatePackageList";
import OnePackage from "app/wsa/package/views/OnePackage";
import PackageInvoice from "app/wsa/package/views/PackageInvoice";
import WSASettings from "app/wsa/setting/views/WSASettings";

import ExtensionIcon from "@material-ui/icons/Extension";
import SettingsIcon from "@material-ui/icons/Settings";

const wsaroutes = [
  {
    path: "/packages" || "/client" || "/consolidatepackages",
    name: "Packages",
    title: "Packages",
    id: "packages",
    icon: ExtensionIcon,
    permission: [
      "list.wsa.packages",
      "list.own.wsa.packages",
      "add.wsa.packages",
    ],
    child: [
      {
        collapse: true,
        name: "Packages" || "/client" || "/consolidatepackages",
        icon: ExtensionIcon,
        state: "Packages",
        permission: [
          "list.wsa.packages",
          "list.own.wsa.packages",
          "add.wsa.packages",
        ],
        subchild: [
          {
            path: "/packages/add",
            title: "Create New Package",
            name: "Create New Package",
            permission: "add.wsa.packages",
          },
          {
            path: "/packages/consolidate",
            title: "Consolidate Packages",
            name: "Consolidate Packages",
            component: ConsolidatePackageList,
            permission: "list.wsa.packages",
          },
          {
            path: "/packages/list/all",
            title: "Manage Packages",
            name: "All Packages",
            permission: "list.wsa.packages",
          },
          {
            path: "/packages/list/received",
            title: "Received Packages",
            name: "Received Packages",
            permission: "list.wsa.packages",
          },
          {
            path: "/packages/list/ready-to-mailout",
            title: "Ready to Mailout",
            name: "Ready to Mailout",
            permission: "list.wsa.packages",
          },
          {
            path: "/packages/list/hold",
            title: "Hold Packages",
            name: "Hold Packages",
            permission: "list.wsa.packages",
          },
          {
            path: "/packages/list/delivered",
            title: "Delivered Packages",
            name: "Delivered Packages",
            permission: "list.wsa.packages",
          },
        ],
      },
      {
        path: "/packages/settings",
        name: "Setting",
        permission: ["view.logs"],
        title: "Setting",
        icon: SettingsIcon,
        component: WSASettings,
      },
      {
        path: "/packages/shipping/list",
        name: "Shipping Carrier",
        permission: ["view.logs"],
        title: "Shipping Carrier",
        icon: SettingsIcon,
        component: ShippingList,
      },
    ],
  },
  {
    path: "/packages/:id",
    name: "Create New Package",
    component: OnePackage,
    routeonly: true,
  },
  {
    path: "/packages/:id/view",
    name: "Package Invoice",
    component: PackageInvoice,
    routeonly: true,
  },
  {
    path: "/packages/list/:status",
    name: "",
    title: "",
    component: PackageList,
    routeonly: true,
  },
];

export default wsaroutes;
