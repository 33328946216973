import {
    FETCH_EMAILPROVIDER_SUCCESS,
    FETCH_EMAILPROVIDER_PENDING,
    FETCH_EMAILPROVIDER_ERROR,
    FETCH_EMAILPROVIDER,
    FETCH_EMAILPROVIDER_COUNT,
    FETCH_ONE_EMAILPROVIDER,
    FETCH_ONE_EMAILPROVIDER_PENDING,
    ADD_UPDATE_EMAILPROVIDER_PENDING,
    ADD_UPDATE_EMAILPROVIDER_SUCCESS,
    ADD_UPDATE_EMAILPROVIDER_ERROR,
    
    TEST_EMAIL_PENDING,
    TEST_EMAIL_SUCCESS,
    TEST_EMAIL_ERROR,
    TEST_EMAIL_RESPONSE,
} from '../actions/types';

const initialState = {
    isFetchEmailProviderSuccess: false,
    isFetchEmailProviderPending: false,
    fetchEmailProviderError: null,
    addUpdateEmailProviderError: null,
    emailproviderData: [],
    isEmailProviderDataPending: false,
    emailproviders: null,
    noOfRecords: 0,
    isAddUpdateEmailProviderPending: false,
    isAddUpdateEmailProviderSuccess: false,

    testEmailSuccess: false,
    testEmailPending: false,
    testEmailError: null,
    testEmailResponse: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_EMAILPROVIDER:
            return {
                ...state,
                emailproviders: action.payload
            };

        case FETCH_EMAILPROVIDER_SUCCESS:
            return {
                ...state,
                isFetchEmailProviderSuccess: action.payload
            };

        case FETCH_EMAILPROVIDER_PENDING:
            return {
                ...state,
                isFetchEmailProviderPending: action.payload
            };

        case FETCH_EMAILPROVIDER_ERROR:
            return {
                ...state,
                fetchEmailProviderError: action.payload
            };

        case FETCH_EMAILPROVIDER_COUNT:
            return {
                ...state,
                noOfRecords: action.payload
            };

        case FETCH_ONE_EMAILPROVIDER:
            return {
                ...state,
                emailproviderData: action.payload
            };

        case ADD_UPDATE_EMAILPROVIDER_ERROR:
            return {
                ...state,
                addUpdateEmailProviderError: action.payload
            };

        case FETCH_ONE_EMAILPROVIDER_PENDING:
            return {
                ...state,
                isEmailProviderDataPending: action.payload
            };

        case ADD_UPDATE_EMAILPROVIDER_PENDING:
            return {
                ...state,
                isAddUpdateEmailProviderPending: action.payload
            };

        case ADD_UPDATE_EMAILPROVIDER_SUCCESS:
            return {
                ...state,
                isAddUpdateEmailProviderSuccess: action.payload
            };

        case TEST_EMAIL_PENDING:
            return {
                ...state,
                testEmailPending: action.payload
            };

        case TEST_EMAIL_SUCCESS:
            return {
                ...state,
                testEmailSuccess: action.payload
            };

        case TEST_EMAIL_ERROR:
            return {
                ...state,
                testEmailError: action.payload
            };

        case TEST_EMAIL_RESPONSE:
            return {
                ...state,
                testEmailResponse: action.payload
            };

        default:
            return state;
    }
}