import React from "react";
import { connect } from "react-redux";
import {
  fetchPermissions,
  setFetchPermissionError,
  deletePermissions,
} from "../actions/permissionActions";
import { fetchAllGroups } from "app/setup/group/actions/groupActions";
import { fetchAllApplications } from "app/setup/application/actions/applicationActions";
import PermissionActionButtons from "./PermissionActionButtons";
import OnePermission from "./OnePermission";
import PermissionSkeleeton from "./PermissionSkeleeton";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  MuiTable,
  IconButton,
  Button,
  Drawer,
  FormControl,
  Select,
  InputLabel,
} from "@mycentyr/centyr-ui";

import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

class PermissionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      group_id: 0,
      application_id: 0,
      modal: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllGroups();
    this.props.fetchAllApplications();
    this.props.fetchPermissions();
  }

  render() {
    const headCells = [
      {
        id: "permission_name",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "permission_slug",
        numeric: false,
        disablePadding: false,
        label: "Permission",
      },
      {
        id: "permission_description",
        numeric: false,
        disablePadding: false,
        label: "Description",
      },
      {
        id: "application_name",
        numeric: false,
        disablePadding: false,
        label: "App Name",
      },
      {
        id: "group_name",
        numeric: false,
        disablePadding: false,
        label: "Group Name",
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (
      id,
      permission_name,
      permission_slug,
      permission_description,
      application_name,
      group_name
    ) => {
      return {
        id,
        permission_name,
        permission_slug,
        permission_description,
        application_name,
        group_name,
      };
    };

    const onReload = (rowsPerPage, keyword) => {
      this.setState({ application_id: 0, group_id: 0 });
      this.props.fetchPermissions(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchPermissions(
        pageNo,
        perPage,
        keyword,
        this.state.group_id,
        this.state.application_id
      );
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deletePermissions(
        postData,
        pageNo,
        perPage,
        keyword,
        this.state.group_id,
        this.state.application_id
      );
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchPermissions(
          1,
          perPage,
          keyword,
          this.state.group_id,
          this.state.application_id
        );
    };

    const onClose = () => {
      this.setState({ ...this.state, open: !this.state.open });
    };

    const FilterActionButton = () => {
      return (
        <IconButton
          className="no-border-onfocus"
          aria-label="Filter"
          onClick={() => this.setState({ ...this.state, open: true })}
        >
          <FilterListIcon />
        </IconButton>
      );
    };

    var rows = [];
    if (this.props.permissions) {
      this.props.permissions.map((permission) =>
        rows.push(
          createData(
            permission.id,
            permission.permission_name,
            permission.permission_slug,
            permission.permission_description,
            permission.application_name,
            permission.group_name
          )
        )
      );
    }

    const toggle = () =>
      this.setState({ ...this.state, modal: !this.state.modal });

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Permission</ModalHeader>
          <ModalBody>
            <OnePermission id="add" toggle={toggle} />
          </ModalBody>
        </Modal>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes(
                  "add.permissions"
                ) && (
                  <Button className="mb-3" color="primary" onClick={toggle}>
                    Add Permission
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <Drawer
            anchor="right"
            classes={{ paper: "filter-paper" }}
            onClose={onClose}
            open={this.state.open}
            variant="temporary"
          >
            <div className="filter-action-buttons-container">
              <Button onClick={onClose} color="danger" outline size="sm">
                <CloseIcon /> Close
              </Button>
              <Row className="mt-3">
                <Col lg={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="mb-3"
                    onChange={(event) =>
                      this.setState({ group_id: event.target.value })
                    }
                  >
                    <InputLabel htmlFor="group_id">Group</InputLabel>
                    <Select
                      native
                      value={this.state.group_id}
                      inputProps={{
                        name: "group_id",
                        id: "group_id",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {this.props.groups &&
                        this.props.groups.map((group) => (
                          <option value={group.id} key={group.id}>
                            {group.application_group_name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="mb-3"
                    onChange={(event) =>
                      this.setState({ application_id: event.target.value })
                    }
                  >
                    <InputLabel htmlFor="application_id">App</InputLabel>
                    <Select
                      native
                      value={this.state.application_id}
                      inputProps={{
                        name: "application_id",
                        id: "application_id",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {this.props.applications &&
                        this.props.applications.map((application) => (
                          <option value={application.id} key={application.id}>
                            {application.application_name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Button
                color="primary"
                block
                outline
                className="filter-action-button"
                onClick={() => {
                  this.setState({ application_id: 0, group_id: 0 });
                }}
              >
                <DeleteIcon /> Clear
              </Button>
              <Button
                color="primary"
                block
                className="filter-action-button"
                onClick={() => {
                  this.props.fetchPermissions(
                    1,
                    this.props.perPage,
                    this.props.keyword,
                    this.state.group_id,
                    this.state.application_id
                  );
                  onClose();
                }}
              >
                Apply filters
              </Button>
            </div>
          </Drawer>
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchPermissionPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes("delete.permissions")
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            FilterComponent={FilterActionButton}
            onSearch={onSearch}
            CustomSkeleton={PermissionSkeleeton}
            Actions={PermissionActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchPermissionSuccess: state.permission.isFetchPermissionSuccess,
    isFetchPermissionPending: state.permission.isFetchPermissionPending,
    fetchPermissionError: state.permission.fetchPermissionError,
    permissions: state.permission.permissions,
    noOfRecords: state.permission.noOfRecords,
    authUserData: state.authUser.userData,
    groups: state.applicationgroup.groups,
    applications: state.application.applications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPermissions: (pageNo, perPage, keyword, group_id, application_id) => {
      dispatch(
        fetchPermissions(pageNo, perPage, keyword, group_id, application_id)
      );
    },
    setFetchPermissionError: (postData) => {
      dispatch(setFetchPermissionError(postData));
    },
    deletePermissions: (postData, pageNo, perPage, keyword) => {
      dispatch(deletePermissions(postData, pageNo, perPage, keyword));
    },
    fetchAllApplications: () => {
      dispatch(fetchAllApplications());
    },
    fetchAllGroups: () => {
      dispatch(fetchAllGroups());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionList);
