import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { deletePackages, getLabel, getMenifest } from "../slices/packageSlice";
import { connect } from "react-redux";
import { useDispatch } from "redux/store";
import {
  CustomModal,
  Popover,
  PopoverBody,
  IconButton,
} from "@mycentyr/centyr-ui";
import { history } from "helpers/history";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const PackageActionButtons = (props) => {
  const dispatch = useDispatch();
  const {
    setPage,
    setSelected,
    page,
    rowsPerPage,
    keyword,
    row,
    rowLength,
  } = props;

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [popover, setPopover] = React.useState(false);
  const togglePopover = () => setPopover(!popover);

  const onDelete = () => {
    toggle();
    if (rowLength === 1 && page !== 0) {
      setPage(page - 1);
      dispatch(deletePackages({ id: row.id }, page, rowsPerPage, keyword));
    } else
      dispatch(deletePackages({ id: row.id }, page + 1, rowsPerPage, keyword));
    setSelected([]);
  };

  return (
    <div>
      <IconButton
        aria-label="key"
        className="no-border-onfocus"
        onClick={togglePopover}
        id={`Popover-${row.id}`}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        placement="left"
        trigger="legacy"
        isOpen={popover}
        target={`Popover-${row.id}`}
        toggle={togglePopover}
      >
        <PopoverBody>
          {row.is_paid == 1 && (
            <React.Fragment>
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(getLabel(row.id));
                }}
              >
                <PictureAsPdfIcon /> Label
              </a>
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(getMenifest(row.id));
                }}
              >
                <PictureAsPdfIcon /> Menifest
              </a>
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/packages/" + row.id + "/view");
                }}
              >
                <PictureAsPdfIcon /> Invoice
              </a>
            </React.Fragment>
          )}
          {props.authUserData.permissions &&
            props.authUserData.permissions.includes("edit.wsa.packages") && (
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/packages/" + row.id);
                }}
              >
                <EditIcon /> Edit
              </a>
            )}
          {props.authUserData.permissions &&
            props.authUserData.permissions.includes("delete.wsa.packages") && (
              <a
                role="button"
                className="anchoreless-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggle();
                }}
              >
                <DeleteIcon /> Delete
              </a>
            )}
        </PopoverBody>
      </Popover>
      <CustomModal
        onModalAccept={onDelete}
        onModalCancel={toggle}
        modal={modal}
        toggle={toggle}
        modalTitle="Delete"
        modalBody="Are you sure you want to delete?"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserData: state.authUser.userData,
  };
};

export default connect(mapStateToProps, null)(PackageActionButtons);
