import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchCustomer } from '../actions/customerActions';
import { Row, Col, Divider, Box, Tabs, Tab } from '@mycentyr/centyr-ui';

import CustomerForm from './CustomerForm';
import UserPassword from 'app/manage/user/views/UserPassword';

import { appName } from 'utils/axios';
import AddressList from 'app/crm/address/views/AddressList';
import PermissionTable from 'app/manage/role/views/PermissionTable';

const OneCustomer = (props) => {
  const { id } = props.match.params;
  const [value, setValue] = React.useState('general');

  if (id !== 'add') document.title = 'Update Customer' + appName;
  else document.title = 'Create New Customer' + appName;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (id !== 'add') {
      props.fetchCustomer(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-header'>
        <Row>
          <Col className='page-title'>
            <h1 className='page-title'>
              {id === 'add'
                ? 'Create New Customer'
                : `Update Customer (${props.customerData.user_display_name})`}
            </h1>
          </Col>
        </Row>
      </div>
      <div className='content-wrapper'>
        {id === 'add' ? (
          <CustomerForm id={id} />
        ) : (
          <React.Fragment>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              aria-label='full width tabs example'
            >
              <Tab value={'general'} label='General' />
              <Tab value={'security'} label='Security' />
              <Tab value={'address'} label='Addresses' />
              <Tab value={'permission'} label='Permissions' />
            </Tabs>
            <Divider />
            <Box py={3} pb={6}>
              {value === 'general' && <CustomerForm id={id} />}
              {value === 'security' && <UserPassword role='customer' />}
              {value === 'address' && <AddressList id={id} />}
              {value === 'permission' && (
                <PermissionTable
                  allowedPermissions={props.customerData?.permissions}
                  id={id}
                  type='user'
                />
              )}
            </Box>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    customerData: state.customer.customerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomer: (id) => {
      dispatch(fetchCustomer(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OneCustomer);
