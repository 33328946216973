import React from "react";
import { connect } from "react-redux";
import {
  fetchUsers,
  setFetchUserError,
  deleteUsers,
} from "../actions/userActions";
import UserActionButtons from "./UserActionButtons";
import { history } from "helpers/history";
import UserSkeleeton from "./UserSkeleeton";

import { Row, Col, MuiTable, Button, Badge } from "@mycentyr/centyr-ui";

function CustomBadge(props) {
  const { text, ...rest } = props;

  return (
    <div>
      <Badge {...rest}>{text}</Badge>
    </div>
  );
}
class UserList extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  render() {
    const headCells = [
      {
        id: "display_name",
        numeric: false,
        disablePadding: false,
        label: "Name",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email Address",
      },
      {
        id: "phone",
        numeric: true,
        disablePadding: false,
        label: "Phone Number",
      },
      { id: "role", numeric: false, disablePadding: false, label: "Roles" },
      {
        id: "created",
        numeric: true,
        disablePadding: false,
        label: "Created At",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        component: CustomBadge,
      },
      { id: "action", numeric: true, disablePadding: false, label: "Action" },
    ];

    const createData = (
      id,
      display_name,
      email,
      phone,
      role,
      created,
      status,
      uid,
      customProps
    ) => {
      return {
        id,
        display_name,
        email,
        phone,
        role,
        created,
        status,
        uid,
        customProps,
      };
    };
    var rows = [];
    if (this.props.users) {
      this.props.users.map((user) =>
        rows.push(
          createData(
            user.id,
            user.user_display_name,
            user.user_email,
            user.user_phone_number,
            user.role,
            user.created_at,
            user.is_active === "1"
              ? "Active"
              : user.is_active === "0"
              ? "Deactive"
              : "Pending",
            user.user_uid,
            {
              color:
              user.is_active === "1"
                  ? "success"
                  : user.is_active === "1"
                  ? "danger"
                  : "secondary",
            }
          )
        )
      );
    }

    const onReload = (rowsPerPage, keyword) => {
      this.props.fetchUsers(1, rowsPerPage, keyword);
    };

    const handlePaginationChange = (pageNo, perPage, keyword) => {
      this.props.fetchUsers(pageNo, perPage, keyword);
    };

    const onMultiDelete = (postData, pageNo, perPage, keyword) => {
      this.props.deleteUsers(postData, pageNo, perPage, keyword);
    };

    const onSearch = (keyword, perPage) => {
      if (keyword.length > 2 || keyword.length === 0)
        this.props.fetchUsers(1, perPage, keyword);
    };

    return (
      <div>
        <div className="page-header">
          <Row>
            <Col className="page-title">
              <h1>{this.props.title}</h1>
            </Col>
            <Col className="text-right page-header-button">
              {this.props.authUserData.permissions &&
                this.props.authUserData.permissions.includes("add.users") && (
                  <Button
                    color="primary"
                    className="mb-3"
                    onClick={() => {
                      history.push("add");
                    }}
                  >
                    Add User
                  </Button>
                )}
            </Col>
          </Row>
        </div>
        <div className="content-wrapper">
          <MuiTable
            headCells={headCells}
            rows={rows}
            onReload={onReload} // comment this to disable refresh option
            isFetchPending={this.props.isFetchUserPending}
            // chooseColumn={false}   //=>to false display column option
            noOfRecords={this.props.noOfRecords}
            handlePaginationChange={handlePaginationChange}
            multipleDelete={
              this.props.authUserData.permissions &&
              this.props.authUserData.permissions.includes("delete.users")
                ? true
                : false
            }
            onMultiDelete={onMultiDelete}
            onSearch={onSearch}
            CustomSkeleton={UserSkeleeton}
            Actions={UserActionButtons}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchUserSuccess: state.user.isFetchUserSuccess,
    isFetchUserPending: state.user.isFetchUserPending,
    fetchUserError: state.user.fetchUserError,
    users: state.user.users,
    noOfRecords: state.user.noOfRecords,
    authUserData: state.authUser.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (pageNo, perPage, keyword) => {
      dispatch(fetchUsers(pageNo, perPage, keyword));
    },
    setFetchUserError: (postData) => {
      dispatch(setFetchUserError(postData));
    },
    deleteUsers: (postData, pageNo, perPage, keyword) => {
      dispatch(deleteUsers(postData, pageNo, perPage, keyword));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
